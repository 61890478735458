import { Navigate } from 'react-router-dom';

const AuthRedirect = ({ children }) => {
  const token = localStorage.getItem('token');
  if (token) {
    return <Navigate to="/" />; // Redirect if logged in
  }

  return children; // Render the component if not logged in
};

export default AuthRedirect;
