import React, { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import PageLoader from "../components/ui/PageLoader";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import { FaEdit } from "react-icons/fa";
import Modal from "../components/ui/Modal";
import { MdDelete } from "react-icons/md";
import {
  useAddTriggerMutation,
  useDeleteTriggerMutation,
  useGetAllTriggersQuery,
  useUpdateTriggerMutation,
} from "apis/triggers";
import { FaPlus } from "react-icons/fa6";
import TriggerEditForm from "components/triggers/edit";
import Button from "components/ui/Button";

const Triggers = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);
  const [pageGroup, setPageGroup] = useState(0);
  const navigate = useNavigate();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const { mutate: updateTrigger, isLoading: isTriggerUpdating } =
    useUpdateTriggerMutation();
  const {
    data: triggers,
    isPending: isTriggersPending,
    isError: isTriggersError,
    error: triggersError,
  } = useGetAllTriggersQuery();
  const {
    mutate: addTrigger,
    isError: isAddError,
    isLoading: isAddLoading,
  } = useAddTriggerMutation();
  const {
    mutate: deleteTrigger,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeleteTriggerMutation();

  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "#",
        cell: (info) => info.row.index + 1,
        enableColumnFilter: false,
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "query",
        header: "Query",
        cell: ({ row }) => (
          <pre className="text-sm whitespace-pre-wrap">
            {JSON.stringify(row.original.query, null, 2)}
          </pre>
        ),
      },
      {
        accessorKey: "targetTable",
        header: "Target Table",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => {
                setSelectedId(row.original._id);
                setIsEditOpen(true);
              }}
              className="view-icon"
            >
              <FaEdit fontSize={20} />
            </button>
            <button
              onClick={() => {
                setSelectedId(row.original._id);
                deleteTrigger(row.original._id);
              }}
              className="view-icon"
            >
              <MdDelete fontSize={20} />
            </button>
          </div>
        ),
      },
    ],
    [navigate]
  );

  const table = useReactTable({
    data: triggers || [],
    columns,
    state: {
      columnFilters,
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
  });

  if (!triggers) {
    return <PageLoader />;
  }

  const totalPages = table.getPageCount();
  const pagesPerGroup = 10;
  const currentGroupStart = pageGroup * pagesPerGroup;
  const currentGroupEnd = Math.min(
    currentGroupStart + pagesPerGroup,
    totalPages
  );

  const handleNextGroup = () => {
    if (currentGroupEnd < totalPages) {
      setPageGroup(pageGroup + 1);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
    }
  };

  return (
    <>
      <Modal
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        title="Edit Trigger"
      >
        <TriggerEditForm
          initialData={triggers.find((item) => item._id === selectedId)}
          onSubmit={(data) => {
            updateTrigger({ id: selectedId, ...data });
            setIsEditOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        title="Add Trigger"
      >
        <TriggerEditForm
          initialData={{ name: "", description: "", query: {}, targetTable: "" }}
          onSubmit={(data) => {
            addTrigger(data);
            setIsAddOpen(false);
          }}
        />
      </Modal>

      <div className="container mx-auto">
        <div
          className="pagination-info flex justify-between
      align-center items-center"
        >
          <p>
            Showing {pagination.pageIndex * pagination.pageSize + 1}-
            {Math.min(
              (pagination.pageIndex + 1) * pagination.pageSize,
              triggers.length
            )}{" "}
            of {triggers.length} items.
          </p>
          <Button className="black-button flex" onClick={() => setIsAddOpen(true)}>
            <FaPlus size={16} className="mr-1" />
            Add Trigger
          </Button>
        </div>
        <div className="table-responsive">
          <table className="styled-table">
          <thead>
  {table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <th key={header.id}>
          <div className="flex flex-col">
            {flexRender(
              header.column.columnDef.header,
              header.getContext()
            )}
            {header.column.id === "name" && ( // Add search only for "name"
              <input
                type="text"
                value={header.column.getFilterValue() || ""}
                onChange={(e) =>
                  header.column.setFilterValue(e.target.value)
                }
                placeholder="Search by name"
                   className="column-filter"
              />
            )}
          </div>
        </th>
      ))}
    </tr>
  ))}
</thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-controls">
          <button onClick={handlePrevGroup} disabled={pageGroup === 0}>
            &laquo;
          </button>
          {Array.from(
            { length: currentGroupEnd - currentGroupStart },
            (_, i) => (
              <button
                key={i}
                onClick={() => table.setPageIndex(currentGroupStart + i)}
                className={
                  currentGroupStart + i === pagination.pageIndex ? "active" : ""
                }
              >
                {currentGroupStart + i + 1}
              </button>
            )
          )}
          <button
            onClick={handleNextGroup}
            disabled={currentGroupEnd >= totalPages}
          >
            &raquo;
          </button>
        </div>
      </div>
    </>
  );
};

export default Triggers;
