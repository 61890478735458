'use client'

import React, { useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { ChevronLeft, ChevronRight } from 'lucide-react'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

export default function PdfViewer({ pdfUrl }) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageWidth, setPageWidth] = useState(0)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
  }

  return (
    <div className="flex flex-col items-center w-full max-w-4xl mx-auto p-4">
      <div 
        className="w-full bg-white shadow-md"
        ref={(ref) => {
          if (ref) setPageWidth(ref.clientWidth)
        }}
      >
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          className="flex justify-center"
        >
          <Page 
            pageNumber={pageNumber} 
            width={pageWidth}
            className="max-w-full h-auto"
          />
        </Document>
      </div>
      <div className="w-full flex justify-between items-center mt-4">
        <button
          onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
          disabled={pageNumber <= 1}
          className="p-2 bg-gray-200 rounded-full disabled:opacity-50"
          aria-label="Previous page"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <span className="text-sm">
          Page {pageNumber} of {numPages || '--'}
        </span>
        <button
          onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages || Infinity))}
          disabled={pageNumber >= (numPages || 0)}
          className="p-2 bg-gray-200 rounded-full disabled:opacity-50"
          aria-label="Next page"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </div>
  )
}