import React, { useContext, useState, useEffect } from "react";
import Sidebar, { SidebarItem } from "../components/ui/Sidebar";
import axios from "axios";
import {
  LayoutDashboard,
  BookA,
  BookOpen,
  ClipboardList,
  LogOut,
} from "lucide-react";

import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/ui/NavBar";
import EnhancedDashboardLayout from "../components/ui/DashBoardLayout";

const DashboardComponent = () => {
  return (
    <div className="space-y-4">
      <h2>Dashboard</h2>
    </div>
  );
};

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "Dashboard":
        return  <div className="container">
        <h4>Dashboard</h4>
      </div>
      default:
        return (
          <div className="space-y-4">
            <h2>Dashboard</h2>
          </div>
        );
    }
  };

  return (
    // <EnhancedDashboardLayout>
          renderActiveComponent()
        // </EnhancedDashboardLayout>
    
  );
};

export default Dashboard;
