import {
    useQuery,
    useMutation,
    useQueryClient,
  } from '@tanstack/react-query'
import api from '../utils/axios'
import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'
export const useUpdateContractorMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_CONTRACTOR}/${data?.id}`, data),
            onSuccess: () => {
                showToast('success', 'Contractor updated successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CONTRACTORS)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useGetContractorByIdQuery = (id) => {
    const { data, isPending, isError, error } = useQuery({
        queryKey:[API_ROUTES.GET_CONTRACTORS, id], 
        queryFn:() => api.get(`${API_ROUTES.GET_CONTRACTORS}/${id}`),
        enabled: !!id,
})

    return {isPending, isError, user:data?.data?.data, error}
}
export const useGetContractorQuery = () => {
    const { data, isError, error,isLoading } = useQuery({
        queryKey: [API_ROUTES.GET_CONTRACTORS],
        queryFn: () => api.get(API_ROUTES.GET_CONTRACTORS),
    })
    return {data: data?.data?.data ?? [], isLoading, isError, error }
}

export const useDeleteContractorMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (id) => api.delete(`${API_ROUTES.DELETE_CONTRACTOR}/${id}`),
            onSuccess: () => {
                showToast('success', 'Contractor deleted successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CONTRACTORS)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useUpdatePictureMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: ({id,data}) => api.put(`${API_ROUTES.UPDATE_CONTRACTOR_PICTURE}/${id}`,data,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }),
            onSuccess: () => {
                showToast('success', 'Picture updated successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CONTRACTORS)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }

}