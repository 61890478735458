// src/components/Login/Login.js
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/ui/Button"
import { useUpdatePasswordMutation } from "apis/auth";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const ChangePassword = () => {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const{
        mutate:updatePassword,
        isError, error, isLoading, isSuccess, isPending
    }=useUpdatePasswordMutation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm(

    {
        mode: "onBlur",
      defaultValues: {
        newPassword: "",
        confirmPassword: "",
        oldPassword: "",
      }
    }
  );

  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const onSubmit = async (data) => {
    updatePassword(data);
  };

  return (
    <div className=" py-8 px-4 flex  justify-center">
      <form
        className="border border-mediumgray p-8 rounded-md w-full max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >

        <h4 className="text-center">Change Password</h4>
        <div className="my-4 relative">
          <input
            type={showOldPassword ? "text" : "password"}
            {...register("oldPassword", {
              required: "Old password is required",
              minLength: { value: 8, message: "Password must be at least 8 characters" },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                message: "Password must contain at least one numeric digit, one uppercase, one lowercase, and at least 8 characters",
              },
            })}
            className={`form-input border ${errors.oldPassword ? "border-red-500" : "border-mediumgray"}`}
            placeholder="Old Password"
          />
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => setShowOldPassword((prev) => !prev)}
          >
            {showOldPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
          </span>
          {errors.oldPassword && <p className="form-error-message">{errors.oldPassword.message}</p>}
        </div>
        <div className="my-4 relative">
          <input
            type={showNewPassword ? "text" : "password"}
            ref={passwordRef}
            {...register("newPassword", {
              required: "New password is required",
              minLength: { value: 8, message: "Password must be at least 8 characters" },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                message: "Password must contain at least one numeric digit, one uppercase, one lowercase, and at least 8 characters",
              },
            })}
            className={`form-input border ${errors.newPassword ? "border-red-500" : "border-mediumgray"}`}
            placeholder="New Password"
          />
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => setShowNewPassword((prev) => !prev)}
          >
            {showNewPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
          </span>
          {errors.newPassword && <p className="form-error-message">{errors.newPassword.message}</p>}
        </div>
        <div className="my-4 relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            {...register("confirmPassword", {
              required: "Confirm password is required",
              minLength: { value: 8, message: "Password must be at least 8 characters" },
              validate: (value) => value === getValues('newPassword') || "The passwords do not match",
            })}
            className={`form-input border ${errors.confirmPassword ? "border-red-500" : "border-mediumgray"}`}
            placeholder="Confirm Password"
          />
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => setShowConfirmPassword((prev) => !prev)}
          >
            {showConfirmPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
          </span>
          {errors.confirmPassword && <p className="form-error-message">{errors.confirmPassword.message}</p>}
        </div>
        <Button type="submit" className="w-full black-button" 
        loading={isPending} disabled={isPending}
        >
          {isPending ? "Loading..." : "Change Password"}
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
