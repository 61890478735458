import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";
import video from "assets/videos/postlist-video.mp4";
import Button from "components/ui/Button";
import propertyImg1 from "assets/images/property-img-1.jpg";
import propertyImg3 from "assets/images/property-img-3.jpg";
import propertyImg6 from "assets/images/property-img-6.jpg";
import propertyImg13 from "assets/images/property-img-13.jpg";
import propertyImg14 from "assets/images/property-img-14.jpg";
import propertyImg15 from "assets/images/property-img-15.jpg";

import arrow from "assets/images/arrow.png";
import PageLoader from "components/ui/PageLoader";
import Error from "components/ui/Error";
import MarketingPrice from "components/ui/MarketingPrice";

const ProcessChain = ({ processChain }) => {
  return (
    <div className="w-full space-y-16">
      <h4>THE PROCESS CHAIN</h4>
      <div className="w-full flex overflow-x-auto box-scrollbar">
        {processChain.map((step, index) => (
          <div key={index} className="flex items-center">
            <div>
              <div className="flex items-center justify-center border-4 chain border-gray-300 bg-white text-gray-500 cursor-pointer">
                {step.selected === true && "✔️"}
                {step.selected === false && "❌"}
                {step.selected === null && ""} {/* Initial state */}
              </div>
              <p className="mt-2 mb-0 min-h-[32px] max-w-[100px] text-xs">
                {step.name}
              </p>
            </div>
            {index < processChain.length - 1 && (
              <div className="chain-stick bg-gray-300"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const PostList = ({ property }) => {
  const MaximumOutcomeProcess = ({
    logicalPrice,
    processName,
    soldProperties,
  }) => {
    // const getProcessName = (process) => {
    //   // Your function to get the process name based on the data
    //   return process ? process.name : 'Default Process Name';
    // };

    const formatCurrency = (value) => {
      if (value === undefined || value === null) return "N/A";
      return "$" + new Intl.NumberFormat().format(value);
    };

    return (
      <div className="py-8 space-y-20">
        <div className="space-y-16">
          <h4>MAXIMUM OUTCOME PROCESS</h4>
          <p>{processName || "N/A"}</p>
          <p>
            Everyone deserves the maximum outcome. It's not just the method of
            sale, but also the intricate details within the process that will
            maximise the result. We have carefully chosen this method.
          </p>
        </div>

        {/* Step 1 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 1</p>
            <p>Price Point</p>
          </div>

          {/* LOGICAL ASSESSMENT */}
          <div className="space-y-16">
            <h4>LOGICAL ASSESSMENT</h4>
            <p className="font-bold">{postList.logicalPrice}</p>
          </div>

          <div className=" flex flex-col items-center justify-center space-y-16">
            <div className="max-w-sm mx-auto space-y-8 flex flex-col items-center justify-center">
              <div className="flex flex-row justify-between w-full text-sm">
                <span>Buyers start logically</span>
                <span>and finish emotionally</span>
              </div>
              <img src={arrow} alt="arrow" />
            </div>

            <p className="text-center">
              Getting the first part of the process right helps to create the
              maximum outcome. This is a logical range derived from market
              insights and the final outcome may vary significantly.
            </p>

            {soldProperties && soldProperties.length > 0 && (
              <div className="w-full overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-lightgray ">
                      <th className="py-2 px-3">Address</th>
                      <th className="py-2 px-3">Price</th>
                      <th className="py-2 px-3">Score Match</th>
                    </tr>
                  </thead>
                  <tbody>
                    {soldProperties.map(({ property, score }, index) => (
                      <tr key={property._id} className="border-b">
                        <td className="py-2 px-3">{property.address}</td>
                        <td className="py-2 px-3">
                          {formatCurrency(property.price)}
                        </td>
                        <td className="py-2 px-3">{score}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {/* Step 2 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 2</p>
            <p>Engagement</p>
          </div>
          <p>
            Assess: Enquiry Inspections Engagement Determine if the price is
            adjusted, remains, or is raised.
          </p>
        </div>

        {/* Step 3 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 3</p>
            <p>Price Assessment</p>
          </div>
          {soldProperties && soldProperties.length > 0 && (
            <div className="w-full overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-lightgray ">
                    <th className="py-2 px-3">Address</th>
                    <th className="py-2 px-3">Price</th>
                    <th className="py-2 px-3">Score Match</th>
                  </tr>
                </thead>
                <tbody>
                  {soldProperties.map(({ property, score }, index) => (
                    <tr key={property._id} className="border-b">
                      <td className="py-2 px-3">{property.address}</td>
                      <td className="py-2 px-3">
                        {formatCurrency(property.price)}
                      </td>
                      <td className="py-2 px-3">{score}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Step 4 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 4</p>
            <p>Closing Date Live</p>
          </div>
          <p>
            Determine if the date will be moved forward or the date is moved
            back based on the engagement.
          </p>
        </div>

        {/* Step 5 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 5</p>
            <p>Finalise</p>
          </div>
          <p>Maximum Outcome</p>
        </div>
      </div>
    );
  };

  const [postList, setPostList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [areaDynamics, setAreaDynamics] = useState(null);

  const fetchPostlist = async () => {
    try {
      const response = await axios.get(`/admin/postList`, {
        params: {
          propertyId: property?._id,
        },
      });
      if (!response.data.success) {
      }
      setPostList(response.data.data);
    } catch (error) {
      console.error("Error fetching postlist data:", error.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  // const fetchAreaDynamics = async () => {
  //   try {
  //     const response = await axios.get(`/property/suburb/${postList.suburb}`);
  //     console.log(response);
  //     setAreaDynamics(response?.data?.data);
  //   } catch (error) {
  //     console.error("Error fetching area dynamics:", error);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchPostlist(); // Fetch data by UUID and wait for it to complete
      } catch (error) {
        console.error("Error in fetching post list", error);
      }
    };

    fetchData(); // Call the async function to fetch post list data
  }, []); // Empty dependency array to run only on mount

  // Use another useEffect to run fetchAreaDynamics once postList is updated
  // useEffect(() => {
  //   if (postList && postList.suburb) {
  //     const fetchAreaDynamics = async () => {
  //       try {
  //         const response = await axios.get(
  //           `/property/suburb/${postList.suburb}`
  //         );
  //         console.log(response);
  //         setAreaDynamics(response?.data?.data);
  //       } catch (error) {
  //         console.error("Error fetching area dynamics:", error);
  //       }
  //     };

  //     fetchAreaDynamics(); // Call fetchAreaDynamics after postList.suburb is updated
  //   }
  // }, [postList]); // This effect will run whenever postList is updated

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <Error />;
  }

  if (!postList) {
    return (
      <div className="flex items-center justify-center">
        Postlist Not Found
      </div>
    );
  }

  return (
    <div className="container flex flex-col items-center justify-center">
      <div className="w-full h-[90vh] relative pb-16">
        <video
          autoPlay
          muted
          loop
          preload="metadata"
          playsInline
          webkit-playsinline="true"
          className="w-full h-full object-cover"
        >
          <source
            src="https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/videos/postlist-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-20">
        <h2>Thank you</h2>

        <p>{postList.address}</p>

        <div className="flex items-center flex-col">
          {postList.vendors.map((vendor, index) => (
            <div key={vendor._id} className="flex items-center gap-2">
              {index === 0 && <span>Dear</span>}
              <span>{vendor.firstName}</span>
              <span>{vendor.lastName}</span>
            </div>
          ))}
        </div>

        <p className="w-full">{postList.ownerSituation}</p>

        <div class="w-full flex items-center justify-center">
          <img
            src={propertyImg3}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
        </div>

        <p class="text-xl">
          “It’s not just a sale it means more. The maximum outcome is life
          changing”
        </p>

        <div className="space-y-16">
          <h4>Key features of your property to maximise the outcome</h4>
          <p>{postList.keyFeatures.heading}</p>
          <p>{postList.keyFeatures.description}</p>
        </div>

        <div class="flex items-center justify-center">
          <img
            src={propertyImg1}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
        </div>

        <MaximumOutcomeProcess
          processName={postList.recommendedSaleProcess}
          logicalPrice={postList.logicalPrice}
          soldProperties={postList.soldProperties}
        />

        <div className="space-y-16">
          <h4>Improvements To Maximise The Outcome</h4>
          {postList.improvementMaxOutcome && (
            <div
              className="engaged-purchaser"
              dangerouslySetInnerHTML={{
                __html: postList.improvementMaxOutcome,
              }}
            />
          )}
        </div>

        <div class="flex items-center justify-center">
          <img
            src={propertyImg13}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
        </div>

        <div class="w-full">
          <ProcessChain processChain={postList.processChain} />
        </div>

        <p>Every step is carefully considered</p>

        <div class="w-full flex items-center justify-center">
          <img
            src={propertyImg15}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
        </div>

        <div class="w-full max-w-lg mx-auto space-y-16">
          <h4>YOUR AREA DYNAMICS</h4>
          {areaDynamics ? (
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-start border-collapse">
                <thead>
                  <tr className="bg-mediumgray">
                    <th className="py-2 px-3"></th>
                    <th className="py-2 px-3 text-left">
                      <i className="fa-solid fa-house mr-2"></i> House
                    </th>
                    <th className="py-2 px-3 text-left">
                      <i className="fa-solid fa-building mr-2"></i> Unit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      Median Sale Price
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                        : "N/A"}
                    </td>
                  </tr>
                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      Annual Sales Volume
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? areaDynamics.houseStats.annualSalesVolume
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? areaDynamics.unitStats.annualSalesVolume
                        : "N/A"}
                    </td>
                  </tr>

                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      Suburb Growth
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? areaDynamics.houseStats.suburbGrowth
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? areaDynamics.unitStats.suburbGrowth
                        : "N/A"}
                    </td>
                  </tr>
                  <tr className="border-b border-mediumgray">
                    <td className="py-2 px-3 border border-mediumgray">
                      High Demand Area
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.houseStats
                        ? areaDynamics.houseStats.highDemandArea
                        : "N/A"}
                    </td>
                    <td className="py-2 px-3 border border-mediumgray">
                      {areaDynamics.unitStats
                        ? areaDynamics.unitStats.highDemandArea
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <i className="fa-solid fa-spinner animate-spin mr-2"></i> Loading
              ...
            </div>
          )}
        </div>

        <div class="w-full flex items-center justify-center">
          <img
            src={propertyImg6}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
        </div>

        <div class="space-y-16">
          <h4>MOST LIKELY ENGAGED PURCHASER</h4>
          {/* property */}
          {postList.engagedPurchaser && (
            <div
              className="engaged-purchaser"
              dangerouslySetInnerHTML={{ __html: postList.engagedPurchaser }}
            />
          )}
        </div>

        <div class="flex items-center justify-center">
          <img
            src={propertyImg14}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
        </div>

        <div class="space-y-16">
          <h4>MARKETING</h4>
          <p class="text-xl">
            “We believe people don’t buy products; they buy the feeling the
            product gives them.”
          </p>
        </div>

        {postList?.marketing && (
          <div className="w-full">
            <h4>SHOPPING CART</h4>
            <div className="w-full flex items-center justify-center">
              <MarketingPrice
                tab="post-list"
                isEdit={false}
                existingData={postList.marketing}
              />
            </div>
          </div>
        )}

        <div className="w-full space-y-16">
          <h4>INVESTMENT</h4>
          <div class="overflow-x-auto">
            <table class="min-w-full border">
              <tbody>
                <tr>
                  <td class="border text-sm px-4 py-2">
                    <div class="flex justify-center items-center">
                      {postList.commissionFee}
                      &nbsp;
                      <span>off the purchase price</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="w-full space-y-16">
          <h4>Next Steps</h4>
          <div class="overflow-x-auto">
            <table class="min-w-full border-collapse">
              <thead>
                <tr class="bg-gray-200 text-gray-700">
                  <th class="py-2 px-3">Week</th>
                  <th class="py-2 px-3">Event</th>
                </tr>
              </thead>
              <tbody>
                {[
                  "Instruct solicitor to prepare contract of sale, Marketing Preparation, Coming Soon Letters & pointer signs",
                  "Notify off market buyers, launch to market meeting",
                  "Launch to market, Just listed notifications, signboard, brochures, first open home",
                  "Weekly report, vendor meeting to assess engagement, open home",
                ].map((option, index) => (
                  <tr key={index} className="border-b">
                    <td className="py-2 px-3">{index + 1}</td>
                    <td className="py-2 px-3">{option}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div class="overflow-x-auto">
            <a href="https://23mil.my.canva.site/ausrealty-look-book">
              <table class="min-w-full border ">
                <tbody>
                  <tr>
                    <td class="border px-4 py-2 font-bold">
                      See more of our work: Click Here
                    </td>
                  </tr>
                </tbody>
              </table>
            </a>
          </div>
        </div>

        <div class="flex flex-col space-y-8">
          <img
            src={postList.agent.picture}
            alt="Agent"
            className="max-h-[600px]"
          />
          <p>{postList.agent.name}</p>
          <p>{postList.agent.email}</p>
        </div>

        {postList?.followers.length > 0 &&
          postList?.followers.map((follower) => (
            <div className="space-y-8">
              <img
                src={follower?.picture}
                alt="Agent"
                className="max-h-[600px]"
              ></img>
              <p>{follower?.name}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PostList;
