import { useForm } from "react-hook-form";

export default function TriggerEditForm({ initialData, onSubmit }) {
  // Initialize the form with default values
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: initialData?.name || "",
      description: initialData?.description || "",
      query: JSON.stringify(initialData?.query || {}, null, 2), // Default as JSON string
      targetTable: initialData?.targetTable || "",
    },
  });

  const submitForm = (data) => {
    try {
      // Parse the query string into an object
      const parsedQuery = JSON.parse(data.query);
      const formattedData = { ...data, query: parsedQuery };
      console.log("Form submitted:", formattedData);
      onSubmit(formattedData);
    } catch (error) {
      console.error("Invalid query JSON format:", error);
      alert("Query must be a valid JSON object.");
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className="space-y-4 p-2">
      <div className="space-y-2">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Name
        </label>
        <input
          type="text"
          id="name"
          {...register("name", { required: "Name is required" })}
          placeholder="Enter name"
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
        {errors.name && <p className="text-red-600 text-sm">{errors.name.message}</p>}
      </div>

      <div className="space-y-2">
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          Description
        </label>
        <textarea
          id="description"
          {...register("description")}
          placeholder="Enter description"
          rows={3}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="query" className="block text-sm font-medium text-gray-700">
          Query (JSON format)
        </label>
        <textarea
          id="query"
          {...register("query", { required: "Query is required" })}
          placeholder='Enter query (e.g., {"isCompleted": true, "prepareMarketing": "OffMarket"})'
          rows={6}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
        {errors.query && <p className="text-red-600 text-sm">{errors.query.message}</p>}
      </div>

      <div className="space-y-2">
        <label htmlFor="targetTable" className="block text-sm font-medium text-gray-700">
          Target Table
        </label>
        <input
          type="text"
          id="targetTable"
          {...register("targetTable", { required: "Target Table is required" })}
          placeholder="Enter target table (e.g., Properties)"
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
        {errors.targetTable && (
          <p className="text-red-600 text-sm">{errors.targetTable.message}</p>
        )}
      </div>

      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </form>
  );
}
