import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      setRole(localStorage.getItem('role'))
    }
    setLoading(false); // Set loading to false after checking token
  }, []);

  const login = (token,role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('role',role)
    setIsAuthenticated(true);
    setRole(localStorage.getItem('role'))
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role')
    setIsAuthenticated(false);
    setRole(null)
  };
  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout,role }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
