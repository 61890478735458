import React from 'react'
import  Select  from 'react-select'
const SelectComponent = ({
    field,
    label,
    options,
    ...props
}) => {
    return (
        <Select
        {...field}
        options={options}
        classNamePrefix="react-select"
        placeholder={`Select ${label}`}
        {...props}
      />
    );
  };
  

export default SelectComponent
