import React, { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import PageLoader from "../components/ui/PageLoader";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import Modal from "../components/ui/Modal";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import UserAdd from "components/user/UserAdd";
import { useAddAdminMutation, useDeleteAdminMutation, useGetAdminQuery, useMeQuery, useUpdateAdminMutation } from "apis/user";
import { FaEdit } from "react-icons/fa";

const Admin = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);
  const [pageGroup, setPageGroup] = useState(0); // State for pagination groups
  const navigate = useNavigate();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const{
    data,
    isPending:isPromptsPending,
    isError:isPromptsError,
    error:PromptsError,
  }=useGetAdminQuery();

  const{
    mutate:addAdmin,
     isError, error, isLoading, isSuccess, isPending
  }=useAddAdminMutation();

  const{
    mutate:deleteAdmin,
    isError:isDeleteError,
    error:deleteError,
    isLoading:isDeleteLoading,
    isSuccess:isDeleteSuccess,
  }=useDeleteAdminMutation();

  const{
    mutate:updateAdmin,
  }=useUpdateAdminMutation();
  const {
    data:me
  }=useMeQuery();
  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "#",
        cell: (info) => info.row.index + 1,
        enableColumnFilter: false, // Disables search for this column
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "role",
        header: "Role",
        cell: ({ row }) => (
          <div>
            {row.original.role === "superAdmin" ? "Super Admin" :row.original.role === "beleefAdmin"?"Beleef Admin":row.original.role === "meloAdmin"?"Melo Admin"
            
            : "Super Admin"}
          </div>
        ),
        
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => (
          <div>
            {row.original.status === "active"
              ? "Active"
              : row.original.status === "inactive"
              ? "Inactive"
              : "Pending"}
          </div>
        ),
      },
      {
        accessorKey:"createdAt",
        header:"Created At",
        cell:({row})=>(
          <div>
            {new Date(row.original.createdAt).toLocaleDateString()}
          </div>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex items-center space-x-2">
         <button
            onClick={() => {
              setSelectedId(row.original._id);
              setIsEditOpen(true);
            }}
            className="view-icon"
          >
            <FaEdit 
              fontSize={20}
            />
          </button>
          <button
            onClick={() => {
              
              setSelectedId(row.original._id);
              deleteAdmin(row.original._id);
            }}
            className="view-icon"
          >
            <MdDelete
              fontSize={20}

            />
          </button> 
          
          
          </div>
        ),
      },
    ],
    [navigate]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
  });

  if (!data.length) {
    return <PageLoader />;
  }

  const totalPages = table.getPageCount();
  const pagesPerGroup = 10;
  const currentGroupStart = pageGroup * pagesPerGroup;
  const currentGroupEnd = Math.min(
    currentGroupStart + pagesPerGroup,
    totalPages
  );

  const handleNextGroup = () => {
    if (currentGroupEnd < totalPages) {
      setPageGroup(pageGroup + 1);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
    }
  };

  return (
    <>
<Modal
      isOpen={isEditOpen}
      onClose={
        () => setIsEditOpen(false)
      }
      title="Edit Admin"
      className="max-w-2xl"
    >
    <UserAdd
  initialName={data.find((d) => d._id === selectedId)?.name}
  initialEmail={data.find((d) => d._id === selectedId)?.email}
  initialRole={data.find((d) => d._id === selectedId)?.role}
  onSubmit={(data) => {
    updateAdmin({
      ...data,
      id: selectedId,
    });
    setIsEditOpen(false);
  }}
/>
    </Modal>
    <Modal
      isOpen={isAddOpen}
      onClose={
        () => setIsAddOpen(false)
      }
      title="Create Admin"
      className="max-w-2xl"
    >
    <UserAdd
  initialName=""
  initialEmail=""
  initialRole=""
  onSubmit={(data) => {
    addAdmin(data);
    setIsAddOpen(false);
  }}
/>
    </Modal>    
    
    <div className="container mx-auto">
      <div className="pagination-info flex justify-between
      align-center items-center
      ">
        <p>
        Showing {pagination.pageIndex * pagination.pageSize + 1}-
        {Math.min(
          (pagination.pageIndex + 1) * pagination.pageSize,
          data.length
        )}{" "}
        of {data.length} items.
        </p>
        <button
                className="mt-4 black-button flex"

        onClick={() => setIsAddOpen(true)}
        >
        
        <FaPlus size={16} className="mr-1" />
            Add Admin
        </button>
      </div>
      <div className="table-responsive">
        <table className="styled-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className="text-sm cursor-pointer flex center items-center"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div className="flex flex-col ml-2">
                        <i
                          className={`fa-solid fa-caret-up ${
                            header.column.getIsSorted() === "asc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                        <i
                          className={`fa-solid fa-caret-down ${
                            header.column.getIsSorted() === "desc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                      </div>
                    </div>
                    {header.column.getCanFilter() &&
                    (header.column.id !== "index" ) && (
                        <input
                          type="text"
                          value={header.column.getFilterValue() || ""}
                          onChange={(e) =>
                            header.column.setFilterValue(e.target.value)
                          }
                          placeholder={`Search ${header.column.id}`}
                          className="column-filter"
                        />
                      
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <button onClick={handlePrevGroup} disabled={pageGroup === 0}>
          &laquo;
        </button>
        {Array.from({ length: currentGroupEnd - currentGroupStart }, (_, i) => (
          <button
            key={i}
            onClick={() => table.setPageIndex(currentGroupStart + i)}
            className={
              currentGroupStart + i === pagination.pageIndex ? "active" : ""
            }
          >
            {currentGroupStart + i + 1}
          </button>
        ))}
        <button
          onClick={handleNextGroup}
          disabled={currentGroupEnd >= totalPages}
        >
          &raquo;
        </button>
      </div>
    </div>
    </>
  );
};

export default Admin;
