import React from 'react';
import { FaHome, FaBuilding, FaDollarSign, FaChartLine, FaCalendarAlt } from 'react-icons/fa';
import { FaTags } from 'react-icons/fa6';

const SuburbView = ({ data }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-AU', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <div>
      <div className="text-center mb-8">
        <h4>{data.suburb}</h4>
        <p className="text-xl text-gray-600">{data.state} {data.postcode}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <StatCard icon={<FaHome className="w-6 h-6" />} title="House Stats" data={data.houseStats[0]} />
        <StatCard icon={<FaBuilding className="w-6 h-6" />} title="Unit Stats" data={data.unitStats[0]} />
      </div>

      <div className="border border-gray-200 rounded-lg p-4 mb-8">
        <h4>Description</h4>
        <p className="text-gray-600">{data.description}</p>
      </div>

      <div className="mb-8">
        <h4 
        className="flex items-center"
        >
          <FaTags className="mr-2" />
          Pricing
        </h4>
        <div className="bg-gray-100 rounded-lg p-4">
          <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-300">
            <h4>REA Price</h4>
            <span className="text-gray-600">{formatCurrency(data.reaPrice)}</span>
          </div>
          <div>
            <h4>Domain Price Tiers</h4>
            <div className="space-y-2">
              {data.domainPrice.map((tier, index) => (
                <div key={index} className="flex justify-between items-center py-2 border-b border-gray-200 last:border-b-0">
                  <span className="text-gray-600">
                    {formatCurrency(tier.minPrice)} - {formatCurrency(tier.maxPrice)}
                  </span>
                  <span className="font-semibold">{formatCurrency(tier.fee)}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="text-sm text-gray-500 mt-4 flex justify-between items-center">
        <p>Listings Fetched: {data.listingsFetched ? 'Yes' : 'No'}</p>
        <p className="flex items-center">
          <FaCalendarAlt className="mr-1" /> Last Updated: {formatDate(data.updatedAt)}
        </p>
      </div>
    </div>
  );
};

const StatCard = ({ icon, title, data }) => (
  <div className="border border-darkgray rounded-lg p-4">
    <div className="flex items-center mb-4">
      <span className="text-black mr-2">{icon}</span>
      <h3 className="text-xl font-semibold">{title}</h3>
    </div>
    <ul className="space-y-2 ml-3">
      <li className="flex items-center">
        <FaDollarSign className="mr-2 text-gray-600" />
        <span>Median Price: {new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(data.medianSalePrice)}</span>
      </li>
      <li className="flex items-center">
        <FaChartLine className="mr-2 text-gray-600" />
        <span>Annual Sales: {data.annualSalesVolume}</span>
      </li>
      <li className="flex items-center">
        <FaChartLine className="mr-2 text-gray-600" />
        <span>Suburb Growth: {data.suburbGrowth}</span>
      </li>
      <li
      className="flex items-center"
      >High Demand: {data.highDemandArea}</li>
    </ul>
  </div>
);

export default SuburbView;