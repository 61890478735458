import Button from 'components/ui/Button'
import React from 'react'
import { useForm, useFieldArray } from 'react-hook-form'

export default function SuburbEdit({ data, onSave }) {
  const { register, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      ...data,
      houseStats: data.houseStats[0],
      unitStats: data.unitStats[0],
      domainPrice: data.domainPrice
    }
  })

  const { fields: domainPriceFields } = useFieldArray({
    control,
    name: "domainPrice"
  })

  const onSubmit = (formData) => {
    const updatedData = {
      ...formData,
      houseStats: [formData.houseStats],
      unitStats: [formData.unitStats]
    }
    console.log(updatedData)
    onSave(updatedData)
  }

  return (
    <div>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="suburb" className="form-label">Suburb</label>
            <input
              {...register("suburb", { required: "Suburb is required" })}
              className="form-input border border-mediumgray"
            />
            {errors.suburb && <p className="mt-1 text-sm text-red-600">{errors.suburb.message}</p>}
          </div>
          <div>
            <label htmlFor="postcode" className="form-label">Postcode</label>
            <input
              {...register("postcode", { required: "Postcode is required" })}
              className="form-input border border-mediumgray"
            />
            {errors.postcode && <p className="mt-1 text-sm text-red-600">{errors.postcode.message}</p>}
          </div>
        </div>

        <div>
          <label htmlFor="state" className="form-label">State</label>
          <input
            {...register("state", { required: "State is required" })}
            className="form-input border border-mediumgray"
          />
          {errors.state && <p className="mt-1 text-sm text-red-600">{errors.state.message}</p>}
        </div>

        <div>
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            {...register("description")}
            rows={4}
            className="form-textarea border border-mediumgray"
          />
        </div>
        
        <div>
          <label htmlFor="reaPrice" className="form-label">REA Price</label>
          <input
            {...register("reaPrice", { required: "REA Price is required" })}
            className="form-input border border-mediumgray"
          />
          {errors.reaPrice && <p className="mt-1 text-sm text-red-600">{errors.reaPrice.message}</p>}
        </div>
        <div className="pt-2">
          <h3 className="text-lg font-medium text-gray-900 mb-2">House Stats</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="houseStats.medianSalePrice" className="form-label">Median Sale Price</label>
              <input
                {...register("houseStats.medianSalePrice", { valueAsNumber: true })}
                type="number"
                className="form-input border border-mediumgray"
              />
            </div>
            <div>
              <label htmlFor="houseStats.annualSalesVolume" className="form-label">Annual Sales Volume</label>
              <input
                {...register("houseStats.annualSalesVolume", { valueAsNumber: true })}
                type="number"
                className="form-input border border-mediumgray"
              />
            </div>
            <div>
              <label htmlFor="houseStats.suburbGrowth" className="form-label">Suburb Growth</label>
              <input
                {...register("houseStats.suburbGrowth")}
                className="form-input border border-mediumgray"
              />
            </div>
            <div>
              <label htmlFor="houseStats.highDemandArea" className="form-label">High Demand Area</label>
              <div className="mt-1 space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    {...register("houseStats.highDemandArea")}
                    value="Yes"
                    className="form-radio text-indigo-600"
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    {...register("houseStats.highDemandArea")}
                    value="No"
                    className="form-radio text-indigo-600"
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-2">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Unit Stats</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="unitStats.medianSalePrice" className="form-label">Median Sale Price</label>
              <input
                {...register("unitStats.medianSalePrice", { valueAsNumber: true })}
                type="number"
                className="form-input border border-mediumgray"
              />
            </div>
            <div>
              <label htmlFor="unitStats.annualSalesVolume" className="form-label">Annual Sales Volume</label>
              <input
                {...register("unitStats.annualSalesVolume", { valueAsNumber: true })}
                type="number"
                className="form-input border border-mediumgray"
              />
            </div>
            <div>
              <label htmlFor="unitStats.suburbGrowth" className="form-label">Suburb Growth</label>
              <input
                {...register("unitStats.suburbGrowth")}
                className="form-input border border-mediumgray"
              />
            </div>
            <div>
              <label htmlFor="unitStats.highDemandArea" className="form-label">High Demand Area</label>
              <div className="mt-1 space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    {...register("unitStats.highDemandArea")}
                    value="Yes"
                    className="form-radio text-indigo-600"
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    {...register("unitStats.highDemandArea")}
                    value="No"
                    className="form-radio text-indigo-600"
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-2">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Domain Prices</h3>
          {domainPriceFields.map((field, index) => (
            <div key={field.id} className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div>
                <label htmlFor={`domainPrice.${index}.minPrice`} className="form-label">Min Price</label>
                <input
                  {...register(`domainPrice.${index}.minPrice`, { valueAsNumber: true })}
                  type="number"
                  className="form-input border border-mediumgray"
                />
              </div>
              <div>
                <label htmlFor={`domainPrice.${index}.maxPrice`} className="form-label">Max Price</label>
                <input
                  {...register(`domainPrice.${index}.maxPrice`, { valueAsNumber: true })}
                  type="number"
                  className="form-input border border-mediumgray"
                />
              </div>
              <div>
                <label htmlFor={`domainPrice.${index}.fee`} className="form-label">Fee</label>
                <input
                  {...register(`domainPrice.${index}.fee`, { valueAsNumber: true })}
                  type="number"
                   className="form-input border border-mediumgray"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-2 pt-2">
          <Button
            type="submit"
            className="black-button"
          >
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  )
}