// src/components/Login/Login.js
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/ui/Button"
import { useSendEmailMutation, useSetPasswordMutation, useUpdatePasswordMutation } from "apis/auth";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Modal from "components/ui/Modal";
import UserEmail from "components/user/UserEmail";
const AddPassword = () => {
    const [showpassword, setShowpassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const{
      mutate:updatePassword,
      isError, error, isLoading, isSuccess, isPending
  }=useSetPasswordMutation();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const{
    mutate:sendEmail,
  }=useSendEmailMutation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm(

    {
        mode: "onBlur",
      defaultValues: {
        password: "",
        confirmPassword: "",
      }
    }
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const onSubmit = async (data) => {
    updatePassword({
      ...data,
      token: searchParams.get("token"),
    });
  };

  return (
    <>
    <Modal
      isOpen={isAddOpen}
      onClose={
        () => setIsAddOpen(false)
      }
      title="Prompt Add"
      className="max-w-2xl"
    >
    <UserEmail
  initialEmail=""
  onSubmit={(data) => {
    sendEmail(data);
    setIsAddOpen(false);
  }}
/>
    </Modal>  
    <div className="min-h-screen py-8 px-4 flex items-center justify-center">
      <form
        className="border border-mediumgray p-8 rounded-md w-full max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >

        <h4 className="text-center">Add Password</h4>
       
        <div className="my-4 relative">
          <input
            type={showpassword ? "text" : "password"}
            ref={passwordRef}
            {...register("password", {
              required: "New password is required",
              minLength: { value: 8, message: "Password must be at least 8 characters" },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                message: "Password must contain at least one numeric digit, one uppercase, one lowercase, and at least 8 characters",
              },
            })}
            className={`form-input border ${errors.password ? "border-red-500" : "border-mediumgray"}`}
            placeholder="New Password"
          />
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => setShowpassword((prev) => !prev)}
          >
            {showpassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
          </span>
          {errors.password && <p className="form-error-message">{errors.password.message}</p>}
        </div>
        <div className="my-4 relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            {...register("confirmPassword", {
              required: "Confirm password is required",
              minLength: { value: 8, message: "Password must be at least 8 characters" },
              validate: (value) => value === getValues('password') || "The passwords do not match",
            })}
            className={`form-input border ${errors.confirmPassword ? "border-red-500" : "border-mediumgray"}`}
            placeholder="Confirm Password"
          />
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => setShowConfirmPassword((prev) => !prev)}
          >
            {showConfirmPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
          </span>
          {errors.confirmPassword && <p className="form-error-message">{errors.confirmPassword.message}</p>}
        </div>
        <div className="text-right mb-4">
          <Link onClick={
            () => setIsAddOpen(true)
          } className="text-primary">
            Resend Email?
          </Link>
        </div>
        <Button type="submit" className="w-full black-button" 
        loading={isPending} disabled={isPending}
        >
          {isPending ? "Loading..." : "Set Password"}
        </Button>
      </form>
    </div></>
    
  );
};

export default AddPassword;
