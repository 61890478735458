import React, { useState, useEffect } from "react";
import axios from "utils/axios";
import { useNavigate } from "react-router-dom";
import Kanban from "components/ui/Kanban";

const Edits = () => {
  return (
    <div className="container">
      <Kanban />
    </div>
  );
};

export default Edits;
