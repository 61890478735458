// src/components/Login/Login.js
import React, { useState, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import axios from "../utils/axios";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../context/AuthContext";
import Button from "../components/ui/Button"
import {showToast} from "../components/ui/toast"
import logo from "../assets/images/logo.png";
import Modal from "components/ui/Modal";
import UserAdd from "components/user/UserAdd";
import UserEmail from "components/user/UserEmail";
import { useSendEmailMutation } from "apis/auth";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const{
    mutate:sendEmail,
      isError, error, isLoading, isSuccess, isPending
  }=useSendEmailMutation();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [loading,setLoading]=useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const res = await axios.post(
        `/admin/auth/login`,
        data
      );
      showToast("login","Login Successfully")
      login(res.data.token, res?.data?.user?.role);
      // const lastVisitedPage = localStorage.getItem("lastVisitedPage") || "/";
      navigate("/");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      showToast("error",errorMessage)
    }finally{
      setLoading(false)
    }
  };

  return (
    <>
    <Modal
      isOpen={isAddOpen}
      onClose={
        () => setIsAddOpen(false)
      }
      title="Prompt Add"
      className="max-w-2xl"
    >
    <UserEmail
  initialName=""
  initialEmail=""
  onSubmit={(data) => {
    sendEmail(data);
    setIsAddOpen(false);
  }}
/>
    </Modal>   
    <div className="min-h-screen py-8 px-4 flex items-center justify-center">
      <form
        className="border border-mediumgray p-8 rounded-md w-full max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex justify-center items-center w-full pb-8">
          <img
            src={logo}
            alt="Chatbot"
            className="w-[150px] h-auto" 
          />
        </div>

        <h4 className="text-center">Admin Login</h4>
        <div className="my-4">
          <input
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
            })}
            className={`form-input border ${
              errors.email 
                ? "border-red-500"
                : "border-mediumgray"
            }`}
            placeholder="Email"
          />
          {errors.email && (
            <p className="form-error-message">{errors.email.message}</p>
          )}
        </div>
        <div className="mb-6">
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Password is required",
              })}
              className={`form-input border ${
                errors.password 
                  ? "border-red-500"
                  : "border-mediumgray"
              }`}
              placeholder="Password"
            />
            {passwordRef.current && (
              <span
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              >
                {showPassword ? (
                  <i className="fa-regular fa-eye text-gray-400"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash text-gray-400"></i>
                )}
              </span>
            )}
          </div>
          {errors.password && (
            <p className="form-error-message">
              {errors.password.message}
            </p>
          )}
        </div>
        {/* forgot password link */}
        <div className="text-right mb-4">
          <Link onClick={
            () => setIsAddOpen(true)
          } className="text-primary">
            Forgot Password?
          </Link>
        </div>
        <Button type="submit" className="w-full black-button" loading={loading} disabled={loading}>
          Login
        </Button>
      </form>
    </div></>
    
  );
};

export default Login;
