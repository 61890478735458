import React, { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import PageLoader from "../components/ui/PageLoader";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import { FaEdit } from "react-icons/fa";
import Modal from "../components/ui/Modal";
import { MdDelete } from "react-icons/md";
import {
  useAddTemplateMutation,
  useDeleteTemplateMutation,
  useGetAllTemplatesQuery,
  useUpdateTemplateMutation,
} from "apis/templates";
import { FaEye, FaPlus } from "react-icons/fa6";
import TemplateEdit from "components/templates/edit";
import TemplateView from "components/templates/view";

const Templates = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);
  const [pageGroup, setPageGroup] = useState(0); // State for pagination groups
  const navigate = useNavigate();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { mutate: updateTemplate, isLoading: isTemplateLoading } =
    useUpdateTemplateMutation();
  const {
    templates = [],
    triggers = [],
    isPending: isTemplatesPending,
    isError: isTemplatesError,
    error: TemplatesError,
  } = useGetAllTemplatesQuery();
  const {
    mutate: addTemplate,
    isError,
    error,
    isLoading,
    isSuccess,
    isPending,
  } = useAddTemplateMutation();

  const {
    mutate: deleteTemplate,
    isError: isDeleteError,
    error: deleteError,
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
  } = useDeleteTemplateMutation();

  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "#",
        cell: (info) => info.row.index + 1,
        enableColumnFilter: false, // Disables search for this column
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "action",
        header: "Action",
      },
      {
        accessorKey: "time",
        header: "Time",
      },
      {
        accessorKey: "triggerId.name",
        header: "Trigger Name",
        cell: ({ row }) => row.original.triggerId?.name || "No trigger linked", // Safely access nested trigger name
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => {
                setSelectedId(row.original._id);
                setIsViewOpen(true);
              }}
              className="view-icon"
            >
              <FaEye fontSize={20} />
            </button>
            <button
              onClick={() => {
                setSelectedId(row.original._id);
                setIsEditOpen(true);
              }}
              className="view-icon"
            >
              <FaEdit fontSize={20} />
            </button>
            <button
              onClick={() => {
                setSelectedId(row.original._id);
                deleteTemplate(row.original._id);
              }}
              className="view-icon"
            >
              <MdDelete fontSize={20} />
            </button>
          </div>
        ),
      },
    ],
    [navigate]
  );
  
  const table = useReactTable({
    data: templates,
    columns,
    state: {
      columnFilters,
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
  });

  if (isTemplateLoading) {
    return <PageLoader />;
  }

  const totalPages = table.getPageCount();
  const pagesPerGroup = 10;
  const currentGroupStart = pageGroup * pagesPerGroup;
  const currentGroupEnd = Math.min(
    currentGroupStart + pagesPerGroup,
    totalPages
  );

  const handleNextGroup = () => {
    if (currentGroupEnd < totalPages) {
      setPageGroup(pageGroup + 1);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
    }
  };

  return (
    <>
      <Modal
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        title="Template Edit"
        className="max-w-2xl"
      >
        <TemplateEdit
          initialData={templates.find(
            (Template) => Template._id === selectedId
          )}
          triggers={triggers}
          onSubmit={(data) => {
            updateTemplate({ id: selectedId, ...data });
            setIsEditOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        title="Template Add"
        className="max-w-2xl"
      >
        <TemplateEdit
          initialData=""
          triggers={triggers}
          onSubmit={(data) => {
            addTemplate(data);
            setIsAddOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={isViewOpen}
        onClose={() => setIsViewOpen(false)}
        title="Template Details"
        className="max-w-2xl"
      >
        <TemplateView
          data={templates.find((Template) => Template._id === selectedId)}
        />
      </Modal>

      <div className="container mx-auto">
        <div
          className="pagination-info flex justify-between
      align-center items-center
      "
        >
          <p>
            Showing {pagination.pageIndex * pagination.pageSize + 1}-
            {Math.min(
              (pagination.pageIndex + 1) * pagination.pageSize,
              templates.length
            )}{" "}
            of {templates.length} items.
          </p>
          <button
            className="black-button flex"
            onClick={() => setIsAddOpen(true)}
          >
            <FaPlus size={16} className="mr-1" />
            Add Template
          </button>
        </div>
        <div className="table-responsive">
          <table className="styled-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        className="text-sm cursor-pointer flex center items-center"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <div className="flex flex-col ml-2">
                          <i
                            className={`fa-solid fa-caret-up ${
                              header.column.getIsSorted() === "asc"
                                ? "text-primary"
                                : ""
                            }`}
                            style={{ lineHeight: "0.5" }}
                          ></i>
                          <i
                            className={`fa-solid fa-caret-down ${
                              header.column.getIsSorted() === "desc"
                                ? "text-primary"
                                : ""
                            }`}
                            style={{ lineHeight: "0.5" }}
                          ></i>
                        </div>
                      </div>
                      {header.column.getCanFilter() &&
                        header.column.id !== "index" && (
                          <input
                            type="text"
                            value={header.column.getFilterValue() || ""}
                            onChange={(e) =>
                              header.column.setFilterValue(e.target.value)
                            }
                            placeholder={`Search ${header.column.id}`}
                            className="column-filter"
                          />
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-controls">
          <button onClick={handlePrevGroup} disabled={pageGroup === 0}>
            &laquo;
          </button>
          {Array.from(
            { length: currentGroupEnd - currentGroupStart },
            (_, i) => (
              <button
                key={i}
                onClick={() => table.setPageIndex(currentGroupStart + i)}
                className={
                  currentGroupStart + i === pagination.pageIndex ? "active" : ""
                }
              >
                {currentGroupStart + i + 1}
              </button>
            )
          )}
          <button
            onClick={handleNextGroup}
            disabled={currentGroupEnd >= totalPages}
          >
            &raquo;
          </button>
        </div>
      </div>
    </>
  );
};

export default Templates;
