import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import axios from "utils/axios";
import { HiChevronDown } from "react-icons/hi2";
import GoogleMaps from "components/GoogleMaps";
import GoogleChart from "components/GoogleChart";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "components/ui/Tooltip";
import arrow from "assets/images/arrow.png";
import GooglePieChart from "components/GooglePieChart";
import { AuthContext } from "context/AuthContext";
import Modal from "components/ui/Modal";
import propertyImg1 from "assets/images/property-img-1.jpg";
import propertyImg2 from "assets/images/property-img-2.png";
import propertyImg3 from "assets/images/property-img-3.jpg";
import propertyImg4 from "assets/images/property-img-4.jpg";
import propertyImg5 from "assets/images/property-img-5.jpg";
import propertyImg6 from "assets/images/property-img-6.jpg";
import propertyImg7 from "assets/images/property-img-7.jpg";
import processExampleImg from "assets/images/process-example.png";
import "./PriceProcess.css";
import RecommendedSalesProcess from "./RSP";
import PageLoader from "components/ui/PageLoader";
import { formatCurrency } from "utils/helperFunctions";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: true,
  slidesToShow: 1, // Show only 2 slides if they are large in width
  slidesToScroll: 1,
  variableWidth: true, // Allows variable width for slides
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const RecentAreaSoldProcess = ({ recentAreaSoldProcess }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [filterMonths, setFilterMonths] = useState(12); // Default is 12 months
  const [suggestions, setSuggestions] = useState([]); // Store all suggestions from the API
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // Store filtered suggestions based on user input
  const [searchTerm, setSearchTerm] = useState(""); // Search input value
  const [showSuggestions, setShowSuggestions] = useState(false); // To toggle the visibility of the dropdown
  const [filteredRecentAreaSoldProcess, setFilteredRecentAreaSoldProcess] =
    useState(recentAreaSoldProcess); // Store filtered data

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index); // Toggle row
  };

  // Fetch all suggestions once when the component mounts
  useEffect(() => {
    axios
      .get("/property/suburbName")
      .then((response) => {
        const suburbData = response.data.data; // Assuming the response has a `data` field containing suburbs
        setSuggestions(suburbData); // Store all suggestions
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
      });
  }, []);

  // Handle search term change and filter suggestions locally
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    // Show the dropdown only when the user starts typing
    if (searchValue.length > 0) {
      setShowSuggestions(true);

      // Filter suggestions based on the search term
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSuggestions(filtered); // Update filtered suggestions based on input
    } else {
      setShowSuggestions(false); // Hide the dropdown if the input is empty
    }
  };
  const [loading, setLoading] = useState(false);
  // Handle suggestion click and call API to replace recentAreaSoldProcess
  const handleSuggestionClick = async (suggestion) => {
    try {
      setLoading(true); // Set loading to true before making the API call
      const response = await axios.post(
        `/property/recentAreaSoldProcess/${suggestion}/House`
      );

      setFilteredRecentAreaSoldProcess(response.data.data); // Replace recentAreaSoldProcess data with new data
      setSearchTerm(suggestion); // Set the selected suggestion as the input value
      setShowSuggestions(false); // Hide the dropdown after a suggestion is selected
      setFilterMonths(12);
    } catch (error) {
      console.error("Error fetching recent area sold process:", error);
    } finally {
      setLoading(false); // Set loading to false once the API call is completed
    }
  };

  const calculateMonthsAgo = (months) => {
    const date = new Date();
    date.setMonth(date.getMonth() - months);
    return date;
  };

  const twelveMonthsAgo = calculateMonthsAgo(filterMonths); // Based on selected months
  const parseDate = (dateString) => new Date(dateString);

  useEffect(
    () =>
      setFilteredRecentAreaSoldProcess(
        filteredRecentAreaSoldProcess.filter((property) => {
          const saleDateValue =
            property?.saleHistory?.sales?.[0]?.saleDate?.value;
          if (saleDateValue) {
            const saleDate = parseDate(saleDateValue); // Convert string to Date object
            return saleDate >= twelveMonthsAgo; // Perform the comparison
          }
          return false; // Exclude properties without a valid saleDate.value
        })
      ),
    [filterMonths]
  );

  return (
    <>
      {filteredRecentAreaSoldProcess &&
        filteredRecentAreaSoldProcess.length > 0 && (
          <div className="w-full space-y-4 text-center">
            <div>
              <h4 className="text-center">RECENT AREA SOLD PROCESS</h4>
              <div className="relative py-4">
                <input
                  className="search-input bg-lightgray rounded-md w-full py-2 pr-3 pl-8"
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange} // Update search term and filter suggestions
                />
                <div className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="text-sm text-darkergray"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="none"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64z"
                    ></path>
                    <path
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M338.29 338.29 448 448"
                    ></path>
                  </svg>
                </div>
                {/* Display filtered suggestions below input */}
              </div>
              {showSuggestions && filteredSuggestions.length > 0 && (
                <ul className="form-input border border-mediumgray bg-white w-full p-0 py-1 m-0 mt-2 list-none max-h-[120px] overflow-y-auto text-start">
                  {filteredSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)} // Pass full user object
                      className="px-2 py-1 cursor-pointer hover:bg-lightgray m-0"
                    >
                      {suggestion} {/* Display user name */}
                    </li>
                  ))}
                </ul>
              )}
              {loading && (
                <p>
                  <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                  Loading ...
                </p>
              )}
              <div className="mt-2 flex items-center justify-end">
                {/* Select box for choosing months */}
                <label className="mr-2">Show sales from the last:</label>
                <select
                  value={filterMonths}
                  onChange={(e) => setFilterMonths(Number(e.target.value))}
                  className="form-select border border-mediumgray w-auto min-w-[100px] py-1 px-2 min-h-[20px]"
                >
                  <option value={6}>6 months</option>
                  <option value={12}>12 months</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full text-sm text-center border-collapse border  mx-auto">
                <thead>
                  <tr className="border-b ">
                    <th className="hidden sm:table-cell py-2 px-3 border-r "></th>
                    <th className="py-2 px-3 border-r  min-w-[120px]">
                      Address
                    </th>
                    <th className="hidden sm:table-cell py-2 px-3 border-r ">
                      Agency
                    </th>
                    <th className="py-2 px-3 border-r  min-w-[100px]">
                      Process
                    </th>
                    <th className="py-2 px-3 border-r  min-w-[75px]">
                      Sale Price
                    </th>
                    <th className="py-2 px-3">Days on Market</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecentAreaSoldProcess.map((property, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`border-b  cursor-pointer hover:bg-lightgray ${
                          expandedRow === index ? "bg-mediumgray" : ""
                        }`}
                        onClick={() => toggleRow(index)}
                      >
                        <td className="hidden sm:table-cell py-2 px-3 border-r ">
                          {index + 1}
                        </td>
                        <td className="py-2 px-3 border-r ">
                          {property.address}
                        </td>
                        <td className="hidden sm:table-cell py-2 px-3 border-r ">
                          {property.listingHistory?.listings?.[0]?.agencies?.[0]
                            ?.name || "N/A"}
                        </td>
                        <td className="py-2 px-3 border-r ">
                          {property.beleefSaleProcess || "N/A"}
                        </td>
                        <td className="py-2 px-3 border-r  ">
                          {property?.saleHistory?.sales?.[0]?.price?.value
                            ? `$${property.saleHistory?.sales?.[0]?.price.value.toLocaleString()}`
                            : "N/A"}
                        </td>
                        <td className="py-2 px-3 w-24">
                          {property?.saleHistory?.sales?.[0]?.listingHistory
                            ?.daysToSell + 1 || "N/A"}
                        </td>
                      </tr>

                      {expandedRow === index && (
                        <tr className="border-b ">
                          <td colSpan="6" className="border-t ">
                            <div className="p-4">
                              <table className="bg-lightergray min-w-full table-auto border-collapse border">
                                <thead>
                                  <tr>
                                    <th className="px-3 py-2 border">Date</th>
                                    <th className="px-3 py-2 border">
                                      Listing Price
                                    </th>
                                    <th className="px-3 py-2 border">
                                      Agency Name
                                    </th>
                                    <th className="px-3 py-2 border">
                                      Agent Name
                                    </th>
                                    <th className="px-3 py-2 border">DOM</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {property?.listingHistory?.listings.map(
                                    (listing, index) => (
                                      <tr
                                        key={index}
                                        className="hover:bg-darkgray"
                                      >
                                        <td className="px-3 py-2 border">
                                          {listing.startDate?.display || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.price?.display || "N/A"}
                                        </td>

                                        <td className="px-3 py-2 border">
                                          {listing.agencies?.[0]?.name || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.agents?.[0]?.name || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.listingHistory
                                            ?.daysOnMarket || "N/A"}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="text-start text-xs">
              *Last {filterMonths} months sales
            </p>
          </div>
        )}
    </>
  );
};

const ProcessChain = ({ processChain }) => {
  return (
    <div className="w-full space-y-16">
      <h4>THE PROCESS CHAIN</h4>
      <div className="w-full flex overflow-x-auto box-scrollbar">
        {processChain.map((step, index) => (
          <div key={index} className="flex items-center">
            <div>
              <div className="flex items-center justify-center border-4 chain border-gray-300 bg-white text-gray-500 cursor-pointer">
                {step.selected === true && "✔️"}
                {step.selected === false && "❌"}
                {step.selected === null && ""} {/* Initial state */}
              </div>
              <p className="mt-2 mb-0 min-h-[32px] max-w-[100px] text-xs">
                {step.name}
              </p>
            </div>
            {index < processChain.length - 1 && (
              <div className="chain-stick bg-gray-300"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const PriceProcess = ({
  property, // Use 'property' directly
}) => {
  const [loading, setLoading] = useState(false);

  const {
    logicalPrice,
    microPockets,
    saleProperties = [],
    soldProperties = [],
    logicalReasoning,
    engagedPurchaser,
    recentAreaSoldProcess = [],
    duplexProperties = [],
    highEndProperties = [],
    lowEndProperties = [],
    recommendedSaleProcess,
  } = property || {}; // Destructure property to get all the values

  const [isPropertyClicked, setIsPropertyClicked] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [areaDynamics, setAreaDynamics] = useState(null);
  const [pieChartData, setPieChartData] = useState([["Process", "Count"]]);
  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    setIsPropertyClicked(true);
  };

  // useEffect(() => {
  //   if (!property?._id) return; // Ensure property exists before making API calls

  //   const fetchAreaDynamics = async () => {
  //     try {
  //       const response = await axios.get(`/property/suburb/${property.suburb}`);

  //       setAreaDynamics(response?.data?.data);
  //     } catch (error) {
  //       console.error("Error fetching area dynamics:", error);
  //     }
  //   };

  //   const fetchBeleefSaleProcess = async () => {
  //     try {
  //       const response = await axios.get(
  //         `/property/beleefSaleProcess/${property.suburb}`
  //       );
  //       const apiData = response.data.data;

  //       // Map the API data to the format required for the chart
  //       const formattedData = [["Process", "Count"]]; // Initialize with header row
  //       for (const process in apiData) {
  //         // Skip "Withdrawn" process
  //         if (process !== "Withdrawn") {
  //           formattedData.push([process, apiData[process]]);
  //         }
  //       }
  //       // Update the chart data
  //       setPieChartData(formattedData);
  //     } catch (error) {
  //       console.error("Error fetching beleef sale process:", error);
  //     }
  //   };

  //   const executeFetchFunctions = async () => {
  //     try {
  //       setLoading(true);
  //       await fetchAreaDynamics(); // Waits for this to complete before moving on
  //       await fetchBeleefSaleProcess(); // Waits for this to complete
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   executeFetchFunctions();

  //   // Only trigger the useEffect when property._id changes
  // }, [property._id]);

  const convertDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  function getAverageValueInUnits(range) {
    // Remove dollar signs and split the range into two numbers
    const [low, high] = range
      .replace(/\$/g, "") // Remove dollar signs
      .split("-") // Split by the hyphen into low and high values
      .map((str) => str.trim()); // Trim spaces in case they exist

    // Function to convert the value based on suffix (M, K, or no suffix)
    const convertToUnits = (value, suffix) => {
      if (suffix === "M") {
        return parseFloat(value) * 1000000; // Convert millions to units
      } else if (suffix === "K") {
        return parseFloat(value) * 1000; // Convert thousands to units
      } else {
        return parseFloat(value); // If no suffix, assume it's already in units
      }
    };

    // Determine if the high value has a suffix (M or K)
    const highSuffix = high.includes("M") ? "M" : high.includes("K") ? "K" : "";

    // Strip suffix from the high value before conversion
    const highValue = high.replace(/[MK]/, "");

    // Convert low and high values to unit form, applying the same suffix to both
    const lowInUnits = convertToUnits(low, highSuffix); // Apply the suffix from high to low
    const highInUnits = convertToUnits(highValue, highSuffix); // Convert high value without suffix

    // Calculate the average
    const average = (lowInUnits + highInUnits) / 2;

    // Return the average in units format, no suffix
    return average;
  }

  // Create dataPoints for GoogleChart
  const dataPoints = [
    [
      property.landArea || 0,
      logicalPrice ? getAverageValueInUnits(logicalPrice) : 0,
      property.address,
    ],
    ...(() => {
      // Get today's date
      const today = new Date();

      // Filter soldProperties based on dateListed within the last 90 days
      const recentSoldProperties = soldProperties.filter(({ property }) => {
        const dateListed = new Date(property.dateListed);
        const diffInDays = (today - dateListed) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
        return diffInDays <= 180 && property.landArea && property.price;
      });

      // If there are more than 5 properties listed in the last 180 days, return them
      if (recentSoldProperties.length > 5) {
        return recentSoldProperties.map(({ property }) => [
          property.landArea,
          property.price,
          property.address,
        ]);
      } else {
        return soldProperties
          .filter(({ property }) => property.landArea && property.price) // Filter properties with both landArea and price
          .map(({ property }) => [
            property.landArea,
            property.price,
            property.address,
          ]);
      }
    })(),
  ];



  if (loading) {
    return <PageLoader text={"Loading your price and process..."} />;
  }

  return (
    <div className="flex flex-col items-center justify-center overflow-x-hidden">
      <div className="w-full h-[90vh] relative pb-16">
        <video
          autoPlay
          muted
          loop
          preload="metadata"
          playsInline
          webkit-playsinline="true"
          className="w-full h-full object-cover"
        >
          <source
            src="https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/videos/postlist-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-12 overflow-x-hidden">
        <h4>{property.address}</h4>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">GET STARTED</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              <div class="text-start space-y-2">
                <h4>Assess situation</h4>
                <p>What is your situation?</p>
              </div>
              <div class="text-start space-y-2">
                <h4>Assess timeline</h4>
                <p>What is your timeline?</p>
              </div>
              <div class="text-start space-y-2">
                <h4>Other Options</h4>
                <p>
                  We want to establish a great reputation in the area and giving
                  people great advice is part of that -{" "}
                </p>

                <p>This is a great property - </p>
                <p>
                  Have you considered other options other then selling such as
                  renting?
                </p>
              </div>
              <div class="text-start space-y-2">
                <h4>Maximum outcome</h4>
                <p>
                  It’s not about selling the property. It’s about getting the
                  maximium outcome. That takes a process. Let’s go through that.
                </p>
              </div>
              {microPockets && (
                <Disclosure as="div" className="w-full" defaultOpen={false}>
                  <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
                    <span className="font-medium">The micro pockets</span>
                    <HiChevronDown className="group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2">
                    <div
                      className="engaged-purchaser"
                      dangerouslySetInnerHTML={{ __html: microPockets }}
                    />
                  </DisclosurePanel>
                </Disclosure>
              )}
              <div className="w-full h-full grayscale flex flex-col items-center justify-center">
                <GoogleMaps lat={property.latitude} lon={property.longitude} />
              </div>

              <div class="w-full max-w-lg mx-auto space-y-4">
                <h4>YOUR AREA DYNAMICS</h4>
                {areaDynamics ? (
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm text-start border border-mediumgray border-collapse">
                      <thead>
                        <tr className="bg-mediumgray border border-mediumgray">
                          <th className="py-2 px-3 border border-mediumgray"></th>
                          <th className="py-2 px-3 text-left border border-mediumgray">
                            <i className="fa-solid fa-house mr-2"></i> House
                          </th>
                          <th className="py-2 px-3 text-left border border-mediumgray">
                            <i className="fa-solid fa-building mr-2"></i> Unit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Median Sale Price
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Annual Sales Volume
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.annualSalesVolume
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.annualSalesVolume
                              : "N/A"}
                          </td>
                        </tr>

                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Suburb Growth
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.suburbGrowth
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.suburbGrowth
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            High Demand Area
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.highDemandArea
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.highDemandArea
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                    Loading ...
                  </div>
                )}
              </div>

              <div class="space-y-8 flex flex-col items-center justify-center">
                <h4>MOST LIKELY ENGAGED PURCHASER</h4>

                {engagedPurchaser && (
                  <div
                    className="engaged-purchaser"
                    dangerouslySetInnerHTML={{ __html: engagedPurchaser }}
                  />
                )}
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">
              STEP 1: LET’S ASSESS LOGICAL PRICE
            </span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              <div className="space-y-8">
                <h4>LOGICAL PRICE</h4>

                <div className="max-w-sm mx-auto space-y-8 flex flex-col items-center justify-center">
                  <div className="flex flex-row justify-between w-full text-sm">
                    <span>Buyers start logically</span>
                    <span>and finish emotionally</span>
                  </div>
                  <img src={arrow} alt="arrow" />
                </div>

                {/* Price and Info Icon */}
                <div className="flex items-center justify-center gap-4">
                  <span className="font-bold">
                    {logicalPrice ? logicalPrice : "N/A"}
                  </span>

                  <Tooltip
                    className="w-[250px]"
                    text={<i className="fa fa-info-circle text-xs"></i>}
                    tooltip="This is just a logical estimated price, and is grounded on a comprehensive set of factors including recent local sales, property size, number of bedrooms, the topography of the land, street traffic, recent updates to the property, and various other determinants. The information is sourced from public datasets which, while extensive, might be incomplete or contain inaccuracies, so should not be solely relied upon. For a more precise and accurate estimation of price, we strongly recommend consulting with a licensed real estate agent or a registered valuer. Occasionally, we may send you updates on the property market"
                  />
                </div>

                {/* Logical Reasoning */}
                {logicalReasoning && (
                  <div className="text-start my-1 text-sm">
                    <span className="font-medium">Reasoning:</span>{" "}
                    {logicalReasoning}
                  </div>
                )}

                {/* Property Details */}
                <div className="max-w-md mx-auto space-y-6">
                  {/* Property Image */}
                  {property?.media?.[0]?.url && (
                    <img
                      className="w-full h-auto rounded-lg grayscale"
                      src={property.media[0].url}
                      alt="property"
                    />
                  )}

                  {/* Property Address */}
                  <p className="text-center">{property.address}</p>

                  {/* Property Features */}
                  <div className="flex items-center justify-center gap-4">
                    <div className="text-sm">
                      <i className="fa fa-bed me-2"></i>
                      {property.bedrooms || "N/A"}
                    </div>
                    <div className="text-sm">
                      <i className="fa fa-bath me-2"></i>
                      {property.bathrooms || "N/A"}
                    </div>
                    <div className="text-sm">
                      <i className="fa fa-car me-2"></i>
                      {property.carspaces || "N/A"}
                    </div>
                    <div className="text-sm">{property.propertyType}</div>
                  </div>

                  {/* Sale Details */}
                  <div className="flex justify-between gap-4 text-left text-sm">
                    <div className="flex flex-col">
                      <span className="font-semibold">Sale Price:</span>
                      <span>{formatCurrency(property.price)}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold">Sale Date:</span>
                      <span>
                        {property.dateListed
                          ? convertDate(property.dateListed)
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold">Days to Sell:</span>
                      <span>{property.daysListed}</span>
                    </div>
                  </div>
                </div>
              </div>

              {soldProperties && soldProperties.length > 0 && (
                <div class="w-full space-y-8">
                  <h4>SOLD MATCHES</h4>
                  <p>
                    Getting the first part of the process right helps to create
                    the maximum outcome. This is a logical range derived from
                    market insights and the final outcome may vary significantly
                  </p>
                  <Slider {...settings} className="w-full">
                    {soldProperties.map(({ property, score, keyMatches }) => (
                      <div
                        key={property._id}
                        className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                        onClick={() => handlePropertyClick(property)}
                      >
                        <img
                          className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                          src={
                            property?.media[0]?.url || "/placeholder-image.jpg"
                          }
                          alt="property"
                        />
                        <div className="text-center mt-4 space-y-3">
                          <p className="font-semibold">
                            {formatCurrency(property.price)} ({score}%)
                          </p>
                          <p className="text-sm">{property.address}</p>
                          <div className="flex items-center justify-center gap-4">
                            <div className="text-sm">
                              <i className="fa fa-bed me-2"></i>
                              {property.bedrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-bath me-2"></i>
                              {property.bathrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-car me-2"></i>
                              {property.carspaces || "N/A"}
                            </div>
                            <div className="text-sm">
                              {property.propertyType}
                            </div>
                          </div>
                          <div className="text-xs text-start space-y-1">
                            <p className="font-semibold">Key Matches</p>
                            <p className="italic">{keyMatches.join(", ")}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}

              {saleProperties && saleProperties.length > 0 && (
                <div class="w-full space-y-8">
                  <h4>ON MARKET MATCHES</h4>
                  <Slider {...settings} className="w-full">
                    {saleProperties.map(({ property, score, keyMatches }) => (
                      <div
                        key={property._id}
                        className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                        onClick={() => handlePropertyClick(property)}
                      >
                        <img
                          className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                          src={
                            property?.media[0]?.url || "/placeholder-image.jpg"
                          }
                          alt="property"
                        />
                        <div className="text-center mt-4 space-y-3">
                          <p className="font-semibold">
                            {formatCurrency(property.price)} ({score}%)
                          </p>
                          <p className="text-sm">{property.address}</p>
                          <div className="flex items-center justify-center gap-4">
                            <div className="text-sm">
                              <i className="fa fa-bed me-2"></i>
                              {property.bedrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-bath me-2"></i>
                              {property.bathrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-car me-2"></i>
                              {property.carspaces || "N/A"}
                            </div>
                            <div className="text-sm">
                              {property.propertyType}
                            </div>
                          </div>
                          <div className="text-xs text-start space-y-1">
                            <p className="font-semibold">Key Matches</p>
                            <p className="italic">{keyMatches.join(", ")}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">STEP 2: LET’S LOOK AT PROCESSES</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              {soldProperties && soldProperties.length > 0 && (
                <div className="space-y-4 w-full">
                  <h4>SCORE MATCH ON MARKET</h4>
                  <GoogleChart dataPoints={dataPoints} />
                </div>
              )}

              <div className="space-y-4 w-full">
                <h4>AREA SALES PROCESS BREAKDOWN</h4>
                <GooglePieChart data={pieChartData} />
              </div>

              {recentAreaSoldProcess && recentAreaSoldProcess.length > 0 && (
                <RecentAreaSoldProcess
                  recentAreaSoldProcess={recentAreaSoldProcess}
                />
              )}
            </div>
          </DisclosurePanel>
        </Disclosure>

       

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">STEP 3: LET’S GET THE MAXIMUM</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              <ProcessChain property={property?.processChain} />

              {logicalPrice && (
                <RecommendedSalesProcess
                  recommendedSaleProcess={recommendedSaleProcess}
                  highEndProperties={highEndProperties}
                  lowEndProperties={lowEndProperties}
                  address={property.address}
                  developmentPotential={property.developmentPotential}
                  logicalPrice={logicalPrice}
                  soldProperties={soldProperties}
                  areaDynamics={areaDynamics}
                  propertyType={property.propertyType}
                  duplexProperties={duplexProperties}
                />
              )}

              <div class="flex items-center justify-center">
                <img
                  src={processExampleImg}
                  alt="Property"
                  class="w-full h-auto max-w-[600px]"
                />
              </div>

              <div class="space-y-4 flex flex-col items-center justify-center">
                <h4>PROCESS MAXIMISES THE OUTCOME</h4>
                <img
                  src={propertyImg2}
                  alt="Property"
                  class="w-full h-auto max-w-[600px]"
                />
              </div>

              <div class="flex gap-4 items-center justify-center">
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Price
                </div>
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Marketing
                </div>
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Process
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">STEP 4: THE MARKETING</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">Photos come here</div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">TOOLS</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-2 space-y-12">
            <div class="w-full space-y-8 flex flex-col items-center justify-center">
              <h4>PROCESS TIMELINE</h4>
              <img
                src={propertyImg5}
                alt="Property"
                class="w-full h-auto max-w-[600px]"
              />
            </div>

            <div class="w-full space-y-8 flex flex-col items-center justify-center">
              <h4>THE GAP</h4>
              <img
                src={propertyImg7}
                alt="Property"
                class="w-full h-auto max-w-[600px]"
              />
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>

      <PropertyDetailsModal
        isOpen={isPropertyClicked}
        onClose={() => setIsPropertyClicked(false)}
        property={selectedProperty}
      />
    </div>
  );
};

const PropertyDetailsModal = ({ isOpen, onClose, property }) => {
  if (!property) return null;

  const {
    address,
    price,
    suburb,
    postcode,
    propertyType,
    bedrooms,
    bathrooms,
    carspaces,
    landArea,
    buildingArea,
    buildType,
    frontage,
    developmentPotential,
    features,
    media,
    pool,
    tennisCourt,
    finishes,
    description,
    headline,
    saleProcess,
    configurationPlan,
    beleefSaleProcess,
  } = property;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Property Details">
      <div className="space-y-6">
        {/* <h4 className="font-semibold text-lg">{headline}</h4>
        <p className="text-gray-700">{description}</p> */}

        <table className="w-full text-sm">
          <tbody>
            <tr className="bg-white">
              <td className="p-2 ">Address</td>
              <td className="p-2">{address}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Price</td>
              <td className="p-2">${price?.toLocaleString()}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Suburb</td>
              <td className="p-2">{suburb}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Postcode</td>
              <td className="p-2">{postcode}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Property Type</td>
              <td className="p-2">{propertyType}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Bedrooms</td>
              <td className="p-2">{bedrooms}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Bathrooms</td>
              <td className="p-2">{bathrooms}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Carspaces</td>
              <td className="p-2">{carspaces}</td>
            </tr>

            {propertyType === "ApartmentUnitFlat" ? (
              <tr className="bg-lightgray">
                <td className="p-2 ">Building Area</td>
                <td className="p-2">{buildingArea} m²</td>
              </tr>
            ) : (
              <tr className="bg-white">
                <td className="p-2 ">Land Area</td>
                <td className="p-2">{landArea} m²</td>
              </tr>
            )}
            <tr className="bg-white">
              <td className="p-2 ">Frontage</td>
              <td className="p-2">{frontage || `N/A`} m²</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Development Potential</td>
              <td className="p-2">{developmentPotential || null}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Build Type</td>
              <td className="p-2">{buildType}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Pool</td>
              <td className="p-2">{pool}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Tennis Court</td>
              <td className="p-2">{tennisCourt}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Finishes</td>
              <td className="p-2">{finishes}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Sale Process</td>
              <td className="p-2">{saleProcess}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Beleef Sale Process</td>
              <td className="p-2">{beleefSaleProcess}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Configuration Plan</td>
              <td className="p-2">{configurationPlan}</td>
            </tr>
          </tbody>
        </table>

        {/* Media Slider */}
        <div className="mt-6">
          <h4>Property Images</h4>
          {media?.length > 0 && (
            <Slider {...settings}>
              {media.map((img, idx) => (
                <div key={idx} className="w-full h-[200px]">
                  <img
                    src={img.url || "/placeholder-image.jpg"}
                    alt={`property-${idx}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default PriceProcess;
