import { useForm } from 'react-hook-form';

export default function UserEmail({ initialEmail, onSubmit }) {
  // Initialize the form with default values
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: initialEmail,
    },
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className="space-y-4 p-2">
      <div className="space-y-2">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <input
          id="email"
          {...register('email',{
            required: true,
            pattern: { value: /^\S+@\S+$/i, message: "Invalid email" }
          })
          }
          placeholder="Enter email"
          className="mt-1 block w-full px-3 py-2 bg-white sm:text-sm form-input border border-mediumgray"
        />
        {
            errors.email && <p className="text-red-600 text-sm">
                Email is required
            </p>
        }
      </div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Send Email
      </button>
    </form>
  );
}
