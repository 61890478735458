import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
  } from '@tanstack/react-query'
    import api from '../utils/axios'
    import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'
import { useContext } from 'react'
import { AuthContext } from 'context/AuthContext'
import { useNavigate } from 'react-router-dom'
export const useUpdatePasswordMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(API_ROUTES.UPDATE_PASSWORD, data),
            onSuccess: () => {
                showToast('success', 'Password updated successfully')
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.ME)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useSetPasswordMutation = () => {
    const queryClient = useQueryClient()
    const router = useNavigate()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(`${API_ROUTES.SET_PASSWORD}/${data?.token}`, data),
            onSuccess: () => {
                showToast('success', 'Password set successfully')
                router('/login')
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.ME)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useSendEmailMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(API_ROUTES.SEND_EMAIL, data),
            onSuccess: () => {
                showToast('success', 'Email sent successfully')
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}