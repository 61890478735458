import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
  } from '@tanstack/react-query'
    import api from '../utils/axios'
    import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'
import { useContext } from 'react'
import { AuthContext } from 'context/AuthContext'
import { useNavigate } from 'react-router-dom'
export const useAddCategoryMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(API_ROUTES.ADD_CATEGORY, data),
            onSuccess: () => {
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CATEGORY)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useGetCategoryQuery = () => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.GET_CATEGORY],
        queryFn: () => api.get(API_ROUTES.GET_CATEGORY),
    })
    return {data: data?.data?.categories ?? [], isPending, isError, error }
}
export const useDeleteCategoryMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (id) => api.delete(`${API_ROUTES.DELETE_CATEGORY}/${id}`),
            onSuccess: () => {
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CATEGORY)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useUpdateCategoryMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_CATEGORY}/${data.id}`, data),
            onSuccess: () => {
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CATEGORY)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useAddItemMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(`${API_ROUTES.ADD_ITEM}/${data?.categoryId}`, data),
            onSuccess: () => {
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CATEGORY)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useDeleteItemMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.delete(`${API_ROUTES.DELETE_ITEM}/${data?.categoryId}/${data?.itemId}`),
            onSuccess: () => {
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CATEGORY)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useUpdateItemMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_ITEM}/${data.categoryId}/${data.itemId}`, data),
            onSuccess: () => {
            },
            onError: (error) => {
                showToast('error', error.response.data.message)
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_CATEGORY)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
