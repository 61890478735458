import { useForm, Controller } from "react-hook-form";
import SelectComponent from "../ui/Select";
import { useEffect, useRef, useState } from "react";
import api from "utils/axios";
import Button from "components/ui/Button";
import { HiXMark } from "react-icons/hi2";
import { FaFacebook, FaInstagram } from "react-icons/fa6";
const SuburbsComponent = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuburbs, setSelectedSuburbs] = useState(value || []);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const inputRef = useRef(null);

  // Fetch all suggestions once when the component mounts
  useEffect(() => {
    api
      .get("/admin/suburb")
      .then((response) => {
        const suburbData = response.data.data;
        setSuggestions(suburbData);
        // {"suburb": "Peakhurst",
        // "postcode": "2210",
        // "state": "NSW",}
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
      });
  }, []);

  // Handle input change and filter suggestions
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.trim()) {
      const filtered = suggestions.filter(
        (item) =>
          item.suburb.toLowerCase().includes(value.toLowerCase()) ||
          item.postcode.includes(value)
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const match = suggestions.find(
        (item) => item.suburb.toLowerCase() === inputValue.toLowerCase()
      );

      if (
        match &&
        !selectedSuburbs.some((suburb) => suburb.suburb === match.suburb)
      ) {
        handleAddSuburb(match);
      }
    }
  };

  const handleAddSuburb = (suburbObj) => {
    if (!selectedSuburbs.some((suburb) => suburb.suburb === suburbObj.suburb)) {
      setSelectedSuburbs([...selectedSuburbs, suburbObj]);
      onChange([...value, suburbObj]);
    }

    setInputValue("");
    setShowSuggestions(false);
  };

  const handleRemoveSuburb = (suburbToRemove) => {
    setSelectedSuburbs(
      selectedSuburbs.filter(
        (suburb) => suburb.suburb !== suburbToRemove.suburb
      )
    );
    onChange(value.filter((suburb) => suburb.suburb !== suburbToRemove.suburb));
  };

  // Click outside to close suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <div>
        <div className="flex gap-2 relative" ref={inputRef}>
          <div className="flex-1 relative">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Enter suburb"
              className="form-input w-full border border-mediumgray"
            />
            {showSuggestions && filteredSuggestions.length > 0 && (
              <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto z-70">
                {filteredSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleAddSuburb(suggestion)}
                  >
                    {suggestion?.suburb} {suggestion?.state}{" "}
                    {suggestion?.postcode}
                  </div>
                ))}
              </div>
            )}
          </div>
          <Button
            onClick={() => handleAddSuburb(inputValue)}
            className="black-button"
            disabled={!inputValue.trim()}
          >
            Add
          </Button>
        </div>
      </div>
      {selectedSuburbs?.length >= 1 && (
        <div className="space-y-2 mt-4">
          <p className="text-sm font-medium text-darkergray">
            Selected Suburbs:
          </p>
          <div className="flex flex-wrap gap-2">
            {selectedSuburbs.map((suburb) => (
              <div
                key={suburb?.postcode}
                className="flex items-center gap-1 bg-lightgray px-2 py-1 rounded"
              >
                <span className="text-sm">
                  {suburb?.suburb} {suburb?.state} {suburb?.postcode}
                </span>
                <button
                  onClick={() => handleRemoveSuburb(suburb)}
                  className="text-darkergray"
                >
                  <HiXMark className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default function UserForm({
  name,
  picture,
  updateUser,
  isLoading,
  setIsEditOpen,
  mobile,
  company,
  title,
  selectedId,
  validLicence,
  suburbs,
  facebookUrl,
  instagramUrl,
}) {
  console.log(instagramUrl, facebookUrl);
  const companyOptions = [
    {
      value: "Ausrealty (Riverwood) Pty Ltd (Licenced user of Ausrealty)",
      label: "Ausrealty (Riverwood) Pty Ltd (Licenced user of Ausrealty)",
    },
    {
      value: "KK Property Services Pty Ltd (Licenced user of Ausrealty)",
      label: "KK Property Services Pty Ltd (Licenced user of Ausrealty)",
    },
    {
      value: "I.M Group Pty Ltd (Licenced user of Ausrealty)",
      label: "I.M Group Pty Ltd (Licenced user of Ausrealty)",
    },
    {
      value: "MRL Property Group Pty Ltd (Licenced user of Ausrealty)",
      label: "MRL Property Group Pty Ltd (Licenced user of Ausrealty)",
    },
    {
      value: "Anodos Enterprises Pty Ltd (Licenced user of Ausrealty)",
      label: "Anodos Enterprises Pty Ltd (Licenced user of Ausrealty)",
    },
    {
      value: "I Sayed Investments Pty Ltd (Licenced user of Ausrealty)",
      label: "I Sayed Investments Pty Ltd (Licenced user of Ausrealty)",
    },
    {
      value: "Suti Investments Pty Ltd (Licenced user of Ausrealty)",
      label: "Suti Investments Pty Ltd (Licenced user of Ausrealty)",
    },
    {
      value: "Hani Property Services Pty Ltd (Licenced user of Ausrealty)",
      label: "Hani Property Services Pty Ltd (Licenced user of Ausrealty)",
    },
  ];

  const titleOptions = [
    { value: "Partner", label: "Partner" },
    { value: "Director", label: "Director" },
    { value: "Associate Agent", label: "Associate Agent" },
    {
      value: "Real Estate Representative",
      label: "Real Estate Representative",
    },
    { value: "Investment Manager", label: "Investment Manager" },
    { value: "Concierge", label: "Concierge" },
  ];
  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/;
  const instagramRegex =
    /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9(\.\?)?]/;
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name,
      mobile,
      company: {
        value: company,
        label: company,
      },
      title: {
        value: title,
        label: title,
      },
      validLicence: {
        value: validLicence,
        label: validLicence ? "Yes" : "No",
      },
      suburbs: suburbs || [],
      facebookUrl,
      instagramUrl,
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    updateUser(
      {
        ...data,
        id: selectedId,
        company: data.company.value,
        title: data.title.value,
        suburbs:
          data.suburbs?.map((suburb) => {
            return {
              suburb: suburb.suburb,
              state: suburb.state,
              postcode: suburb.postcode,
            };
          }) || [],
      },
      {
        onSuccess: () => {
          reset();
          setIsEditOpen(false);
        },
      }
    );
  };

  return (
    <div className="bg-white">
      <div className="bg-darkergray p-4 text-white text-center rounded-lg">
        <div className="w-24 h-24 mx-auto mb-4 rounded-full overflow-hidden border-4 border-[#ccc] shadow-inner">
          <img
            src={picture}
            alt="User Avatar"
            className="w-full h-full object-cover"
          />
        </div>
        <h2 className="text-2xl font-bold">{name}</h2>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="p-4 space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Name
          </label>
          <input
            {...register("name", { required: "Name is required" })}
            type="text"
            placeholder="Enter your name"
            className="w-full px-3 py-2 border border-darkgray rounded-md shadow-sm focus:outline-none focus:ring-2 focus:darkgray focus:border-darkgray"
          />
          {errors.name && (
            <p className="text-red-500 text-sm">{errors.name.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Company
          </label>
          <Controller
            name="company"
            rules={{ required: "Company is required" }}
            control={control}
            render={({ field }) => (
              <SelectComponent
                field={field}
                options={companyOptions}
                label={"Company"}
              />
            )}
          />
          {errors.company && (
            <p className="text-red-500 text-sm">{errors.company.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Valid Licence
          </label>
          <Controller
            name="validLicence"
            control={control}
            rules={{ required: "Valid licence is required" }}
            render={({ field }) => (
              <SelectComponent
                field={field}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                label={"Valid Licence"}
              />
            )}
          />
          {errors.title && (
            <p className="text-red-500 text-sm">{errors.title.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Mobile
          </label>
          <input
            {...register("mobile", { required: "Mobile is required" })}
            type="tel"
            placeholder="Enter your mobile number"
            className="w-full px-3 py-2 border border-darkgray rounded-md shadow-sm focus:outline-none focus:ring-2 focus:darkgray focus:border-darkgray"
          />
          {errors.mobile && (
            <p className="text-red-500 text-sm">{errors.mobile.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {"Title"}
          </label>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <SelectComponent
                field={field}
                options={titleOptions}
                label={"Title"}
              />
            )}
          />
          {errors.title && (
            <p className="text-red-500 text-sm">{errors.title.message}</p>
          )}
        </div>
        <div>
          <label className="form-label ">Facebook</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaFacebook className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              {...register("facebookUrl", {
                pattern: {
                  value: facebookRegex,
                  message: "Invalid facebook url",
                },
                required: false,
              })}
              className={`form-input border border-mediumgray pl-10`}
              placeholder="Enter your facebook profile url"
            />
          </div>

          {errors.facebookUrl && (
            <p className="text-red-500 text-sm">{errors.facebookUrl.message}</p>
          )}
        </div>
        <div>
          <label className="form-label ">Instagram</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaInstagram className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              {...register("instagramUrl", {
                pattern: {
                  value: instagramRegex,
                  message: "Invalid instagram url",
                },
                required: false,
              })}
              className={`form-input border border-mediumgray pl-10`}
              placeholder="Enter your instagram profile url"
            />
          </div>
          {errors.instagramUrl && (
            <p className="text-red-500 text-sm">
              {errors.instagramUrl.message}
            </p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {"Suburbs"}
          </label>
          <Controller
            name="suburbs"
            control={control}
            rules={{ required: "Suburbs is required" }}
            render={({ field }) => (
              <SuburbsComponent
                value={field.value || []}
                onChange={field.onChange}
              />
            )}
          />
          {errors.suburbs && (
            <p className="text-red-500 text-sm">{errors.suburbs.message}</p>
          )}
        </div>

        <div>
          <button
            type="submit"
            className="w-full bg-darkergray text-white py-2 px-4 rounded-md hover:bg-darkgray focus:outline-none focus:ring-2 focus:darkgray focus:ring-offset-2 transition duration-150 ease-in-out"
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
