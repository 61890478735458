import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import api from '../utils/axios';
import { API_ROUTES } from './routes';
import { showToast } from '../components/ui/toast';



/**
 * Get All Triggers Query
 */
export const useGetAllTriggersQuery = () => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.GET_ALL_TRIGGERS],
        queryFn: () => api.get(API_ROUTES.GET_ALL_TRIGGERS),
    });
    return { data: data?.data?.data ?? [], isPending, isError, error };
};

/**
 * Get Trigger By ID Query
 */
export const useGetTriggerByIdQuery = (id) => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.GET_TRIGGER_BY_ID, id],
        queryFn: () => api.get(API_ROUTES.GET_TRIGGER_BY_ID.replace(':id', id)),
        enabled: !!id, // Only run query if ID is provided
    });
    return { data: data?.data?.data ?? null, isPending, isError, error };
};

/**
 * Create Trigger Mutation
 */
export const useAddTriggerMutation = () => {
    const queryClient = useQueryClient();
    const { mutate, isError, error, isLoading, isSuccess, isPending } = useMutation({
        mutationFn: (data) => api.post(API_ROUTES.CREATE_TRIGGER, data),
        onSuccess: () => {
            showToast('success', 'Trigger created successfully');
        },
        onSettled: () => {
            queryClient.invalidateQueries(API_ROUTES.GET_ALL_TRIGGERS);
        },
    });
    return { mutate, isError, error, isLoading, isSuccess, isPending };
};

/**
 * Update Trigger Mutation
 */
export const useUpdateTriggerMutation = () => {
    const queryClient = useQueryClient();
    const { mutate, isError, error, isLoading, isSuccess, isPending } = useMutation({
        mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_TRIGGER.replace(':id', data?.id)}`, data),
        onSuccess: () => {
            showToast('success', 'Trigger updated successfully');
        },
        onSettled: () => {
            queryClient.invalidateQueries(API_ROUTES.GET_ALL_TRIGGERS);
        },
    });
    return { mutate, isError, error, isLoading, isSuccess, isPending };
};

/**
 * Delete Trigger Mutation
 */
export const useDeleteTriggerMutation = () => {
    const queryClient = useQueryClient();
    const { mutate, isError, error, isLoading, isSuccess, isPending } = useMutation({
        mutationFn: (id) => api.delete(`${API_ROUTES.DELETE_TRIGGER.replace(':id', id)}`),
        onSuccess: () => {
            showToast('success', 'Trigger deleted successfully');
        },
        onSettled: () => {
            queryClient.invalidateQueries(API_ROUTES.GET_ALL_TRIGGERS);
        },
    });
    return { mutate, isError, error, isLoading, isSuccess, isPending };
};
