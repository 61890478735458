import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { FaCamera, FaPhone, FaUser } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";
import SelectComponent from "components/ui/Select";
import Button from "components/ui/Button";
import {
  useUpdateContractorMutation,
  useUpdatePictureMutation,
} from "apis/contractor";

export default function ContractorEdit({ user, selectedId, setIsEditOpen }) {
  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      name: user?.name || "",
      email: user?.email || "",
      company: user?.company || "",
      mobile: user?.mobile || "",
      picture: user?.picture || "",
      services:
        user?.services?.map((service) => ({
          value: service,
          label: service,
        })) || [],
      serviceAreas:
        user?.serviceAreas?.map((area) => ({ value: area, label: area })) || [],
      calendarColor: user?.calendarColor || "",
    },
  });
  const { mutate: updatePicture } = useUpdatePictureMutation();
  const { mutate: updateContractor, isPending } = useUpdateContractorMutation();
  const picture = watch("picture");

  const [availability, setAvailability] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const convertFrom24To12Hour = (time24h) => {
    if (!time24h) return "";
    const [hours, minutes] = time24h.split(":");
    const date = new Date(2024, 0, 1, hours, minutes);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const convertTo24Hour = (time12h) => {
    if (!time12h) return "";
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }
    return `${hours.padStart(2, "0")}:${minutes}`;
  };
  useEffect(() => {
    if (user?.availability) {
      const initialAvailability = Object.entries(user.availability).reduce(
        (acc, [day, data]) => {
          acc[day] = {
            available: data.available,
            startTime: convertFrom24To12Hour(data.startTime),
            endTime: convertFrom24To12Hour(data.endTime),
          };
          return acc;
        },
        {}
      );
      setAvailability(initialAvailability);
    }
  }, []);
  const updateAvailability = (day, field, value) => {
    let updatedDay;
    console.log(day, field, value);
    if (field === "available" && value === true) {
      updatedDay = {
        ...availability[day],
        available: true,
        startTime: "6:00 AM",
        endTime: "9:00 PM",
      };
    } else {
      updatedDay = {
        ...availability[day],
        [field]: value,
      };
    }

    setAvailability((prev) => ({
      ...prev,
      [day]: updatedDay,
    }));
    setHasChanges(true);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("picture", file);
      updatePicture({
        id: selectedId,
        data: formData,
      });
      setValue("picture", URL.createObjectURL(file));
    }
  };
  const handleAvailabilityChange = (day) => {
    const available = watch(`availability.${day}.available`);
    setValue(`availability.${day}.available`, !available);
  };
  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      id: selectedId,
      services: data.services.map((service) => service.value),
      serviceAreas: data.serviceAreas.map((area) => area.value),
      availability: Object.entries(availability).reduce((acc, [day, data]) => {
        acc[day] = {
          available: data.available,
          startTime: convertTo24Hour(data.startTime),
          endTime: convertTo24Hour(data.endTime),
        };
        return acc;
      }, {}),
    };
    updateContractor(formattedData);
  };
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = new Date(2024, 0, 1, hour, minute);
        options.push(
          time.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
          })
        );
      }
    }
    return options;
  };

  const timeOptions = generateTimeOptions();
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      {/* Basic Info Section */}
      <div>
        <h2 className="text-xl font-semibold mb-6">Basic Information</h2>
        <div className="flex items-center gap-6 mb-6">
          <div className="relative">
            {picture ? (
              <img
                src={picture}
                alt=""
                className="w-20 h-20 rounded-full object-cover"
              />
            ) : (
              <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center">
                <FaUser className="w-8 h-8 text-gray-500" />
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className="hidden"
              id="fileInput"
              onChange={handleFileChange}
            />
            <label
              htmlFor="fileInput"
              className="absolute bottom-0 right-0 p-1 bg-white rounded-full shadow-md cursor-pointer hover:bg-gray-50"
            >
              <FaCamera className="w-4 h-4" />
            </label>
          </div>
          <div className="flex-1 space-y-4">
            <input
              {...register("name")}
              type="text"
              className="border border-gray-200 form-input"
              placeholder="Name"
            />

<div className="flex items-center gap-4">
  <input
    {...register("calendarColor")} // Hook form binding
    type="color" // HTML5 color picker
    className="w-10 h-10 p-0 m-0 border-none cursor-pointer"
  />
  <span className="text-gray-700">
    {watch("calendarColor", "#000000")} {/* Default to black if no color */}
  </span>
</div>

            <input
              {...register("company")}
              type="text"
              className="w-full px-3 py-2 border border-gray-200 rounded-md"
              placeholder="Company"
            />
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div>
        <h2 className="text-xl font-semibold mb-6">Services</h2>
        <Controller
          name="services"
          control={control}
          render={({ field }) => (
            <SelectComponent
              {...field}
              label="Service"
              options={[
                { value: "Videographer", label: "Videographer" },
                { value: "Floor planner", label: "Floor planner" },
                { value: "Auctioneer", label: "Auctioneer" },
                { value: "Property Stylist", label: "Property Stylist" },
                {
                  value: "Building & Pest Inspector",
                  label: "Building & Pest Inspector",
                },
                { value: "Photographer", label: "Photographer" },
              ]}
              isMulti={true}
            />
          )}
        />
      </div>

      {/* Contact Information */}
      <div>
        <h2 className="text-xl font-semibold mb-6">Contact Information</h2>
        <div className="space-y-4">
          <div className="flex items-center">
            <FiMail className="w-5 h-5 text-gray-400 mr-2" />
            <input
              {...register("email")}
              type="email"
              className="flex-1 px-3 py-2 border border-gray-200 rounded-md"
              placeholder="Email"
            />
          </div>
          <div className="flex items-center">
            <FaPhone className="w-5 h-5 text-gray-400 mr-2" />
            <input
              {...register("mobile")}
              type="tel"
              className="flex-1 px-3 py-2 border border-gray-200 rounded-md"
              placeholder="Mobile"
            />
          </div>
        </div>
      </div>

      {/* Service Areas */}
      <div>
        <h2 className="text-xl font-semibold mb-6">Service Areas</h2>
        <Controller
          name="serviceAreas"
          control={control}
          render={({ field }) => (
            <SelectComponent
              {...field}
              label="Service Area"
              options={[
                { value: "Northern Beaches", label: "Northern Beaches" },
                { value: "North Shore", label: "North Shore" },
                { value: "Eastern Suburbs", label: "Eastern Suburbs" },
                { value: "Inner West", label: "Inner West" },
                { value: "Western Sydney", label: "Western Sydney" },
                {
                  value: "South-Western Sydney",
                  label: "South-Western Sydney",
                },
                { value: "South Sydney", label: "South Sydney" },
                { value: "Central Coast", label: "Central Coast" },
                {
                  value: "Greater Western Sydney",
                  label: "Greater Western Sydney",
                },
                { value: "The Hills District", label: "The Hills District" },
                { value: "Macarthur Region", label: "Macarthur Region" },
                { value: "Hawkesbury Region", label: "Hawkesbury Region" },
                { value: "Illawarra Region", label: "Illawarra Region" },
              ]}
              isMulti={true}
            />
          )}
        />
      </div>

      {/* Availability Section */}
      <div>
        <h2 className="text-xl font-semibold mb-6">Availability</h2>
        {Object.entries(availability).map(
          ([day, { available, startTime, endTime }]) => (
            <div key={day} className="mb-6">
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  checked={available}
                  onChange={() =>
                    updateAvailability(day, "available", !available)
                  }
                  className="mr-2"
                />
                <span className="font-medium">{day}</span>
              </div>
              {available ? (
                <div className="flex space-x-4">
                  <select
                    value={startTime}
                    onChange={(e) =>
                      updateAvailability(day, "startTime", e.target.value)
                    }
                    className="form-select max-w-[100px] min-h-8 border px-2 py-1"
                  >
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  <span>-</span>
                  <select
                    value={endTime}
                    onChange={(e) =>
                      updateAvailability(day, "endTime", e.target.value)
                    }
                    className="form-select max-w-[100px] min-h-8 border px-2 py-1"
                  >
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <span className="text-darkergray">Unavailable</span>
              )}
            </div>
          )
        )}
      </div>

      <Button type="submit" className="black-button flex" loading={isPending}>
        Save Profile
      </Button>
    </form>
  );
}
