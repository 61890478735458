import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
  } from '@tanstack/react-query'
    import api from '../utils/axios'
    import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'
export const useAddListingAppointmentMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(API_ROUTES.ADD_LISTING_APPOINTMENT, data),
            onSuccess: () => {
                // showToast('success', 'Listing Appointment added successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_LISTING_APPOINTMENTS)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useGetListingAppointmentsQuery = () => {
    const queryClient = new QueryClient()
    const { data, isPending, isError, error } = useQuery({
        queryKey:[API_ROUTES.GET_LISTING_APPOINTMENTS], 
        queryFn:() => api.get(API_ROUTES.GET_LISTING_APPOINTMENTS),
})

    return {isPending, isError, listingAppointments:data?.data?.priceProcess, error}
}