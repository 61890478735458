import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../utils/axios";
import { API_ROUTES } from "./routes";
import { showToast } from "../components/ui/toast";

export const useGetAllTemplatesQuery = () => {
  const { data, isPending, isError, error } = useQuery({
    queryKey: [API_ROUTES.GET_ALL_TEMPLATES],
    queryFn: async () => {
      const response = await api.get(API_ROUTES.GET_ALL_TEMPLATES);
      return response.data;
    },
  });

  return {
    templates: data?.data?.templates ?? [], // Separate templates data
    triggers: data?.data?.triggers ?? [],   // Separate triggers data
    isPending,
    isError,
    error,
  };
};


export const useGetTemplateByIdQuery = (id) => {
  const { data, isPending, isError, error } = useQuery({
    queryKey: [API_ROUTES.GET_TEMPLATE_BY_ID, id],
    queryFn: () => api.get(API_ROUTES.GET_TEMPLATE_BY_ID.replace(":id", id)),
    enabled: !!id, // Only run query if ID is provided
  });
  return { data: data?.data?.data ?? null, isPending, isError, error };
};

export const useAddTemplateMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, error, isLoading, isSuccess, isPending } =
    useMutation({
      mutationFn: (data) => api.post(API_ROUTES.CREATE_TEMPLATE, data),
      onSuccess: () => {
        showToast("success", "Template created successfully");
      },
      onSettled: () => {
        queryClient.invalidateQueries(API_ROUTES.GET_ALL_TEMPLATES);
      },
    });
  return { mutate, isError, error, isLoading, isSuccess, isPending };
};

/**
 * Update Template Mutation
 */
export const useUpdateTemplateMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, error, isLoading, isSuccess, isPending } =
    useMutation({
      mutationFn: (data) =>
        api.put(`${API_ROUTES.UPDATE_TEMPLATE.replace(":id", data?.id)}`, data),
      onSuccess: () => {
        showToast("success", "Template updated successfully");
      },
      onSettled: () => {
        queryClient.invalidateQueries(API_ROUTES.GET_ALL_TEMPLATES);
      },
    });
  return { mutate, isError, error, isLoading, isSuccess, isPending };
};

/**
 * Delete Template Mutation
 */
export const useDeleteTemplateMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, error, isLoading, isSuccess, isPending } =
    useMutation({
      mutationFn: (id) =>
        api.delete(`${API_ROUTES.DELETE_TEMPLATE.replace(":id", id)}`),
      onSuccess: () => {
        showToast("success", "Template deleted successfully");
      },
      onSettled: () => {
        queryClient.invalidateQueries(API_ROUTES.GET_ALL_TEMPLATES);
      },
    });
  return { mutate, isError, error, isLoading, isSuccess, isPending };
};
