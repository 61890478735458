import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronRight, Edit, Trash2, Plus } from 'lucide-react';
import Button from 'components/ui/Button';
import { useAddCategoryMutation, useAddItemMutation, useDeleteCategoryMutation, useDeleteItemMutation, useGetCategoryQuery, useUpdateCategoryMutation, useUpdateItemMutation } from 'apis/category';

const initialData = [
  {
    category: "Mailcards & Brochures",
    items: [
      { name: "Melo A6 Mailcard Double Sided Colour - Landscape - 300gsm Uncoated Bright White", price: 388 },
      { name: "Melo 16 Page A4 Booklet - 300gsm Uncoated Bright White Landscape (Short Edge Bound)", price: 358 },
      { name: "Melo 8 Page A5 Brochure - 300gsm Uncoated Bright White Landscape (Short Edge Bound)", price: 106 }
    ]
  },
  // ... (other categories)
];

const MarketingPrice = () => {
  const [data, setData] = useState(initialData);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [editingItem, setEditingItem] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const{
    mutate: submitCategory,
  }=useAddCategoryMutation()
  const{
    data:categoryData,
    isLoading,
    isError,
    error,
  }=useGetCategoryQuery()
  const{
    mutate: deleteCategory,
  }=
  useDeleteCategoryMutation()
  const{
    mutate: updateCategory,
  }=
  useUpdateCategoryMutation()
  const {
    mutate: updateItem,
  }=useUpdateItemMutation()
  const{
    mutate: deleteItem,
  }=useDeleteItemMutation()
  const{
    mutate: submitItem,
  }=useAddItemMutation()
  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  
  useEffect(() => {
    if (categoryData && categoryData?.length > 0) {
      setData(categoryData);
    }
  }, [categoryData]);
  const editItem = (categoryIndex, itemIndex, newName, newPrice,id) => {
    console.log(categoryIndex, itemIndex,newName,newPrice)
    const newData = [...data];
    newData[categoryIndex].items[itemIndex] = { name: newName, price: newPrice,_id:id };
    setData(newData);
  };

  // const deleteItem = (categoryIndex, itemIndex) => {
  //   const newData = [...data];
  //   newData[categoryIndex].items.splice(itemIndex, 1);
  //   setData(newData);
  // };

  const addItem = (categoryIndex) => {
    const newData = [...data];
    newData[categoryIndex].items.push({ name: "New Item", price: 0 });
    setData(newData);
    setEditingItem(`${categoryIndex}-${newData[categoryIndex].items.length - 1}`);
  };

  const addCategory = () => {
    setData([
      ...categoryData,
      {
        category: "New Category",
        items: []
      }
    ]);
    setEditingCategory(data.length);
  };

  const editCategory = (index, newName) => {
    // submitCategory({category:newName})
    const newData = [...data];
    newData[index].category = newName;
    setData(newData);
  };
  return (
    <div className="container mx-auto p-4">
      {data.map((category, categoryIndex) => (
        <div key={categoryIndex} className="mb-4 border border-gray-200 rounded-lg overflow-hidden">
          <div className="bg-gray-100 p-3 flex justify-between items-center">
            <div className="flex items-center flex-grow">
              <button onClick={() => toggleCategory(category.category)}>
                {expandedCategories[category.category] ? 
                  <ChevronDown className="text-black" size={20} /> : 
                  <ChevronRight className="text-black" size={20} />
                }
              </button>
              {editingCategory === categoryIndex ? (
                <input 
                  type="text" 
                  value={category.category}
                  onChange={(e) => editCategory(categoryIndex, e.target.value)}
                  onBlur={(e) => {
                    setEditingCategory(null);
                    if(category?._id){
                      updateCategory({id:category?._id,category:e.target.value})
                    }
                    else{
                      submitCategory({category:e.target.value})
                    }
                    
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setEditingCategory(null);
                    if(category?._id){
                      updateCategory({id:category?._id,category:e.target.value})
                    }
                    else{
                      submitCategory({category:e.target.value})
                    }
                    }
                  }}
                  className="form-input flex-grow border border-mediumgray mx-2"
                  autoFocus
                />
              ) : (
                <span className="font-semibold flex-grow">{category.category}</span>
              )}
            </div>
            <div>
              <button onClick={() => setEditingCategory(
                categoryIndex
              )} className="mr-2">
                <Edit className="text-black" size={16} />
              </button>
              <button onClick={() => deleteCategory(category?._id)}>
                <Trash2 className="text-black" size={16} />
              </button>
            </div>
          </div>
          {expandedCategories[category.category] && (
            <div className="p-3">
              {category.items.map((item, itemIndex) => (
                <div key={itemIndex} className="flex justify-between items-center py-2 border-b border-gray-100">
                  {editingItem === `${categoryIndex}-${itemIndex}` ? (
                    <div className="flex-grow flex gap-4 items-center">
                      <input 
                        type="text" 
                        value={item.name}
                        onChange={(e) => editItem(categoryIndex, itemIndex, e.target.value, item.price,item._id)}
                        onBlur={() => {
                          console.log(item,category?._id)
                          if(item?._id){
                            updateItem({categoryId:category?._id,itemId:item?._id,name:item.name,price:item.price})
                          }
                          else{
                            submitItem({categoryId:category?._id,name:item.name,price:item.price})
                          }
                          setEditingItem(null);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if(item?._id){
                            updateItem({categoryId:category?._id,itemId:item?._id,name:item.name,price:item.price})
                          }
                          else{
                            submitItem({categoryId:category?._id,name:item.name,price:item.price})
                          }
                          setEditingItem(null);
                          }
                        }}
                              className="form-input flex-grow border border-mediumgray"
                        // autoFocus
                      />
                      <input 
                        type="number" 
                        value={item.price}
                        onChange={(e) => editItem(categoryIndex, itemIndex, item.name, parseFloat(e.target.value),item._id)}
                        onBlur={() => {
                          if(item?._id){
                            updateItem({categoryId:category?._id,itemId:item?._id,name:item.name,price:item.price})
                          }
                          else{
                            submitItem({categoryId:category?._id,name:item.name,price:item.price})
                          }
                          setEditingItem(null);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if(item?._id){
                            updateItem({categoryId:category?._id,itemId:item?._id,name:item.name,price:item.price})
                          }
                          else{
                            submitItem({categoryId:category?._id,name:item.name,price:item.price})
                          }
                          setEditingItem(null);
                          }
                        }}
                        className="w-20 mr-2 form-input border border-mediumgray"
                      />
                    </div>
                  ) : (
                    <>
                      <span className="flex-grow mr-2">{item.name}</span>
                      <span className="w-20 text-right mr-2">${item.price.toFixed(2)}</span>
                      <div className="flex">
                        <button 
                          onClick={() => setEditingItem(`${categoryIndex}-${itemIndex}`)}
                          className="mr-2"
                        >
                          <Edit className="text-black" size={16} />
                        </button>
                        <button onClick={() => {
                          deleteItem({categoryId:category?._id,itemId:item?._id})
                        }}>
                          <Trash2 className="text-black" size={16} />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
              <button 
                onClick={() => addItem(categoryIndex)}
                className="mt-2 flex items-center text-black"
              >
                <Plus size={16} className="mr-1" /> Add Item
              </button>
            </div>
          )}
        </div>
      ))}
      <Button 
        onClick={addCategory}
        className="mt-4 black-button flex"
      >
        <Plus size={16} className="mr-1" /> Add Category
      </Button>
    </div>
  );
};

export default MarketingPrice;