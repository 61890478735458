import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { useAddListingAppointmentMutation, useGetListingAppointmentsQuery } from 'apis/listingAppointment';
import Tooltip from 'components/ui/Tooltip';
import React, { useCallback, useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form'
import { HiChevronDown } from 'react-icons/hi2';
import { IoMdRefresh } from "react-icons/io";
export default function PricePoint() {
  const {
    mutate: addListingAppointment,
  }=useAddListingAppointmentMutation()
  const{
    listingAppointments,
  }=useGetListingAppointmentsQuery()
  const initalData=[
    { name: 'OFF MARKET', pricePoint: '1.8-1.9m', enquiries: '82', inspections1: '0', priceAssessment: 'Top end of the range', inspections2: '0', engagement: '', finalise: '',keyMeeting: 'KEY MEETING: LISTING APPOINTMENT', },
    { name: 'WEEK 1', pricePoint: '1.6-1.7m', enquiries: '50', inspections1: '15', priceAssessment: 'Top end of the range', inspections2: '15', engagement: '3', finalise: '', keyMeeting: 'KEY MEETING: LAUNCH TO MARKET MEETING' },
    { name: 'WEEK 2', pricePoint: '1.7-1.8m', enquiries: '26', inspections1: '5', priceAssessment: 'Top end of the range', inspections2: '20', engagement: '2', finalise: '1.9m', keyMeeting: 'KEY MEETING: MID CAMPAIGN MEETING' },
    { name: 'WEEK 3', pricePoint: '', enquiries: '', inspections1: '', priceAssessment: 'Top end of the range', inspections2: '', engagement: '', finalise: '', keyMeeting: 'KEY MEETING: PRE CLOSING DATE' },
    { name: 'WEEK 4', pricePoint: '', enquiries: '', inspections1: '', priceAssessment: 'Top end of the range', inspections2: '', engagement: '', finalise: '', keyMeeting: 'KEY MEETING: POST CLOSING DATE' },
  ]
  const { register, handleSubmit, formState: { errors,isValid }, watch, setError, clearErrors,control,getValues,reset,setValue } = useForm({
    mode:'onChange',
    shouldFocusError: false,
    defaultValues: {
      weeks: initalData?.map((week,index) => ({
        ...week,
        keyMeeting: initalData[index]?.keyMeeting
      })),
    },
  });
  
  useEffect(() => {
    if (listingAppointments) {
      reset({ weeks: listingAppointments?.map((week,index) => ({
        ...week,
        keyMeeting: initalData[index]?.keyMeeting
      })) });
    }
  }, [reset]);
  const weeks = useWatch({ control, name: 'weeks' });


  

  const onSubmit = (data) => {
    console.log('Form submitted:', data);
    addListingAppointment(data)
    // alert('Data saved successfully!');
  };

  const calculatePercentage = (value1, value2,) => {
    const num1 = parseFloat(value1);
    const num2 = parseFloat(value2);
    if (isNaN(num1) || isNaN(num2) || num2 === 0) return 0;
    return Math.round((num1 / num2) * 100);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <form>
      <div className="flex justify-end space-x-2">
        <button
            className="flex items-center py-2 px-4 mb-2 mt-4 black-button"
              onClick={(e) => {
                e.preventDefault();
                reset({ weeks: initalData });
                handleSubmit(onSubmit)();
              }}
          >
          <IoMdRefresh 
          className="text-white w-5 h-5 mr-2"

          />
          Refresh
          </button>
         
        </div>
        <div className="space-y-4">
         
          {
                      // each title has 1 week corresponding to it's index
                      weeks?.map((week, index) => {
                const isRequired = index <= 2
                return (

                  
                  <Disclosure key={index}>
            {({ open }) => (
              <>
              <DisclosureButton className="rounded-lg w-full cursor-pointer bg-lightgray p-4 my-1 flex items-center justify-between text-black font-semibold">
                      <div 
                      className="flex items-center space-x-2"
                      ><p className="">{week.name}</p>
                      <Tooltip
                  className="w-[200px]"
                  text={<i className="fa fa-info-circle text-xs"></i>}
                  tooltip={week.keyMeeting}
                /></div>
                      <HiChevronDown
                        className={`transition-transform duration-300 ${
                          open ? "rotate-180" : ""
                        }`}
                      />
                    </DisclosureButton>
                <DisclosurePanel className="px-4 pt-2 pb-2 text-sm text-gray-500">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Price Point</label>
                      <input
                        type="text"
                        {...register(`weeks.${index}.pricePoint`, {
                          required: isRequired ? 'Price Point is required' : false,
                          
                        })}
                        onBlur={handleSubmit(onSubmit)}
                        className="mt-1 form-input border border-mediumgray"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Enquiries / Inspections</label>
                      <div className="flex items-center space-x-2 mt-1">
                        <input
                          type="text"
                          {...register(`weeks.${index}.enquiries`, {
                            required: isRequired ? 'Enquiries are required' : false,
                            validate: (value) => {
      if(value==='') return true
      if(watch(`weeks.${index}.inspections1`)==='') return true
      if(parseFloat(value) >= parseFloat(watch(`weeks.${index}.inspections1`))){
        clearErrors(`weeks.${index}.inspections1`)

        return true
      }else{
        return 'Inspections must be less than or equal to enquiries'
      }
      
      },
                          })}
                                                  className="mt-1 form-input border border-mediumgray"

                        onBlur={handleSubmit(onSubmit)}

                        />
                        <span>/</span>
                        <input
                          type="text"
                          {...register(`weeks.${index}.inspections1`, {
    required: isRequired ? 'Inspections are required' : false,
    validate: (value) => {
      if(value==='') return true
      if(watch(`weeks.${index}.enquiries`)==='') return true
      if(parseFloat(value) <= parseFloat(watch(`weeks.${index}.enquiries`))){
        clearErrors(`weeks.${index}.enquiries`)
        return true
      }else{
        return 'Inspections must be less than or equal to enquiries'
      }
      
      },
  })}
                                                  className="mt-1 form-input border border-mediumgray"

                        onBlur={handleSubmit(onSubmit)}

                        />
                        
                        <span className="ml-2 text-sm font-semibold bg-darkergray text-white px-2 py-1 rounded-md">
                          {calculatePercentage(week.inspections1, week.enquiries) !== null && 
                           `${calculatePercentage(week.inspections1, week.enquiries)}%`}
                        </span>
                      </div>
                      {
                          
                          (errors?.weeks?.[index]?.inspections1
                          || errors?.weeks?.[index]?.enquiries) && (
                            <span className="text-red-500 text-xs">{errors?.weeks?.[index]?.enquiries?errors.weeks[index].enquiries.message : errors.weeks[index].inspections1.message}</span>
                          )
                        }
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Price Test</label>
                      <input
                        type="text"
                        {...register(`weeks.${index}.priceAssessment`)}
                        className="mt-1 block w-full p-2 border rounded-md"
                        onBlur={handleSubmit(onSubmit)}

                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Inspections / Engagements</label>
                      <div className="flex items-center space-x-2 mt-1">
                        <input
                          type="text"
                          {...register(`weeks.${index}.inspections2`, {
                            required: isRequired ? 'Inspections 2 are required' : false,
                            validate: (value) => {
                             
                             if(value==='') return true
                             if(watch(`weeks.${index}.engagement`)==='') return true
                             if(parseFloat(value) >= parseFloat(watch(`weeks.${index}.engagement`))){
                                clearErrors(`weeks.${index}.engagement`)
                                return true
                             }
                              else{
                                return 'Engagements must be less than or equal to Inspections'
                              }
            
                             },

                          })}
                                                  className="mt-1 form-input border border-mediumgray"

                          
                          onBlur={(e) => {
                            setValue(`weeks.${index}.inspections2`, e.target.value.toString(), { shouldValidate: true });
                            handleSubmit(onSubmit)();
                            setValue(`weeks.${index+1}.inspections2`,((parseFloat(
                              listingAppointments?.[index + 1]?.inspections2 || 0
                            )) + parseFloat(e.target.value)|| 0).toString(), { shouldValidate: true });
                            
            
                                
                              }}
                          
                        />
                        <span>/</span>
                        <input
                          type="text"
                          {...register(`weeks.${index}.engagement`, {
                            required: false,
                            validate: (value) => {
                             
      if(value==='') return true
      if(watch(`weeks.${index}.inspections2`)==='') return true
      if(parseFloat(value) <= parseFloat(watch(`weeks.${index}.inspections2`))){
        clearErrors(`weeks.${index}.inspections2`)
        return true
      }else{
        return 'Engagements must be less than or equal to Inspections'
      }
      },
                          })}
                                                  className="mt-1 form-input border border-mediumgray"

                        onBlur={handleSubmit(onSubmit)}

                        />
                        <span className="ml-2 text-sm font-semibold bg-darkergray text-white px-2 py-1 rounded-md">
                          {calculatePercentage(week.engagement, week.inspections2) !== null && 
                           `${calculatePercentage(week.engagement, week.inspections2)}%`}
                        </span>
                      </div>
                      {
                            
                            (errors?.weeks?.[index]?.engagement || errors?.weeks?.[index]?.inspections2) && (
                              <span className="text-red-500 text-xs">{errors?.weeks?.[index]?.engagement?errors.weeks[index].engagement.message : errors.weeks[index].inspections2.message}</span>
                            )
                            
                      }
                      
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Finalise</label>
                      <input
                        type="text"
                        {...register(`weeks.${index}.finalise`)}
                        className="mt-1 block w-full p-2 border rounded-md"
                        onBlur={handleSubmit(onSubmit)}

                      />
                    </div>
                  </div>
                </DisclosurePanel>
              </>
            )}
          </Disclosure>



                );
              })
                    }
        </div>
        
      </form>
    </div>
  );
}
