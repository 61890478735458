import PageLoader from 'components/ui/PageLoader';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { FaMapPin, FaPhone } from 'react-icons/fa6';
import { FiMail } from 'react-icons/fi';

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export default function ContractorDetails({ user}) {
  return (
    <>
    <div className="flex items-center">
        {user.picture ? (
          <img src={user.picture} alt={user.name} className="w-12 h-12 rounded-full mr-4 object-cover" />
        ) : (
          <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center mr-4">
            <FaUserCircle className="w-6 h-6 text-gray-500" />
          </div>
        )}
        <div>
          <h3 className="text-2xl font-bold text-gray-800">{user.name}</h3>
          <p className="text-gray-600">{user.company}</p>
        </div>
      </div>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <div className="mb-4">
          <h3 className="font-semibold text-gray-800 mb-2">Services</h3>
          <div className="flex flex-wrap gap-2">
            {user.services.map((service, index) => (
              <span key={index} className="px-3 py-1 text-sm font-semibold text-white bg-black rounded-full">
                {service}
              </span>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold text-gray-800 mb-2">Contact</h3>
          <div className="space-y-2">
            <div className="flex items-center text-gray-600">
              <FiMail className="h-5 w-5 mr-2 flex-shrink-0" />
              <a href={`mailto:${user.email}`} className="hover:text-blue-600 break-all">{user.email}</a>
            </div>
            <div className="flex items-center text-gray-600">
              <FaPhone className="h-5 w-5 mr-2 flex-shrink-0" />
              <a href={`tel:${user.mobile}`} className="hover:text-blue-600">{user.mobile}</a>
            </div>
          </div>
        </div>

        <div>
          <h3 className="font-semibold text-black mb-2">Service Areas</h3>
          <div className="flex flex-wrap gap-2">
            {user.serviceAreas.map((area, index) => (
              <span key={index} className="py-1 text-sm bg-daekgray rounded flex items-center">
                <FaMapPin className="h-4 w-4 mr-1 flex-shrink-0" />
                <span>{area}</span>
              </span>
            ))}
          </div>
        </div>
      </div>

      <div>
        <h3 className="font-semibold text-black mb-2">Availability</h3>
        <div className="grid grid-cols-3 gap-2">
          {daysOfWeek.map((day) => (
            <div key={day} className={`p-2 text-white rounded ${user.availability[day].available ? 'bg-darkergray' : 'bg-black'}`}>
              <div className="font-medium">{day}</div>
              {user.availability[day].available ? (
                <div className="text-sm">
                  {user.availability[day].startTime} - {user.availability[day].endTime}
                </div>
              ) : (
                <div className="text-sm">Unavailable</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </>
  );
}