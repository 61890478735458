import {
    useQuery,
    useMutation,
    useQueryClient,
  } from '@tanstack/react-query'
import api from '../utils/axios'
import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'
export const useUpdateEventsDurationMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_EVENTS_DURATION}/${data?.id}`, {
                name: data?.name,
                duration: data?.duration
            }),
            onSuccess: () => {
                showToast('success', 'Event updated successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_EVENTS_DURATION)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useGetEventsDurationQuery = () => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.GET_EVENTS_DURATION],
        queryFn: () => api.get(API_ROUTES.GET_EVENTS_DURATION),
    })
    return {data: data?.data?.data ?? [], isPending, isError, error }
}
export const useAddEventsDurationMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(API_ROUTES.ADD_EVENTS_DURATION, data),
            onSuccess: () => {
                showToast('success', 'Event added successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_EVENTS_DURATION)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useDeleteEventsDurationMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (id) => api.delete(`${API_ROUTES.DELETE_EVENTS_DURATION}/${id}`),
            onSuccess: () => {
                showToast('success', 'Event deleted successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_EVENTS_DURATION)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}