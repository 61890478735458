import React, { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import PageLoader from "../components/ui/PageLoader";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import { FaEdit, FaEye, FaUserCircle } from "react-icons/fa";
import Modal from "../components/ui/Modal";
import { useGetContractorQuery, useUpdateContractorMutation,useDeleteContractorMutation } from "../apis/contractor";
import ContractorDetails from "components/contractor/ContractorDetails";
import ContractorEdit from "components/contractor/ContractorEdit";
import { MdDelete } from "react-icons/md";


const Contractors = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);
  const [pageGroup, setPageGroup] = useState(0); // State for pagination groups
  const navigate = useNavigate();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const{
    mutate:deleteUser,
    isLoading:isDeleteLoading,
  }=
  useDeleteContractorMutation();
  const{
    mutate:updateUser,
    isLoading:isUserLoading,
  }=useUpdateContractorMutation();
  const{
    data,
    isLoading
  }=useGetContractorQuery();
  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "#",
        cell: (info) => info.row.index + 1,
        enableColumnFilter: false, // Disables search for this column
      },
      // {
      //   accessorKey: "picture",
      //   header: "Picture",
      //   cell: ({ row }) => (
      //       row.original.picture ? (<img src={row.original.picture} alt="Contractor" className="w-16 h-16 object-cover rounded-md" />):(
              
      //         <div className="w-16 h-16 bg-primary text-dark rounded-md flex items-center justify-center">
      //         <FaUserCircle fontSize={30} />
      //         </div>
      //       )
      //     ),
      // },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "calendarColor",
        header:"Color",
      },
      {
        accessorKey: "email",
        header: "Email",
        cell:({row})=>( row.original.email ? row.original.email : 'N/A'),
      },
      {
        accessorKey:"company",
        header:"Company",
        cell:({row})=>( row.original.company ? row.original.company : 'N/A'),
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        cell:({row})=>( row.original.mobile ? row.original.mobile : 'N/A'),
      },
      // {
      //   accessorKey: "profileComplete",

      //   header: "Profile Complete",
      //   cell:({row})=>(
      //     row.original.profileComplete ? (
      //     'Yes'
      //     ) : (
      //       'No'
      //     )
      //   )
      // },
      // {
        
      //   accessorKey:'services',
      //   header:"Services",
      //   cell:({row})=>(
      //     row?.original?.services?.length>0 ? (<div>
      //       {row.original.services.join(", ")}
      //     </div>):(
      //       'N/A'
      //     )
      //   )
      // },
      
      // {
      //   accessorKey:'serviceAreas',
      //   header:"Service Areas",
      //   cell:({row})=>(
      //     row?.original?.serviceAreas?.length>0 ? (<div>
      //       {row.original.serviceAreas.join(", ")}
      //     </div>):(
      //       'N/A'
      //     )
      //   )
      // },
      // {
      //   accessorKey:'createdAt',
      //   header:"Created At",
      //   cell:({row})=>(
      //     new Date(row.original.createdAt).toLocaleDateString()
      //   )
      // },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            <button
            onClick={() => {
              
              setSelectedId(row.original._id);
              setIsViewOpen(true);
            }}
            className="view-icon"
          >
            <FaEye 
              fontSize={20}

            />
          </button>
          <button
            onClick={() => {
              setSelectedId(row.original._id);
              setIsEditOpen(true);
            }}
            className="view-icon"
          >
            <FaEdit 
              fontSize={20}
            />
          </button>
          <button
            onClick={() => {
              deleteUser(row.original._id);
            }}
            className="view-icon"
          >
            <MdDelete 
              fontSize={20}
            />
          </button>
          </div>
        ),
      },
    ],
    [navigate]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
  });

  if (isLoading) {
    return <PageLoader />;
  }

  const totalPages = table.getPageCount();
  const pagesPerGroup = 10;
  const currentGroupStart = pageGroup * pagesPerGroup;
  const currentGroupEnd = Math.min(
    currentGroupStart + pagesPerGroup,
    totalPages
  );

  const handleNextGroup = () => {
    if (currentGroupEnd < totalPages) {
      setPageGroup(pageGroup + 1);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
    }
  };

  return (
    <>
    <Modal
      isOpen={isViewOpen}
      onClose={
        () => setIsViewOpen(false)
      }
      title="Contractor Details"
      className="max-w-3xl"
    >
      <ContractorDetails 
        user={
          data?.find((user) => user._id === selectedId)
        }

      />
    </Modal>
    <Modal
      isOpen={isEditOpen}
      onClose={
        () => setIsEditOpen(false)
      }
      className="max-w-2xl"
    ><ContractorEdit
      user={data?.find((user) => user._id === selectedId)}
      
      selectedId={
        selectedId
      }
      setIsEditOpen={setIsEditOpen}
    /></Modal>
    
    <div className="container mx-auto">
      <div className="pagination-info">
        Showing {pagination.pageIndex * pagination.pageSize + 1}-
        {Math.min(
          (pagination.pageIndex + 1) * pagination.pageSize,
          data.length
        )}{" "}
        of {data.length} items.
      </div>
      <div className="table-responsive">
        <table className="styled-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className="text-sm cursor-pointer flex center items-center"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div className="flex flex-col ml-2">
                        <i
                          className={`fa-solid fa-caret-up ${
                            header.column.getIsSorted() === "asc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                        <i
                          className={`fa-solid fa-caret-down ${
                            header.column.getIsSorted() === "desc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                      </div>
                    </div>
                    {header.column.getCanFilter() &&
                    header.column.id !== "index" && (
                        <input
                          type="text"
                          value={header.column.getFilterValue() || ""}
                          onChange={(e) =>
                            header.column.setFilterValue(e.target.value)
                          }
                          placeholder={`Search ${header.column.id}`}
                          className="column-filter"
                        />
                      
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
  {table.getRowModel().rows.map((row) => (
    <tr key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id}>
          {/* Render a colored circle for calendarColor column */}
          {cell.column.id === "calendarColor" ? (
            <div
              className="w-6 h-6 rounded"
              style={{
                backgroundColor: cell.getValue(), // Access the color value
              }}
            ></div>
          ) : (
            // Render other cells normally
            flexRender(cell.column.columnDef.cell, cell.getContext())
          )}
        </td>
      ))}
    </tr>
  ))}
</tbody>



        </table>
      </div>
      <div className="pagination-controls">
        <button onClick={handlePrevGroup} disabled={pageGroup === 0}>
          &laquo;
        </button>
        {Array.from({ length: currentGroupEnd - currentGroupStart }, (_, i) => (
          <button
            key={i}
            onClick={() => table.setPageIndex(currentGroupStart + i)}
            className={
              currentGroupStart + i === pagination.pageIndex ? "active" : ""
            }
          >
            {currentGroupStart + i + 1}
          </button>
        ))}
        <button
          onClick={handleNextGroup}
          disabled={currentGroupEnd >= totalPages}
        >
          &raquo;
        </button>
      </div>
    </div>
    </>
  );
};

export default Contractors;
