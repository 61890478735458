import {
    useQuery,
    useMutation,
    useQueryClient,
  } from '@tanstack/react-query'
    import api from '../utils/axios'
    import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'
export const useUpdatePromptMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_PROMPT}/${data?.id}`, {
                name: data?.name,
                description: data?.description
            }),
            onSuccess: () => {
                showToast('success', 'Prompt updated successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_PROMPT)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useGetPromptQuery = () => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.GET_PROMPT],
        queryFn: () => api.get(API_ROUTES.GET_PROMPT),
    })
    return {data: data?.data?.data ?? [], isPending, isError, error }
}
export const useAddPromptMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(API_ROUTES.ADD_PROMPT, data),
            onSuccess: () => {
                showToast('success', 'Prompt added successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_PROMPT)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useDeletePromptMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (id) => api.delete(`${API_ROUTES.DELETE_PROMPT}/${id}`),
            onSuccess: () => {
                showToast('success', 'Prompt deleted successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_PROMPT)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}