import {
  Briefcase,
  Calendar,
  CheckCircle,
  Mail,
  MapPin,
  Phone,
  XCircle,
} from "lucide-react";
import PageLoader from "../ui/PageLoader";
import { showToast } from "../ui/toast";
import { FaCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import moment from "moment-timezone";
import { FaFacebook, FaInstagram } from "react-icons/fa6";

export default function UserDetails({ user, isLoading, isError, error }) {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    showToast(error.message, "error");
  }
  return (
    <div className="w-full max-w-4xl mx-auto bg-white overflow-hidden">
      <div className="">
        <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-6 mb-8">
          <div className="h-32 w-32 sm:h-40 sm:w-40 rounded-full overflow-hidden bg-gray-200 flex-shrink-0">
            <img
              src={user.picture}
              alt={user.name}
              className="h-full w-full object-cover"
            />
          </div>
          <div className="text-center sm:text-left">
            <h2 className="text-3xl font-bold text-gray-800 mb-1">
              {user.name}
            </h2>
            <p className="text-lg text-gray-600 mb-2">{user.title}</p>
            <p className="text-sm text-gray-500 flex items-center justify-center sm:justify-start mb-2">
              <Briefcase className="w-5 h-5 mr-2" />
              {user.company}
            </p>
            <div className="flex justify-center sm:justify-start space-x-4">
              {user.facebookUrl && (
                <a href={user.facebookUrl} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                  <FaFacebook className="w-5 h-5" />
                </a>
              )}
              {user.instagramUrl && (
                <a href={user.instagramUrl} target="_blank" rel="noopener noreferrer" className=" text-gray-500">
                  <FaInstagram className="w-5 h-5" />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
          <div className="flex items-center">
            <Mail className="w-5 h-5 text-gray-500 mr-3" />
            <div>
              <h3 className="text-sm font-semibold text-gray-500 uppercase">
                Email
              </h3>
              <p className="text-gray-700">{user.email}</p>
            </div>
          </div>
          <div className="flex items-center">
            {user.validLicence ? (
              <FaCheckCircle className="w-5 h-5 text-gray-500 mr-3" />
            ) : (
              <RxCrossCircled className="w-5 h-5 text-gray-500 mr-3" />
            )}
            <div>
              <h3 className="text-sm font-semibold text-gray-500 uppercase">
                Valid Licence
              </h3>
              <p className="text-gray-700">
                {user.validLicence ? "Yes" : "No"}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <Phone className="w-5 h-5 text-gray-500 mr-3" />
            <div>
              <h3 className="text-sm font-semibold text-gray-500 uppercase">
                Mobile
              </h3>
              <p className="text-gray-700">{user.mobile}</p>
            </div>
          </div>
          <div className="flex items-center">
            <Calendar className="w-5 h-5 text-gray-500 mr-3" />
            <div>
              <h3 className="text-sm font-semibold text-gray-500 uppercase">
                Joining Date
              </h3>
              <p className="text-gray-700">
                {moment(user.createdAt)
                  .tz("Australia/Sydney")
                  .format("D MMM YYYY")}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <MapPin className="w-5 h-5 text-gray-500 mr-3" />
            <div>
              <h3 className="text-sm font-semibold text-gray-500 uppercase">
                Company Address
              </h3>
              <p className="text-gray-700">{user.companyAddress}</p>
            </div>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-sm font-semibold text-gray-500 uppercase mb-3">
            Signature
          </h3>
          <div className="bg-gray-100 p-4 rounded-lg">
            <img
              src={user.signature}
              alt="User Signature"
              className="max-w-full h-auto"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-500 uppercase mb-1">
              ABN
            </h3>
            <p className="text-gray-700 bg-gray-100 p-2 rounded">{user.abn}</p>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-500 uppercase mb-1">
              License Number
            </h3>
            <p className="text-gray-700 bg-gray-100 p-2 rounded">
              {user.licenseNumber}
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-semibold text-gray-500 uppercase mb-2">
            Conjunction Agent
          </h3>
          {user.conjunctionAgent ? (
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
              <CheckCircle className="w-5 h-5 mr-2" />
              Yes
            </span>
          ) : (
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
              <XCircle className="w-5 h-5 mr-2" />
              No
            </span>
          )}
        </div>
        <div className="mb-6">
          <h3 className="text-sm font-semibold text-gray-500 uppercase mb-2">
            Alloted Suburbs
          </h3>
          <div className="bg-lightgray p-4 rounded">
            <div className="flex flex-wrap gap-2">
              {user.suburbs.map((suburb, index) => (
                <span
                  key={index}
                  className="bg-black text-white text-xs font-medium px-2.5 py-1 rounded"
                >
                  {suburb?.suburb} {suburb?.state} {suburb?.postcode}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
