export const API_ROUTES = {
  UPDATE_USER: "/admin/user",
  GET_USER: "/admin/user",
  ADD_LISTING_APPOINTMENT: "/admin/listingAppointment",
  GET_LISTING_APPOINTMENTS: "/admin/listingAppointment",
  UPDATE_PROMPT: "/admin/prompt",
  GET_PROMPT: "/admin/prompt",
  ADD_PROMPT: "/admin/prompt",
  DELETE_PROMPT: "/admin/prompt",
  ME: "/admin/auth/me",
  LOGOUT: "/admin/auth/logout",
  ADD_ADMIN: "/admin/auth/register",
  GET_ADMIN: "/admin/auth",
  DELETE_ADMIN: "/admin/auth",
  UPDATE_ADMIN: "/admin/auth",
  UPDATE_PASSWORD: "/admin/auth/change-password",
  SET_PASSWORD: "/admin/auth/setPassword",
  SEND_EMAIL: "/admin/auth/resendEmail",
  ADD_CATEGORY: "/admin/category",
  GET_CATEGORY: "/admin/category",
  DELETE_CATEGORY: "/admin/category",
  UPDATE_CATEGORY: "/admin/category",
  ADD_ITEM: "/admin/category/item",
  DELETE_ITEM: "/admin/category/item",
  UPDATE_ITEM: "/admin/category/item",
  UPDATE_EVENTS_DURATION: "/admin/eventsDuration",
  GET_EVENTS_DURATION: "/admin/eventsDuration",
  DELETE_EVENTS_DURATION: "/admin/eventsDuration",
  ADD_EVENTS_DURATION: "/admin/eventsDuration",
  GET_CONTRACTORS: "/admin/contractor",
  UPDATE_CONTRACTOR: "/admin/contractor",
  DELETE_CONTRACTOR: "/admin/contractor",
  UPDATE_CONTRACTOR_PICTURE: "/admin/contractor/picture",
  GET_SUBURBS: "/admin/suburb",
  UPDATE_SUBURB: "/admin/suburb",
  // Added Trigger Routes
  GET_ALL_TRIGGERS: "/admin/triggers",
  GET_TRIGGER_BY_ID: "/admin/triggers/:id",
  CREATE_TRIGGER: "/admin/triggers",
  UPDATE_TRIGGER: "/admin/triggers/:id",
  DELETE_TRIGGER: "/admin/triggers/:id",
  // Added Template Routes
  GET_ALL_TEMPLATES: "/admin/templates",
  GET_TEMPLATE_BY_ID: "/admin/templates/:id",
  CREATE_TEMPLATE: "/admin/templates",
  UPDATE_TEMPLATE: "/admin/templates/:id",
  DELETE_TEMPLATE: "/admin/templates/:id",
};
