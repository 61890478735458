import React from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
const capitalize = (str) => str && str.charAt(0).toUpperCase() + str.slice(1);

const TemplateEdit = ({ initialData, onSubmit, triggers }) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
      } = useForm({
        defaultValues: {
          name: initialData?.name || "",
          description: initialData?.description || "",
          action: initialData?.action
            ? { value: initialData?.action, label: capitalize(initialData?.action) }
            : "",
          time: initialData?.time || "",
          triggerId: initialData?.triggerId
            ? { value: initialData?.triggerId?._id, label: initialData?.triggerId?.name }
            : "",
          sender: initialData?.sender
            ? { value: initialData?.sender, label: capitalize(initialData?.sender) }
            : "",
          approval: initialData?.approval || false,
          platform: initialData?.platform
            ? { value: initialData?.platform, label: capitalize(initialData?.platform) }
            : "",
          saleProcess: initialData?.saleProcess?.length
            ? initialData?.saleProcess.map((item) => ({ value: item, label: capitalize(item) }))
            : [],
        },
      });

  const submitForm = (data) => {
    const formattedData = {
      name: data?.name,
      description: data?.description,
      action: data?.action?.value,
      time: data?.time,
      triggerId: data?.triggerId?.value,
      sender: data?.sender?.value,
      approval: data?.approval,
      platform: data?.platform?.value,
      saleProcess: data?.saleProcess?.map((item) => item.value),
    };

    onSubmit(formattedData);
  };

  const actionOptions = [
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "daily", label: "Daily" },
    { value: "exact", label: "Exact" },
    { value: "immediate", label: "Immediate" },
  ];

  const senderOptions = [
    { value: "admin", label: "Admin" },
    { value: "agent", label: "Agent" },
  ];

  const platformOptions = [
    { value: "sms", label: "SMS" },
    { value: "email", label: "Email" },
    { value: "whatsapp", label: "WhatsApp" },
  ];

  const saleProcessOptions = [
    { value: "auction", label: "Auction" },
    { value: "privateTreaty", label: "Private Treaty" },
  ];

  // Generate options from templates for the triggerId field
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger._id,
    label: trigger.name,
  }));

  return (
    <form onSubmit={handleSubmit(submitForm)} className="space-y-4 p-2">
      <div className="space-y-2">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          {...register("name", { required: true })}
          placeholder="Enter name"
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
        {errors.name && (
          <p className="text-red-600 text-sm">Name is required</p>
        )}
      </div>

      <div className="space-y-2">
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <textarea
          id="description"
          {...register("description")}
          placeholder="Enter description"
          rows={3}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="action"
          className="block text-sm font-medium text-gray-700"
        >
          Action
        </label>
        <Controller
          name="action"
          control={control}
          rules={{ required: "Action is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={actionOptions}
              placeholder="Select an action"
            />
          )}
        />
        {errors.action && (
          <p className="text-red-600 text-sm">{errors.action.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <label
          htmlFor="time"
          className="block text-sm font-medium text-gray-700"
        >
          Time
        </label>
        <input
          type="text"
          id="time"
          {...register("time")}
          placeholder="Enter time (optional)"
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="triggerId"
          className="block text-sm font-medium text-gray-700"
        >
          Trigger
        </label>
        <Controller
          name="triggerId"
          control={control}
          rules={{ required: "A trigger is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={triggerOptions}
              placeholder="Select a trigger"
            />
          )}
        />
        {errors.triggerId && (
          <p className="text-red-600 text-sm">{errors.triggerId.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <label
          htmlFor="sender"
          className="block text-sm font-medium text-gray-700"
        >
          Sender
        </label>
        <Controller
          name="sender"
          control={control}
          rules={{ required: "Sender is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={senderOptions}
              placeholder="Select sender type"
            />
          )}
        />
        {errors.sender && (
          <p className="text-red-600 text-sm">{errors.sender.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <label
          htmlFor="approval"
          className="block text-sm font-medium text-gray-700"
        >
          Approval
        </label>
        <input
          type="checkbox"
          id="approval"
          {...register("approval")}
          className="mt-1 block"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="platform"
          className="block text-sm font-medium text-gray-700"
        >
          Platform
        </label>
        <Controller
          name="platform"
          control={control}
          rules={{ required: "Platform is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={platformOptions}
              placeholder="Select a platform"
            />
          )}
        />
        {errors.platform && (
          <p className="text-red-600 text-sm">{errors.platform.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <label
          htmlFor="saleProcess"
          className="block text-sm font-medium text-gray-700"
        >
          Sale Process
        </label>
        <Controller
          name="saleProcess"
          control={control}
          rules={{ required: "Sale process is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={saleProcessOptions}
              isMulti
              placeholder="Select one or more sale processes"
            />
          )}
        />
        {errors.saleProcess && (
          <p className="text-red-600 text-sm">{errors.saleProcess.message}</p>
        )}
      </div>

      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </form>
  );
};

export default TemplateEdit;
