// src/App.js
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AuthProvider from "context/AuthContext";
import NotFound from "pages/NotFound";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import ProtectedRoute from "utils/ProtectedRoute";
import Users from "pages/User";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import Navbar from "components/ui/NavBar";
import Property from "pages/Property";

import { useJsApiLoader } from "@react-google-maps/api";
import Chat from "pages/Chat";
import MarketingPrice from "pages/MarketingPrice";
import PricePoint from "pages/PricePoint";
import Prompts from "pages/Prompt";
import PDF from "pages/Pdf";
import Admin from "pages/Admin";
import ChangePassword from "pages/ChangePassword";
import AddPassword from "pages/AddPassword";
import Contractors from "pages/Contractor";
import Suburb from "pages/Suburb";
import Events from "pages/Events";
import Services from "pages/Services";
import AuthRedirect from "utils/AuthRedirect";
import Triggers from "pages/Triggers";
import Templates from "pages/Templates";
import SmsLogs from "pages/SmsLogs";
import Edits from "pages/Edits";
const libraries = ["places"]; // Removed "maps" as it's invalid
const queryClient = new QueryClient();

const App = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY, // Ensure this is correctly set
    libraries, // Corrected libraries
    version: "weekly",
  });

  if (loadError) {
    return <div>Error loading Google Maps. Please try again later.</div>;
  }

  if (!isLoaded) {
    return <div></div>; // You can replace this with a loader/spinner component
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastContainer />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/login"
            element={
              <AuthRedirect>
                <Login />
              </AuthRedirect>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricepoint"
            element={
              <ProtectedRoute>
                <PricePoint />
              </ProtectedRoute>
            }
          />
          <Route
            path="/prompts"
            element={
              <ProtectedRoute>
                <Prompts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services"
            element={
              <ProtectedRoute>
                <Services />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contractors"
            element={
              <ProtectedRoute>
                <Contractors />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />
          <Route path="/password" element={<AddPassword />} />
          <Route
            path="/events"
            element={
              <ProtectedRoute>
                <Events />
              </ProtectedRoute>
            }
          />

          <Route
            path="/edits"
            element={
              <ProtectedRoute>
                <Edits />
              </ProtectedRoute>
            }
          />

          <Route
            path="/templates"
            element={
              <ProtectedRoute>
                <Templates />
              </ProtectedRoute>
            }
          />

          <Route
            path="/sms-logs"
            element={
              <ProtectedRoute>
                <SmsLogs />
              </ProtectedRoute>
            }
          />

          <Route path="/pdf" element={<PDF />} />

          <Route
            path="/property"
            element={
              <ProtectedRoute>
                <Property />
              </ProtectedRoute>
            }
          />
          <Route
            path="/property/:id"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />

          <Route
            path="/marketingPrice"
            element={
              <ProtectedRoute>
                <MarketingPrice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/suburb"
            element={
              <ProtectedRoute>
                <Suburb />
              </ProtectedRoute>
            }
          />

          <Route
            path="/triggers"
            element={
              <ProtectedRoute>
                <Triggers />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </QueryClientProvider>
  );
};

export default App;
