import { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi2";
import { formatCurrency } from "utils/helperFunctions";
import axios from "utils/axios";
import Button from "./Button";
import Calendar from "react-calendar";
import Modal from "./Modal";
import { BiChevronDown } from "react-icons/bi";

const MarketingPrice = ({
  price,
  suburb,
  tab = "authorise-and-schedule",
  onConfirm,
  existingData,
  isEdit = "true",
}) => {
  const [categories, setCategories] = useState([]);
  const [agentContribution, setAgentContribution] = useState(
    existingData?.agentContribution?.amount || "$0"
  );

  const [viewCart, setViewCart] = useState(false);
  const [isAgentContributionChecked, setIsAgentContributionChecked] = useState(
    existingData?.agentContribution?.isChecked || false
  );

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`/marketingPrice/${price}/${suburb}`);
        if (response.data.success) {
          const initializedCategories = response.data.data.categories.map(
            (category) => {
              // Check if the category is "Internet Portals"
              if (category.category === "Internet Portals") {
                return {
                  ...category,
                  items: category.items.map((item, index) => ({
                    ...item,
                    // Set the first two items to isChecked: true by default
                    isChecked: index < 2 ? true : false,
                  })),
                };
              } else {
                return {
                  ...category,
                  items: category.items.map((item) => ({
                    ...item,
                    isChecked: false,
                  })),
                };
              }
            }
          );
          setCategories(initializedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (existingData?.categories) {
      setCategories(existingData?.categories); // Initialize categories with existing data
    } else {
      fetchCategories(); // Fetch categories if no existing data
    }

    if (existingData?.total) {
      setViewCart(true);
    }
  }, [existingData]);

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id ? { ...item, isChecked: !item.isChecked } : item
        ),
      }))
    );
    console.log(categories);
  };

  const handlePriceChange = (id, e) => {
    const value = e.target.value;
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0";
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0);

    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id
            ? { ...item, price: formatCurrency(parsedValue) }
            : item
        ),
      }))
    );
  };
  const handleAgentContributionChange = (e) => {
    const value = e.target.value;
    // Use split to remove the dollar sign and extract numeric part
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0"; // Get numeric part after the dollar sign
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0); // Parse the number and ensure it's non-negative
    setAgentContribution(formatCurrency(parsedValue));
  };

  const handleAgentContributionCheckboxChange = () => {
    setIsAgentContributionChecked(!isAgentContributionChecked);
  };

  // Calculate total price for selected items
  const totalPrice = categories.reduce(
    (total, category) =>
      total +
      category.items.reduce((catTotal, item) => {
        // Safely handle item.price
        const priceString =
          typeof item.price === "string"
            ? item.price
            : item.price?.toString() || "0"; // Convert to string if it's a number
        const numericPrice =
          parseFloat(priceString.replace(/^\$/, "").replace(/,/g, "")) || 0; // Ensure price is numeric
        return item.isChecked ? catTotal + numericPrice : catTotal; // Add to total if checked
      }, 0),
    0
  );
  // Apply agent contribution if applicable
  const finalTotal = Math.max(
    0,
    isAgentContributionChecked
      ? totalPrice -
          parseFloat(agentContribution.replace(/^\$/, "").replace(/,/g, ""))
      : totalPrice // Extract numeric value from formatted agent contribution
  );
  const handleConfirmClick = () => {
    onConfirm({
      categories,
      agentContribution: {
        amount: agentContribution,
        isChecked: isAgentContributionChecked,
      },
      total: finalTotal,
    });
    setViewCart(true); // Set view to cart
  };

  return (
    <div className="w-full mx-auto relative">
      <>
        {/* Shopping Cart Modal */}
        <div className="overflow-x-auto">
          <table className="w-full border text-xs">
            <tbody>
              {categories.flatMap((category) =>
                category.items
                  .filter((item) => item.isChecked)
                  .map((item, index) => {
                    // Safely handle item.price
                    const priceString =
                      typeof item.price === "string"
                        ? item.price
                        : item.price?.toString() || "0"; // Convert to string if it's a number
                    const numericPrice =
                      parseFloat(
                        priceString.replace(/^\$/, "").replace(/,/g, "")
                      ) || 0; // Ensure price is numeric

                    return (
                      <tr key={item._id}>
                        <td className="border px-4 py-2 text-xs">
                          {item.name}
                        </td>
                        <td className="border px-4 py-2 text-xs">
                          {formatCurrency(numericPrice)}{" "}
                          {/* Format numeric price */}
                        </td>
                      </tr>
                    );
                  })
              )}
              {isAgentContributionChecked && agentContribution !== "$0" && (
                <tr>
                  <td className="border px-4 py-2 text-xs">
                    Agent Contribution
                  </td>
                  <td className="border-b px-4 py-2 text-xs">
                    - {agentContribution}
                  </td>
                </tr>
              )}
              <tr>
                <td className="border px-4 py-2 font-bold text-xs">TOTAL</td>
                <td className="px-4 py-2 text-xs">
                  {formatCurrency(finalTotal)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {isEdit && (
          <div className="mt-4 flex flex-col justify-between space-y-4">
            <div className="w-full flex justify-end">
              <Button
                onClick={() => setViewCart(false)}
                className="gray-button"
              >
                Continue Shopping
              </Button>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
export default MarketingPrice;
