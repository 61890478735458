import React, { useState, useRef, useEffect } from "react";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "utils/axios";
import { Autocomplete } from "@react-google-maps/api"; // Removed useJsApiLoader
import SignatureCanvas from "react-signature-canvas";
import {
  fetchSignatureUrl,
  formatCurrency,
  formatDateToAEDT,
} from "utils/helperFunctions";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import "./AuthoriseSchedule.css";
import AuthoriseScheduleEditor from "./AuthoriseScheduleEditorLock";

import PageLoader from "components/ui/PageLoader";
import AuthoriseScheduleLock from "./AuthoriseScheduleLock";
import { showToast } from "components/ui/toast";
import Tooltip from "components/ui/Tooltip";
import MarketingPrice from "components/ui/MarketingPrice";
import CampaignAgent from "./CampaignAgent";
import EventsCalendarComplete from "./EventsCalendarComplete";

const AuthoriseSchedule = ({ property }) => {
  const [pageLoading, setPageLoading] = useState(false);
  const propertyId = property._id;
  const [fetchedData, setFetchedData] = useState(null);
  const [isLock, setIsLock] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageLoading(true);

        const response = await axios.get("/admin/authSchedule", {
          params: { propertyId }, // Pass the userId as a query parameter
        });

        const data = response.data.data; // Adjust according to your API response structure

        if (data) {
          console.log(data);
          setFetchedData(data);
          setIsLock(data.isLock);
          setIsCompleted(data.isCompleted);
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      } finally {
        setPageLoading(false);
      }
    };

    fetchData();
  }, [propertyId]);

  if (pageLoading) {
    return <PageLoader />;
  }

  if (isCompleted) {
    const data = {
      agentContribution:
        fetchedData?.marketing?.agentContribution?.amount.replace("$", ""),
      dataEstimatedSalePrice: fetchedData.endPrice.replace(/[$,]/g, ""),
      listingId: fetchedData.propertyId,
      apiConsumerName: "AusRealty",
      userEmail: fetchedData.agent.email,
      hasPermission: true,
      userName: fetchedData.agent.name,
      dataMode: "advanced",
      officeName: "AusRealty",
      officeOwnershipGroupName: fetchedData.agent.officeName,
      officeOwnershipGroup_Ui: fetchedData.agent.officeId,
      officeUniqueIdentifier: fetchedData.agent.officeId,
      owners: fetchedData.vendors.map((vendor) => ({
        email: vendor.email,
        mobile: vendor.mobile,
        name: vendor.firstName + " " + vendor.lastName,
      })),
      propertyAddress: fetchedData.address + " NSW",
      requestedAmount: fetchedData?.marketing?.total,
      listingAgentName: fetchedData.agent.name,
      listingAgentEmail: fetchedData.agent.email,
      listingAgentMobile: fetchedData.agent.mobile,
    };

    return (
      <>
        <div className="authorise-form w-full h-full max-w-4xl mx-auto px-4 py-8">
          <p className="mb-4 text-darkgray text-center">
            {fetchedData.address}
          </p>

          <div className="flex items-center justify-between w-full">
            <div className="flex-1 text-center">
              <h5 className="inline-block">Status: Completed</h5>
            </div>
          </div>

          <table className="w-full border border-lightgray border-collapse mt-2">
            <thead>
              <tr className="bg-lightgray">
                <th className="py-2 px-4 text-center">Signer</th>
                <th className="py-2 px-4 text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {fetchedData.vendors.map((vendor, index) => (
                <Disclosure key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button as="tr" className="cursor-pointer">
                        <td className="py-2 px-4 border-b text-center">
                          {vendor.firstName} {vendor.lastName}
                        </td>

                        <td className="py-2 px-4 border-b text-center">
                          {vendor.isSigned
                            ? "Signed"
                            : vendor.viewedDate
                            ? "Viewed"
                            : vendor.sentDate
                            ? "Sent"
                            : ""}
                        </td>
                      </Disclosure.Button>
                      <Disclosure.Panel as="tr">
                        <td colSpan="2" className="border-t">
                          <div className="w-full overflow-x-auto">
                            <table className="w-full table-fixed border-collapse">
                              <thead>
                                <tr className="bg-lightgray">
                                  <th className="py-2 px-4 text-center">
                                    Sent Date
                                  </th>
                                  <th className="py-2 px-4 text-center">
                                    Viewed Date
                                  </th>
                                  <th className="py-2 px-4 text-center">
                                    Signed Date
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="py-2 px-4 text-center">
                                    {vendor.sentDate || "N/A"}
                                  </td>
                                  <td className="py-2 px-4 text-center">
                                    {vendor.viewedDate || "N/A"}
                                  </td>
                                  <td className="py-2 px-4 text-center">
                                    {vendor.signedDate || "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </tbody>
          </table>

          <br></br>

          <div className="flex items-center justify-between py-2">
            <p>Solicitor: {fetchedData.solicitor.name}</p>
            <p>{fetchedData.solicitor.sentDate}</p>
          </div>

          <div className="flex items-center justify-between py-2">
            <p>Building & Pest</p>
            <p>{fetchedData.services[0].sentDate}</p>
          </div>

          <div className="flex items-center justify-between py-2">
            <p>Strata</p>
            <p>{fetchedData.services[1].sentDate}</p>
          </div>

          <div className="flex items-center justify-between py-2">
            <p>Styling</p>
            <p>{fetchedData.services[2].sentDate}</p>
          </div>

          <br></br>

          <div className="col-span-12 grid grid-cols-12 gap-3">
            <div className="col-span-6 flex flex-col justify-between">
              <label className="form-label">Prepare Marketing</label>
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={fetchedData.prepareMarketing}
                readOnly
              />
            </div>
            <div className="col-span-6 flex flex-col justify-between">
              <label className="form-label">Access</label>
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={fetchedData.access}
                readOnly
              />
            </div>
          </div>

          {fetchedData?.marketing && (
            <div className="w-full my-4">
              <label className="text-center form-label">SHOPPING CART</label>
              <div className="w-full flex items-center justify-center">
                <MarketingPrice
                  tab="authorise-schedule"
                  isEdit={false}
                  existingData={fetchedData.marketing}
                />
              </div>
            </div>
          )}

          <CampaignAgent data={data} />

          <EventsCalendarComplete propertyId={propertyId} data={data} />
        </div>
      </>
    );
  }

  return (
    <div className="w-full h-full max-w-4xl mx-auto flex flex-col justify-between authorise-form px-4 pb-8">
      <p className="text-center">Authorise Schedule is not completed yet</p>
    </div>
  );
};

export default AuthoriseSchedule;
