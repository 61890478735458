import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ChevronRight, ChevronLeft, MoveRight, X, Lock } from "lucide-react";
import axios from "utils/axios";
import PageLoader from "./PageLoader";
import { PiHouse } from "react-icons/pi";
import { IoIosLink } from "react-icons/io";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi2";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";

const formatDate = (dateString) => {
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "Australia/Sydney", // Use Sydney timezone
  }).format(new Date(dateString));
};

const sampleComments = [
  {
    id: "1",
    name: "Alice Johnson",
    createdAt: "2024-12-25T10:30:00.000Z",
    text: "This is a great post! I really enjoyed the insights you shared.",
  },
  {
    id: "2",
    name: "Bob Smith",
    createdAt: "2024-12-24T14:15:00.000Z",
    text: "I have a question about the second point. Can you elaborate?",
  },
  {
    id: "3",
    name: "Charlie Brown",
    createdAt: "2024-12-23T08:45:00.000Z",
    text: "Thank you for this information! It was very helpful.",
  },
];

const CommentsBox = ({ comments }) => {
  const handleStopPropagation = (e) => {
    e.stopPropagation(); // Prevents the parent onClick from being triggered
  };

  return (
    <div onClick={handleStopPropagation}>
      <Disclosure>
        {({ open }) => (
          <>
            <DisclosureButton className="rounded-lg w-full cursor-pointer bg-lightgray px-3 py-2 flex items-center justify-between">
              <div className="flex items-center space-x-2 text-xs">
                Comments
              </div>
              <HiChevronDown
                className={`text-xs transition-transform duration-300 ${
                  open ? "rotate-180" : ""
                }`}
              />
            </DisclosureButton>
            <DisclosurePanel className="py-2 text-sm text-start">
              <div className="w-full mt-2">
                {comments?.map((comment) => (
                  <div
                    key={comment.id}
                    className="flex items-start gap-2 mb-4 text-xs"
                  >
                    <div className="w-6 h-6 rounded-full bg-darkergray flex items-center justify-center text-white font-semibold">
                      {comment.name[0]}
                    </div>

                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <div>
                          <span className="text-xs font-semibold">
                            {comment.name}
                          </span>
                          <span className="ml-2 text-darkergray text-xs">
                            {comment.createdAt
                              ? formatDistanceToNow(
                                  new Date(comment.createdAt),
                                  {
                                    addSuffix: true,
                                  }
                                )
                              : "N/A"}
                          </span>
                        </div>
                      </div>

                      <p className="text-xs mt-0.5 whitespace-pre-wrap">
                        {comment.text}
                      </p>
                    </div>

                    <img
                      src={comment?.url}
                      alt="property"
                      className="rounded-md w-12 h-auto"
                    />
                  </div>
                ))}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

const ResponsiveKanban = () => {
  const [columns, setColumns] = useState({}); // Initialize as an empty object
  const [activeColumn, setActiveColumn] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  const allStatuses = [
    "overdue",
    "pending",
    "edited",
    "revision",
    "review",
    "approved",
  ]; // Define all possible statuses

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get("/prepareMarketing");
        const { data } = response.data;

        // Transform API data into columns structure
        const transformedColumns = allStatuses.reduce((acc, status) => {
          acc[status] = {
            name: status.charAt(0).toUpperCase() + status.slice(1),
            bgColor: getStatusBgColor(status),
            color: getStatusTextColor(status),
            locked: isStatusLocked(status),
            items: data
              .filter((item) => item.status === status)
              .map((item, index) => ({
                ...item,
                id: `${item.eventId}-${index}`, // Unique ID
                _id: item._id,
                title: item.summary,
                // eventId: item.eventId,
                // address: item.address,
                // uploadedAt: item.uploadedAt,
                // comments: item.comments,
              })),
          };
          return acc;
        }, {});

        setColumns(transformedColumns);
        setActiveColumn(Object.keys(transformedColumns)[0]); // Set the first column as active
      } catch (error) {
        console.error("Error fetching kanban data:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, []);

  // Utility functions for status styles
  const getStatusBgColor = (status) => {
    const colors = {
      overdue: "bg-red-50",
      pending: "bg-amber-50",
      edited: "bg-blue-50",
      revision: "bg-red-50",
      review: "bg-violet-50",
      approved: "bg-green-50",
    };
    return colors[status] || "bg-gray-50";
  };

  const getStatusTextColor = (status) => {
    const colors = {
      overdue: "text-red-600",
      pending: "text-amber-600",
      edited: "text-blue-600",
      revision: "text-red-600",
      review: "text-violet-600",
      approved: "text-green-600",
    };
    return colors[status] || "text-gray-600";
  };

  const isStatusLocked = (status) => {
    const lockedStatuses = ["overdue", "pending"];
    return lockedStatuses.includes(status);
  };

  const handlePrevColumn = () => {
    const columnKeys = Object.keys(columns);
    const currentIndex = columnKeys.indexOf(activeColumn);
    if (currentIndex > 0) {
      setActiveColumn(columnKeys[currentIndex - 1]);
    }
  };

  const handleNextColumn = () => {
    const columnKeys = Object.keys(columns);
    const currentIndex = columnKeys.indexOf(activeColumn);
    if (currentIndex < columnKeys.length - 1) {
      setActiveColumn(columnKeys[currentIndex + 1]);
    }
  };

  const isColumnLocked = (columnId) => columns[columnId]?.locked;

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Check if the card is dropped back into the same position
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return; // No action needed if the item is not moved
    }

    // Allow moving FROM edited TO revision/review/approved
    // Prevent moving TO edited FROM revision/review/approved
    const allowedColumns = ["revision", "review", "approved"];
    if (
      (source.droppableId === "edited" &&
        !allowedColumns.includes(destination.droppableId)) ||
      (allowedColumns.includes(source.droppableId) &&
        destination.droppableId === "edited") ||
      (!allowedColumns.includes(source.droppableId) &&
        source.droppableId !== "edited")
    ) {
      return;
    }

    // Check if source or destination columns are locked
    if (
      isColumnLocked(source.droppableId) ||
      isColumnLocked(destination.droppableId)
    ) {
      return;
    }

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    // Remove item from source and add to destination
    const [movedItem] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, movedItem);

    // Update state
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });

    // Call API to update status
    try {
      await axios.put(`/prepareMarketing/status/${movedItem._id}`, {
        eventId: movedItem.eventId,
        address: movedItem.address,
        propertyId: movedItem.propertyId,
        status: destination.droppableId,
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const moveCard = async (cardId, sourceColId, targetColId) => {
    // Check if source or target columns are locked
    if (isColumnLocked(sourceColId) || isColumnLocked(targetColId)) {
      return;
    }

    const allowedColumns = ["revision", "review", "approved"];

    if (
      (sourceColId === "edited" && !allowedColumns.includes(targetColId)) ||
      (allowedColumns.includes(sourceColId) && targetColId === "edited") ||
      (!allowedColumns.includes(sourceColId) && sourceColId !== "edited")
    ) {
      return;
    }

    // Check if the card is being moved to the same column
    if (sourceColId === targetColId) {
      return;
    }

    const sourceColumn = columns[sourceColId];
    const targetColumn = columns[targetColId];
    const sourceItems = [...sourceColumn.items];
    const cardToMove = sourceItems.find((item) => item.id === cardId);

    if (!cardToMove) return;

    // Remove the card from the source column
    const updatedSourceItems = sourceItems.filter((item) => item.id !== cardId);
    const updatedTargetItems = [...targetColumn.items, cardToMove];

    // Update state
    setColumns({
      ...columns,
      [sourceColId]: {
        ...sourceColumn,
        items: updatedSourceItems,
      },
      [targetColId]: {
        ...targetColumn,
        items: updatedTargetItems,
      },
    });

    // Call API to update status
    try {
      await axios.put(`/prepareMarketing/status/${cardToMove._id}`, {
        eventId: cardToMove.eventId,
        address: cardToMove.address,
        propertyId: cardToMove.propertyId,
        status: targetColId,
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  const columnsList = Object.entries(columns);
  const currentColumnIndex = columnsList.findIndex(
    ([id]) => id === activeColumn
  );
  const currentColumn = columnsList[currentColumnIndex]?.[1];

  return (
    <div className="w-full h-[75vh] relative bg-gray-50">
      {/* Desktop View */}
      <div className="hidden md:block h-full">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="flex gap-4 p-4 h-full overflow-x-auto">
            {Object.entries(columns).map(([columnId, column]) => (
              <div
                key={columnId}
                className="flex-shrink-0 w-80 bg-gray-50 rounded-lg"
              >
                <div className="flex items-center justify-between p-4">
                  <div className="flex items-center space-x-2">
                    <span
                      className={`${column.bgColor} ${column.color} px-3 py-1 rounded-full text-sm font-medium flex items-center gap-2`}
                    >
                      {column.name}
                      {column.locked && <Lock className="w-3 h-3" />}
                    </span>
                    <span className="text-sm text-gray-500">
                      {column.items.length}
                    </span>
                  </div>
                </div>
                <Droppable
                  droppableId={columnId}
                  isDropDisabled={column.locked}
                >
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`p-2 space-y-3 min-h-[calc(100%-5rem)] ${
                        column.locked
                          ? "bg-gray-50"
                          : snapshot.isDraggingOver
                          ? "bg-gray-100"
                          : ""
                      }`}
                    >
                      {column.items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={column.locked}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`p-4 bg-white rounded-lg border ${
                                column.locked
                                  ? "cursor-default opacity-90"
                                  : snapshot.isDragging
                                  ? "shadow-lg ring-2 ring-blue-500"
                                  : "shadow-sm"
                              }`}
                            >
                              <p className="text-sm font-medium mb-2">
                                {item.title} {" - "}{" "}
                                <span className="text-darkergray">
                                  {formatDate(item?.uploadedAt)}
                                </span>
                              </p>
                              <span
                                className={`${column.bgColor} ${column.color} text-xs px-2 py-0.5 rounded`}
                              >
                                {column.name}
                              </span>
                              <div className="my-2 flex gap-2 items-center">
                                <PiHouse className="w-4 h-4 text-darkergray" />
                                <p className="text-xs">{item?.address}</p>
                              </div>

                              <Link
                                to={`https://www.beleef.com.au/marketing/draft/${item?.propertyId}`}
                                className="mb-2 flex gap-2 items-center"
                                target="_blank"
                              >
                                <IoIosLink className="w-4 h-4 text-darkergray" />
                                <p className="text-xs underline">
                                  Marketing Draft Link
                                </p>
                              </Link>

                              {column.name === "Edited" && (
                                <CommentsBox comments={item?.comments} />
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>

      {/* Mobile View */}
      <div className="md:hidden h-full flex flex-col">
        <div className="flex items-center justify-between p-4 bg-white border-b">
          <button
            onClick={handlePrevColumn}
            disabled={currentColumnIndex === 0}
            className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>

          <div className="flex items-center space-x-2">
            <span
              className={`${currentColumn?.bgColor} ${currentColumn?.color} px-3 py-1 rounded-full text-sm font-medium flex items-center gap-2`}
            >
              {currentColumn?.name}
              {currentColumn?.locked && <Lock className="w-3 h-3" />}
            </span>
            <span className="text-sm text-gray-500">
              {currentColumnIndex + 1} of {columnsList.length}
            </span>
          </div>

          <button
            onClick={handleNextColumn}
            disabled={currentColumnIndex === columnsList.length - 1}
            className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-3">
          {currentColumn?.items.map((item) => (
            <div
              key={item.id}
              className={`bg-white p-4 rounded-lg border shadow-sm space-y-2 ${
                currentColumn.locked ? "opacity-90" : ""
              }`}
              onClick={() => {
                if (!currentColumn.locked) {
                  setSelectedCard({ ...item, columnId: activeColumn });
                  setShowMoveDialog(true);
                }
              }}
            >
              <p className="text-sm font-medium mb-2">
                {item.title} {" - "}{" "}
                <span className="text-darkergray">
                  {formatDate(item?.uploadedAt)}
                </span>
              </p>

              <div className="flex items-center space-x-2">
                <span
                  className={`${currentColumn.bgColor} ${currentColumn.color} text-xs px-2 py-0.5 rounded`}
                >
                  {currentColumn.name}
                </span>
                {!currentColumn.locked && (
                  <MoveRight className="w-4 h-4 text-gray-400" />
                )}
                {currentColumn.locked && (
                  <Lock className="w-3 h-3 text-gray-400" />
                )}
              </div>

              <div className="mt-2 flex gap-2 items-center">
                <PiHouse className="w-4 h-4 text-darkergray" />
                <p className="text-xs">{item?.address}</p>
              </div>

              <Link
                to={`https://www.beleef.com.au/marketing/draft/${item?.propertyId}`}
                className="mb-2 flex gap-2 items-center"
                target="_blank"
              >
                <IoIosLink className="w-4 h-4 text-darkergray" />
                <p className="text-xs underline">Marketing Draft Link</p>
              </Link>

              {currentColumn.name === "Edited" && (
                <CommentsBox comments={item?.comments} />
              )}
            </div>
          ))}
        </div>

        {showMoveDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:items-center justify-center p-4">
            <div className="bg-white rounded-t-lg md:rounded-lg w-full max-w-sm">
              <div className="p-4 border-b flex justify-between items-center">
                <h4>Move Card</h4>
                <button
                  onClick={() => setShowMoveDialog(false)}
                  className="p-1 rounded-full hover:bg-gray-100"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
              <div className="p-4 space-y-2">
                {Object.entries(columns).map(
                  ([columnId, column]) =>
                    !column.locked &&
                    columnId !== selectedCard?.columnId && (
                      <button
                        key={columnId}
                        onClick={() => {
                          moveCard(
                            selectedCard.id,
                            selectedCard.columnId,
                            columnId
                          );
                          setShowMoveDialog(false);
                        }}
                        className="w-full flex items-center justify-between p-3 rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex items-center space-x-3">
                          <span
                            className={`${column.bgColor} ${column.color} w-2 h-2 rounded-full`}
                          />
                          <span className="text-sm font-medium">
                            {column.name}
                          </span>
                        </div>
                        <ChevronRight className="w-4 h-4 text-gray-400" />
                      </button>
                    )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResponsiveKanban;
