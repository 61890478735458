
import PdfViewer from 'components/ui/PdfViewer';
import React from 'react';
import pdf from '../assets/pdf/Kangaroo Adcopy.pdf';
const PDF = () => {

    return (<>
        <div>
         
            
            {/* <PdfViewer pdfUrl={pdf} /> */}
        <PdfViewer pdfUrl={'https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/files/FTR32_Agency_agreement.pdf '} />

        </div>
    </>
        
    );
};

export default PDF;
