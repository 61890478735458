import React, { useState } from "react";
import Modal from "components/ui/Modal"; // Assuming Modal component is in the same directory
import Button from "components/ui/Button"; // Assuming Button component is in the same directory
import ViewReport from "components/report/ViewReport";

const OnMarket = ({
  property
}) => {
  const [isFirstImageClicked, setIsFirstImageClicked] = useState(false);
  const [sendWhatsappButtonClick, setSendWhatsappButtonClick] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const sendToWhatsApp = () => {
    setSendWhatsappButtonClick(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
    <Modal
      isOpen={openModal}
      onClose={() => {
        setSelectedUrl(null)
        setOpenModal(false)
        
      }}
      className="max-w-3xl"
    >
    <ViewReport
      pdfUrl={selectedUrl}
      setIsOpen={setOpenModal}
      openModal={openModal}

    />
    </Modal>
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {property?.fiveStepProcess?.map((section) => (
          <div key={section.id} className="border rounded-lg p-6 shadow-sm">
            <h2 className="text-2xl font-semibold mb-4">{section.name}</h2>
            {
              section?.url && (
                <div className="flex flex-col sm:flex-row gap-4">
              <Button className="black-button py-2 px-4"
              onClick={() => window.open(section?.url, "_blank")}
              >
                Download Report
              </Button>
              <Button className="black-button py-2 px-4"
              onClick={() => {
                setSelectedUrl(section?.url)
                setOpenModal(true)
              }}
              >
                View Report
              </Button>
            </div>
              )
            }
          </div>
        ))}
      </div>
    </div></>
    
  );
};

export default OnMarket;
