import SelectComponent from 'components/ui/Select';
import { Controller, useForm } from 'react-hook-form';

export default function UserAdd({ initialRole,initialName, initialEmail, onSubmit }) {
  // Initialize the form with default values
  console.log(initialRole,initialName, initialEmail)
  const { control,register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: initialName,
      email: initialEmail,
      role:{
        value: initialRole,
        label: initialRole==='superAdmin' ? 'Super Admin' : initialRole==='beleefAdmin' ? 'Beleef Admin' : 'Melo Admin'
      }
    },
  });

  const submitForm = (data) => {
    console.log('Form submitted:', data);
    onSubmit(
      {
        name: data.name,
        email: data.email,
        role: data.role.value,
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className="space-y-4 p-2">
      <div className="space-y-2">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Name
        </label>
        <input
          type="text"
          id="name"
          {...register('name', { required: true })}
          placeholder="Enter name"
          className="mt-1 block w-full px-3 py-2 bg-white sm:text-sm form-input border border-mediumgray"
        />
        {errors.name && <p className="text-red-600 text-sm">Name is required</p>}
      </div>
      <div className="space-y-2">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <input
          id="email"
          {...register('email',{
            required: true,
            pattern: { value: /^\S+@\S+$/i, message: "Invalid email" }
          })
          }
          placeholder="Enter email"
          className="mt-1 block w-full px-3 py-2 bg-white sm:text-sm form-input border border-mediumgray"
        />
        {
            errors.email && <p className="text-red-600 text-sm">
                Email is required
            </p>
        }
      </div>
      <div className="space-y-2">
      <label htmlFor="role" className="block text-sm font-medium text-gray-700">
        Role
      </label>
      <Controller
        name="role"
        control={control}
        render={({ field }) => (
          <SelectComponent
            field={field}
            options={[
              { value: 'superAdmin', label: 'Super Admin' },
              { value: 'beleefAdmin', label: 'Beleef Admin' },
              { value: 'meloAdmin', label: 'Melo Admin' },
            ]}
            placeholder="Select role"
          />
        )}
      />
    </div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </form>
  );
}
