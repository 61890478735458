import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "utils/axios";
import Modal from "components/ui/Modal";
import moment from "moment";

const BookAppraisal = ({ property }) => {
  const [bookAppraisalData, setBookAppraisalData] = useState([]);
  const [isVendorDetails, setIsVendorDetails] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  // Fetch bookings data when the component mounts
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`admin/booking`, {
          params: {
            address: property.address,
            userId: property.userId,
          },
        });
        if (response.data.success) {
          setBookAppraisalData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, []);

  const showVendorDetails = (booking) => {
    setSelectedBooking(booking);
    setIsVendorDetails(true);
  };

  if (!bookAppraisalData.length) {
    return (
      <div className="flex items-center justify-center">No bookings found</div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {bookAppraisalData
            .filter((booking) => booking.status !== "Cancelled")
            .map((booking) => {
              const formattedDate = moment(booking.startTime).format(
                "DD/MM/YYYY"
              );
              const formattedStartTime = moment(booking.startTime).format(
                "h:mm a"
              );
              const formattedEndTime = moment(booking.endTime).format("h:mm a");

              return (
                <div
                  key={booking.googleEventId}
                  className="bg-white rounded-md border border-mediumgray p-4 flex flex-col gap-4 items-center"
                >
                  <h3 className="text-sm font-semibold text-center">
                    {booking.address}
                  </h3>
                  <p className="text-center m-0">
                    {formattedDate}
                    <br />
                    {`${formattedStartTime} - ${formattedEndTime}`}
                  </p>
                  <p
                    className="underline cursor-pointer"
                    onClick={() => showVendorDetails(booking)}
                  >
                    Vendor Details
                  </p>

                  <p className="m-0">
                    <span
                      className={`px-2 py-0.5 rounded-full ${
                        booking.status === "Completed"
                          ? "bg-black text-white"
                          : "bg-mediumgray text-black"
                      }`}
                      style={{ fontSize: "11px" }}
                    >
                      {booking.status}
                    </span>
                  </p>
                </div>
              );
            })}
        </div>
      </div>

      <Modal
        isOpen={isVendorDetails}
        onClose={() => setIsVendorDetails(false)}
        title="Vendor Details"
      >
        <div className="flex flex-col">
          {selectedBooking && selectedBooking.vendors && (
            <>
              {selectedBooking.vendors.map((vendor, index) => (
                <div key={index} className="mb-4">
                  <p>First Name: {vendor.firstName}</p>
                  <p>Last Name: {vendor.lastName}</p>
                  <p>Email: {vendor.email}</p>
                  <p>Mobile: {vendor.mobile}</p>
                </div>
              ))}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default BookAppraisal;
