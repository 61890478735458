import { useForm } from 'react-hook-form';

export default function EventsDurationEdit({ initialName, initialDuration, onSubmit }) {
  // Initialize the form with default values
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: initialName,
      duration: initialDuration,
    },
  });

  const submitForm = (data) => {
    console.log('Form submitted:', data);
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className="space-y-4 p-2">
      <div className="space-y-2">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Name
        </label>
        <input
          type="text"
          id="name"
          {...register('name', { required: true })}
          placeholder="Enter name"
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
        {errors.name && <p className="text-red-600 text-sm">Name is required</p>}
      </div>
      <div className="space-y-2">
        <label htmlFor="duration" className="block text-sm font-medium text-gray-700">
          Duration
        </label>
        <textarea
          id="duration"
          {...register('duration')}
          placeholder="Enter duration"
          rows={4}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        />
      </div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </form>
  );
}
