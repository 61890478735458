import React, { useState, useEffect } from "react";
import axios from "utils/axios";
import { useNavigate } from "react-router-dom";
import Error from "components/ui/Error";
import PageLoader from "components/ui/PageLoader";

const Property = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]); // Empty by default
  const [allUsers, setAllUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userProperties, setUserProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [address, setAddress] = useState("");
  const [agreements, setAgreements] = useState([]);

  // Fetch users from /user endpoint on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/admin/user"); // Adjust the endpoint as necessary
        setAllUsers(response.data.data); // Assuming response.data.data is the array of user objects
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const fetchAgreements = async () => {
      try {
        const response = await axios.get("/admin/authSchedule/threeDays"); // Adjust the
        setAgreements(response.data.data); // Assuming response.data.data is the array of user
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
    fetchAgreements();
  }, []);

  // Fetch properties when a user is selected
  const fetchUserProperties = async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get("/admin/userProperty", {
        params: { userId: userId }, // Pass the userId as a query parameter
      });
      setUserProperties(response.data.data); // Set the data received from the API
      setFilteredProperties(response.data.data); // Initially, show all properties
    } catch (error) {
      console.error("Error fetching user properties:", error);
    } finally {
      setLoading(false);
    }
  };

  // Filter properties based on the address entered in the second input box
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    const filtered = userProperties.filter((property) =>
      property.address.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredProperties(filtered);
  };

  // Show suggestions only when input is typed
  const handleUserInputChange = (e) => {
    const value = e.target.value.toLowerCase().trim();
    setInputValue(value);
    if (value === "") {
      setSuggestions([]); // Clear suggestions if the input is empty
    } else {
      setSuggestions(
        allUsers.filter((user) => user.name.toLowerCase().includes(value))
      );
    }
  };

  return (
    <div className="container px-8">
      {/* Input for user name suggestions */}
      <input
        type="text"
        value={inputValue}
        onChange={handleUserInputChange}
        placeholder="Enter Agent"
        className="form-input border border-mediumgray"
      />

      {suggestions.length > 0 && (
        <ul className="form-input border border-mediumgray bg-white w-full p-0 py-1 m-0 mt-2 list-none max-h-[120px] overflow-y-auto">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion._id}
              onClick={() => {
                fetchUserProperties(suggestion._id);
                setSuggestions([]);
                setInputValue(suggestion.name);
              }}
              className="px-2 py-1 cursor-pointer hover:bg-lightgray m-0"
            >
              {suggestion.name} {/* Display user name */}
            </li>
          ))}
        </ul>
      )}

      {/* Input for address filtering */}
      <input
        type="text"
        value={address}
        onChange={handleAddressChange}
        placeholder="Enter Address"
        className="mt-4 form-input border border-mediumgray"
      />

      {loading && (
        <p className="text-center my-2">
          {" "}
          <i className="fa-solid fa-spinner animate-spin mr-2"></i> Loading ...
        </p>
      )}
      {/* Display properties */}
      {filteredProperties.length > 0 ? (
        <div className="mt-4">
          {filteredProperties.map((userProperty) => (
            <table
              key={userProperty._id}
              className="table-auto w-full mb-4 border-b"
            >
              <tbody>
                <tr>
                  <td className="p-2 text-darkergray uppercase text-sm">
                    <a
                      href={`/property/${userProperty._id}`}
                      className="text-darkergray hover:underline"
                    >
                      {userProperty.address}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      ) : (
        <p className="text-darkgray text-center mt-4">
          No properties found for this user.
        </p>
      )}

<h4>Latest Agreements</h4>
      {/* Display properties */}
      {/* {agreements.length > 0 ? (
        <div className="mt-4">
          <table className="table-auto w-full mb-4 border-b">
            <tbody>
              {agreements.map((agreement) => (
                <tr
                  key={agreement._id}
                  onClick={() =>
                    (window.location.href = `/property/${agreement.propertyId}?tab=authorise-and-schedule`)
                  }
                  className="cursor-pointer border-b"
                >
                  <td className="p-2 text-darkergray uppercase text-sm text-start w-3/4">
                    {agreement.address}
                  </td>
                  <td className="p-2 text-darkergray uppercase text-sm text-start w-1/4">
                    {agreement.userId.name}
                  </td>
                  <td className="p-2 text-darkergray uppercase text-sm text-start w-1/4">
                    {agreement.marketing.categories}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-darkgray text-center mt-4">
          No agreements found in last three days.
        </p>
      )} */}
      {agreements.length > 0 ? (
  <div className="mt-4">
    <table className="table-auto w-full mb-4 border-b">
      <tbody>
        {agreements.map((agreement) => {
          // Find the "Internet Portals" category in marketing.categories
          const internetPortalsCategory = agreement.marketing.categories.find(
            (category) => category.category === "Internet Portals"
          );

          return (
            <React.Fragment key={agreement._id}>
              <tr
                onClick={() =>
                  (window.location.href = `/property/${agreement.propertyId}?tab=authorise-and-schedule`)
                }
                className="cursor-pointer border-b"
              >
                <td className="p-2 text-darkergray uppercase text-sm text-start w-3/4">
                  {agreement.address}
                </td>
                <td className="p-2 text-darkergray uppercase text-sm text-start w-1/4">
                  {agreement.userId.name}
                </td>
              </tr>

              {internetPortalsCategory ? (
                <tr>
                  <td colSpan="2" className="p-2 text-sm">
                    <table className="table-auto w-full mb-2">
                      <thead>
                        <tr>
                          <th className="text-left p-2 text-xs text-gray-500">
                            Item Name
                          </th>
                          <th className="text-left p-2 text-xs text-gray-500">
                            Price
                          </th>
                          <th className="text-left p-2 text-xs text-gray-500">
                            Checked
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {internetPortalsCategory.items.map((item) => (
                          <tr key={item._id} className="border-t">
                            <td className="p-2 text-sm text-gray-700">
                              {item.name}
                            </td>
                            <td className="p-2 text-sm text-gray-700">
                              {typeof item.price === 'number' ? `$${item.price}` : item.price}
                            </td>
                            <td className="p-2 text-sm text-gray-700">
                              {item.isChecked ? "Yes" : "No"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="2" className="p-2 text-sm text-gray-500">
                    No "Internet Portals" items available.
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  </div>
) : (
  <p className="text-darkgray text-center mt-4">
    No agreements found in the last three days.
  </p>
)}

    </div>
  );
};

export default Property;
