import React from "react";

const TemplateView = ({ data }) => {
  const formatArray = (array) => {
    if (!array || array.length === 0) return "None";
    return array.join(", ");
  };

  return (
    <div className="space-y-4 p-2">
      <div className="space-y-2">
        <h4>Name</h4>
        <p>{data?.name || "Not specified"}</p>
      </div>

      <div className="space-y-2">
        <h4>Description</h4>
        <p>{data?.description || "Not specified"}</p>
      </div>

      <div className="space-y-2">
        <h4>Action</h4>
        <p>{data?.action || "Not specified"}</p>
      </div>

      <div className="space-y-2">
        <h4>Time</h4>
        <p>{data?.time || "Not specified"}</p>
      </div>

      <div className="space-y-2">
        <h4>Trigger</h4>
        <p>{data?.triggerId?.name || "Not linked to any trigger"}</p>
      </div>

      <div className="space-y-2">
        <h4>Sender</h4>
        <p>{data?.sender || "Not specified"}</p>
      </div>

      <div className="space-y-2">
        <h4>Approval</h4>
        <p>{data?.approval ? "Yes" : "No"}</p>
      </div>

      <div className="space-y-2">
        <h4>Platform</h4>
        <p>{data?.platform || "Not specified"}</p>
      </div>

      <div className="space-y-2">
        <h4>Sale Process</h4>
        <p>{formatArray(data?.saleProcess)}</p>
      </div>
    </div>
  );
};

export default TemplateView;
