import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
  } from '@tanstack/react-query'
    import api from '../utils/axios'
    import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'
import { useContext } from 'react'
import { AuthContext } from 'context/AuthContext'
import { useNavigate } from 'react-router-dom'
export const useUpdateUserMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(API_ROUTES.UPDATE_USER, data),
            onSuccess: () => {
                showToast('success', 'User updated successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_USER)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useGetUserByIdQuery = (id) => {
    const queryClient = new QueryClient()
    const { data, isPending, isError, error } = useQuery({
        queryKey:[API_ROUTES.GET_USER, id], 
        queryFn:() => api.get(`${API_ROUTES.GET_USER}/${id}`),
        enabled: !!id,
})

    return {isPending, isError, user:data?.data?.data, error}
}
export const useGetUserQuery = () => {
    const { data, isError, error,isLoading } = useQuery({
        queryKey: [API_ROUTES.GET_USER],
        queryFn: () => api.get(API_ROUTES.GET_USER),
    })
    return {data: data?.data?.data ?? [], isLoading, isError, error }
}
export const useMeQuery = () => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.ME],
        queryFn: () => api.get(API_ROUTES.ME),
    })
    return {data: data?.data?.data ?? [], isPending, isError, error }
}
export const useLogoutMutation = () => {
    const { logout } = useContext(AuthContext);
    const router=useNavigate()
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: () => api.get(API_ROUTES.LOGOUT),
            onSuccess: () => {
                logout()
                router('/login')
                // window.location.href = '/login'
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_USER)
            
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useAddAdminMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.post(API_ROUTES.ADD_ADMIN, data),
            onSuccess: () => {
                showToast('success', 'Admin added successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_USER)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useGetAdminQuery = () => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.GET_ADMIN],
        queryFn: () => api.get(API_ROUTES.GET_ADMIN),
    })
    return {data: data?.data?.admins ?? [], isPending, isError, error }
}
export const useDeleteAdminMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (id) => api.delete(`${API_ROUTES.DELETE_ADMIN}/${id}`),
            onSuccess: () => {
                showToast('success', 'Admin deleted successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_USER)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}
export const useUpdateAdminMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_ADMIN}/${data?.id}`, data),
            onSuccess: () => {
                showToast('success', 'Admin updated successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_USER)
            },

        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}