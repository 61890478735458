import {
    useQuery,
    useMutation,
    useQueryClient,
  } from '@tanstack/react-query'
    import api from '../utils/axios'
    import { API_ROUTES } from './routes'
import { showToast } from '../components/ui/toast'

export const useGetSuburbQuery = () => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [API_ROUTES.GET_SUBURBS],
        queryFn: () => api.get(API_ROUTES.GET_SUBURBS),
    })
    return {data: data?.data?.data ?? [], isPending, isError, error }
}
export const useUpdateSuburbMutation = () => {
    const queryClient = useQueryClient()
    const {
        mutate,
        isError,
        error,
        isLoading,
        isSuccess,
        isPending
    } = useMutation(
        {
            mutationFn: (data) => api.put(`${API_ROUTES.UPDATE_SUBURB}/${data?._id}`, {
                ...data
            }),
            onSuccess: () => {
                showToast('success', 'Suburb updated successfully')
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ROUTES.GET_SUBURBS)
            },
        }
    )
    return { mutate, isError, error, isLoading, isSuccess, isPending }
}