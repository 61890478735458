import React, { useEffect, useState, useRef } from "react";

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar"; // For react-big-calendar
import "react-big-calendar/lib/css/react-big-calendar.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import axios from "utils/axios";
import "./Events.css"; // Assuming custom CSS for styling
import Modal from "components/ui/Modal";
import Avatar from "assets/images/profile (1).png";
import Button from "components/ui/Button";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import { BiMap, BiHomeAlt } from "react-icons/bi";
import { FaEdit, FaTrash, FaRegUserCircle } from "react-icons/fa";
import {
  format,
  parseISO,
  parse,
  addMinutes,
  differenceInMinutes,
  isValid,
} from "date-fns";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { showToast } from "components/ui/toast";
import { isEqual, property } from "lodash"; // For deep comparison of objects

const formatStartDate = (date, timeString) => {
  if (!date || !timeString) return null;
  const [hours, minutes] = timeString.value.split(":").map(Number);
  const startDate = new Date(date);
  startDate.setHours(hours, minutes, 0, 0);
  return startDate;
};

const accessOptions = [
  { value: "Vacant", label: "Vacant" },
  { value: "Owner occupied", label: "Owner occupied" },
  { value: "Tenanted", label: "Tenanted" },
];

const services = {
  "Melo - Photography 10 Images": 1.5,
  "Melo - Photography 20 Images": 3,
  "Melo - Photography 7 Images": 1,
  "Melo - Photography 5 Images": 1,
  "Melo - 2 Dusk Photography": 0.5,
  "Melo - 2 Drone Shots": 0.5,
  "Melo - Property Video": 2.5,
  "Melo - Storytelling Videos": 3,
  "Melo - Large Floor Plan": 2,
  "Melo - Medium Floor Plan": 1,
  "Melo - Small Floor Plan": 0.75,
};

// Convert services object to options for react-select
const serviceOptions = Object.entries(services).map(([key, value]) => ({
  value: key,
  label: key,
  duration: value * 60, // Convert hours to minutes
}));

const localizer = momentLocalizer(moment);

const formatDate = (dateString) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatTime = (dateString) => {
  const options = { hour: "2-digit", minute: "2-digit" };
  return new Date(dateString).toLocaleTimeString(undefined, options);
};

const tileDisabled = ({ date, view }) => {
  if (view === "month") {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to midnight
    date.setHours(0, 0, 0, 0); // Normalize to midnight
    return date < today; // Disable dates before today
  }
  return false; // Do not disable other views
};

const SelectWithImage = ({
  options,
  onSelect,
  placeholder = "Select an option...",
  selectedValue, // Add this prop to pre-select an option
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option._id === selectedValue) || null // Initialize with the selected value
  );
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option); // Callback to parent
    setIsOpen(false);
  };

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      {/* Dropdown Button */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between border border-mediumgray rounded px-4 py-1.5 text-sm focus:outline-none"
      >
        {selectedOption ? (
          <div className="flex items-center gap-3">
            <img
              src={selectedOption.picture || Avatar}
              alt={selectedOption.name}
              className="w-8 h-8 rounded-full object-cover"
            />
            <div className="flex flex-col items-start">
              <span className="font-medium">{selectedOption.name}</span>
            </div>
          </div>
        ) : (
          <span className="text-darkergray">{placeholder}</span>
        )}
        <i className="fas fa-chevron-down text-sm"></i>
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute mt-1 w-full bg-white border border-mediumgray rounded z-10">
          <ul className="py-0 max-h-60 overflow-auto m-0">
            {options.map((option) => (
              <li key={option._id}>
                <button
                  onClick={() => handleSelect(option)}
                  className="w-full px-4 py-2 flex items-center gap-3 hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
                >
                  <img
                    src={option.picture || "default-avatar.png"}
                    alt={option.name}
                    className="w-8 h-8 rounded-full object-cover"
                  />
                  <div className="flex flex-col items-start">
                    <span className="font-medium">{option.name}</span>
                  </div>
                  {selectedOption?._id === option._id && (
                    <i className="fas fa-check-circle ml-auto text-black"></i>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const AddEvent = ({
  agents,
  contractors,
  properties,
  setAddEvent,
  refreshEvents,
}) => {
  const { register, handleSubmit, control, setValue, watch } = useForm();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [endTime, setEndTime] = useState(null);
  const [contractorId, setContractorId] = useState(null); // Track contractor ID
  const [agentId, setAgentId] = useState(null); // Track agent ID
  const [loading, setLoading] = useState(false);

  // Watch selected services and calculate end time
  const selectedServices = watch("services");
  const selectedTime = watch("time");

  useEffect(() => {
    if (selectedTime?.value && selectedServices?.length) {
      // Calculate total duration in minutes
      const totalDuration = selectedServices.reduce(
        (sum, service) => sum + service.duration,
        0
      );

      // Create a new date object for the start time
      const [hours, minutes] = selectedTime.value.split(":").map(Number);
      const startDateTime = new Date(selectedDate);
      startDateTime.setHours(hours, minutes, 0, 0);

      // Calculate end time by adding the total duration
      const calculatedEndTime = addMinutes(startDateTime, totalDuration);
      setEndTime(calculatedEndTime);
    } else {
      setEndTime(null);
    }
  }, [selectedServices, selectedTime, selectedDate]);

  // Generate 15-minute interval times for the entire 24-hour period
  const generateTimes = () => {
    const times = [];
    let currentTime = new Date(selectedDate);
    currentTime.setHours(0, 0, 0, 0); // Set to midnight (00:00)

    while (currentTime.getDate() === selectedDate.getDate()) {
      times.push({
        value: format(currentTime, "HH:mm"),
        label: format(currentTime, "hh:mm a"),
      });
      currentTime.setMinutes(currentTime.getMinutes() + 15);
    }
    return times;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValue("date", date); // Update form value for date
  };

  const times = generateTimes();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const formatStartDate = (date, timeString) => {
        if (!date || !timeString) return null;
        const [hours, minutes] = timeString.value.split(":").map(Number);
        const startDate = new Date(date);
        startDate.setHours(hours, minutes, 0, 0);
        return startDate;
      };

      // Format start date by combining selected date and time
      const startDate = formatStartDate(selectedDate, data.time);

      if (!startDate || !endTime) {
        throw new Error("Invalid date/time selection");
      }

      // Format services summary
      const servicesSummary = data.services
        .map((service) => service.label)
        .join(" and ");

      const roleMapping = {
        Photographer: ["photography", "photo", "drone"],
        Videographer: ["video"],
        "Floor planner": ["floor plan"],
      };

      const servicesOffered = [
        ...new Set(
          data.services.flatMap((service) =>
            Object.keys(roleMapping).filter((role) =>
              roleMapping[role].some((keyword) =>
                service.value.toLowerCase().includes(keyword)
              )
            )
          )
        ),
      ];

      const formattedData = {
        contractorId: contractorId, // Using the state value
        agentId: agentId, // Using the state value
        propertyId: data.propertyId,
        summary: servicesSummary,
        address: data.address,
        start: startDate.toISOString(),
        end: endTime.toISOString(),
        access: data.access?.value || "",
        additionalNotes: data.additionalNotes || "",
        servicesOffered,
      };

      const response = await axios.post("/admin/events", formattedData);
      if (response.data.success) {
        showToast("success", "Event added successfully");
        setAddEvent(false);
        refreshEvents(response.data.data);
      }
    } catch (error) {
      console.error("Error submitting event:", error);
      showToast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleContractorSelection = (contractor) => {
    setContractorId(contractor._id); // Store contractor ID
  };

  const handleAgentSelection = (agent) => {
    setAgentId(agent._id); // Store agent ID
  };

  // Transform properties for react-select
  const formattedProperties = properties.map((property) => ({
    label: property.address,
    value: property.propertyId,
  }));

  const handleAddressChange = (selected, action, event) => {
    // Prevent event propagation
    if (event) {
      event.stopPropagation();
    }

    if (action === "clear") {
      setValue("propertyId", null);
      setValue("address", null);
    } else if (selected) {
      setValue("propertyId", selected.value);
      setValue("address", selected.label);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="w-full">
        {/* Date Picker */}
        <div className="w-full flex items-center justify-center">
          <Calendar
            tileDisabled={tileDisabled}
            prev2Label={null}
            next2Label={null}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        <input type="hidden" {...register("date")} value={selectedDate} />

        <br />

        {/* Time Slot Selection */}
        <div>
          <label className="form-label">Select Time Slot</label>
          <Controller
            control={control}
            name="time"
            render={({ field }) => (
              <Select
                {...field}
                options={times}
                className="w-full"
                placeholder="Select a time"
              />
            )}
          />
        </div>

        <br />

        {/* Address Selection */}
        <div>
          <label className="form-label">Select Address</label>
          <Controller
            control={control}
            name="property"
            render={({ field }) => (
              <Select
                {...field}
                options={formattedProperties}
                className="w-full"
                placeholder="Select address"
                isSearchable
                isClearable={true}
                onChange={(selected, action) => {
                  field.onChange(selected);
                  handleAddressChange(selected, action.action, action.event);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                components={{
                  // Prevent clicks from bubbling up to modal
                  ClearIndicator: (props) => {
                    const {
                      innerProps: { ref, ...restInnerProps },
                      ...restProps
                    } = props;
                    return (
                      <div
                        {...restInnerProps}
                        ref={ref}
                        onClick={(e) => {
                          e.stopPropagation();
                          restInnerProps.onClick(e);
                        }}
                      >
                        {props.children}
                      </div>
                    );
                  },
                }}
              />
            )}
          />
        </div>

        {/* Hidden Fields to Store propertyId and address */}
        <input type="hidden" {...register("propertyId")} />
        <input type="hidden" {...register("address")} />

        <br />

        {/* Service Selection */}
        <div>
          <label className="form-label">Select Services</label>
          <Controller
            control={control}
            name="services"
            render={({ field }) => (
              <Select
                {...field}
                options={serviceOptions}
                isMulti
                isClearable={false}
                className="w-full"
                placeholder="Select services"
              />
            )}
          />
        </div>

        {/* Display End Time */}
        {endTime && (
          <div>
            <br />
            <p>
              <strong>End Time:</strong> {format(endTime, "hh:mm a")}
            </p>
          </div>
        )}

        <br />

        <div>
          <label className="form-label">Select Contractor </label>
          <SelectWithImage
            options={contractors}
            onSelect={handleContractorSelection}
            placeholder="Choose a contractor..."
          />
        </div>

        <br />

        <div>
          <label className="form-label">Select Agent </label>
          <SelectWithImage
            options={agents}
            onSelect={handleAgentSelection}
            placeholder="Choose an agent..."
          />
        </div>

        <br />
        {/* Access Selection */}
        <div>
          <label className="form-label">Select Access</label>
          <Controller
            control={control}
            name="access"
            render={({ field }) => (
              <Select
                {...field}
                options={accessOptions}
                className="w-full"
                placeholder="Select access"
              />
            )}
          />
        </div>

        <br />

        {/* Additional Notes */}
        <div>
          <label className="form-label">Additional Notes</label>
          <textarea
            {...register("additionalNotes")}
            className="form-textarea border border-mediumgray"
            rows={3}
            placeholder="Add notes here"
          ></textarea>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end mt-4">
          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            className="black-button "
          >
            Create
          </Button>
        </div>
      </div>
    </form>
  );
};

const EventCard = ({
  event: initialEvent,
  agents,
  contractors,
  properties,
  shortView,
  refreshEventEdit,
  refreshEventDelete,
}) => {
  const [event, setEvent] = useState(initialEvent);
  const [isEditing, setIsEditing] = useState(false); // Manage modal state for editing
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRefreshEventEdit = (updatedEvent) => {
    setEvent(updatedEvent); // Update local event state
    if (refreshEventEdit) refreshEventEdit(updatedEvent); // Optionally notify parent
  };

  const handleDeleteEvent = async () => {
    try {
      setLoading(true);
      const response = await axios.delete(`/admin/events/${event._id}`);
      if (response.data.success) {
        showToast("success", "Event deleted successfully");
        setIsDelete(false); // Close the modal
        refreshEventDelete(event?._id); // Remove the deleted event
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      showToast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-darkergray space-y-1">
      <h6>{event.summary}</h6>

      {/* Address */}
      {event.address && (
        <div className="flex items-center space-x-2">
          <BiMap size={16} />
          <span>{event.address}</span>
        </div>
      )}

      {/* Date & Time */}
      <div className="flex items-center space-x-2">
        <AiOutlineCalendar size={16} />
        <span>{formatDate(event.start)}</span>
      </div>
      <div className="flex items-center space-x-2">
        <AiOutlineClockCircle size={16} />
        <span>
          {formatTime(event.start)} - {formatTime(event.end)}
        </span>
      </div>

      {!shortView && (
        <>
          {/* Access Info */}
          <div className="flex items-center space-x-2 ">
            <BiHomeAlt size={16} />
            <span>Access: {event.access}</span>
          </div>

          {/* Attendees */}
          <div className="border-t py-4 flex flex-col gap-4">
            {event?.contractorId && (
              <div className="w-full px-4 py-2 bg-lightgray">
                <p className="font-semibold">Contractor</p>
                <div className="flex items-center gap-2 mt-2">
                  <div className="w-12 h-12 shrink-0">
                    <img
                      src={event.contractorId?.picture}
                      alt="contractor"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col items-start text-xs">
                    <span>{event.contractorId?.name}</span>
                    <span>{event.contractorId?.email}</span>
                    <span> {event.contractorId?.mobile}</span>
                  </div>
                </div>
              </div>
            )}
            {event?.agentId && (
              <div className="w-full px-4 py-2 bg-lightgray">
                <p className="font-semibold">Agent</p>
                <div className="flex items-center gap-2 mt-2">
                  <div className="w-12 h-12 shrink-0">
                    <img
                      src={event.agentId?.picture}
                      alt="agent"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <span>
                      <strong>Name:</strong> {event.agentId?.name}
                    </span>
                    <span>
                      <strong>Email:</strong> {event.agentId?.email}
                    </span>
                    <span>
                      <strong>Mobile:</strong> {event.agentId?.mobile}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Booking History */}
          <div className="border-t py-4">
            <h6>Additional Notes</h6>
            <p>{event.additionalNotes}</p>
          </div>

          <div className="w-full border-t pt-4 flex items-center justify-evenly space-x-4">
            <Button
              onClick={() => setIsEditing(true)}
              className="flex items-center space-x-1 text-darkergray"
            >
              <FaEdit size={14} />
              <span className="text-sm">Edit</span>
            </Button>
            <div className="border-l border-gray-300 h-5"></div>
            <Button
              className="flex items-center space-x-1 text-darkergray"
              onClick={() => setIsDelete(true)}
              disabled={loading}
            >
              <FaTrash size={14} />
              <span className="text-sm">Delete</span>
            </Button>
          </div>

          {/* Edit Modal */}
          <Modal
            isOpen={isEditing}
            onClose={() => setIsEditing(false)}
            title="Edit Event"
            className="max-w-lg min-h-[400px] max-h-[94vh] px-4"
          >
            <EditEvent
              agents={agents}
              contractors={contractors}
              properties={properties}
              eventData={event} // Pass current event data for editing
              setIsEditing={setIsEditing} // Handle updated data
              refreshEventEdit={handleRefreshEventEdit}
            />
          </Modal>

          {/* Delete Modal */}
          <Modal
            isOpen={isDelete}
            onClose={() => setIsDelete(false)}
            title="Delete Event"
          >
            <div className="flex flex-col items-center justify-center">
              <p>Are you sure you want to delete this event?</p>
              <div className="w-full flex gap-2 justify-end py-4">
                <Button
                  onClick={() => setIsDelete(false)}
                  className="gray-button"
                >
                  No
                </Button>
                <Button
                  onClick={handleDeleteEvent}
                  className="black-button"
                  loading={loading}
                  disabled={loading}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

const EditEvent = ({
  agents,
  contractors,
  properties,
  eventData,
  setIsEditing,
  refreshEventEdit,
}) => {
  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      ...eventData,
      property: {
        label: eventData.address,
        value: eventData.propertyId,
      },
      time: {
        value: format(new Date(eventData.start), "HH:mm"),
        label: format(new Date(eventData.start), "hh:mm a"),
      },
      services: eventData?.summary
        ? eventData.summary.split(" and ").map((service) => ({
            label: service,
            value: service,
            duration: services[service] ? services[service] * 60 : 0, // Convert hours to minutes
          }))
        : [],
      access: {
        value: eventData?.access,
        label: eventData?.access,
      },
    },
  });

  const [selectedDate, setSelectedDate] = useState(new Date(eventData.start));
  const [endTime, setEndTime] = useState(new Date(eventData.end));
  const [contractorId, setContractorId] = useState(
    eventData?.contractorId?._id
  );
  const [agentId, setAgentId] = useState(eventData?.agentId?._id);
  const [loading, setLoading] = useState(false);

  const selectedServices = watch("services");
  const selectedTime = watch("time");

  const [isChanged, setIsChanged] = useState(false); // Tracks if the form is changed

  const defaultValues = watch(); // Watch all form values

  // Compare current form values with initial default values
  useEffect(() => {
    const { services, time, property, date, ...filteredDefaultValues } =
      defaultValues;

    const currentValues = {
      ...filteredDefaultValues,
      contractorId,
      agentId,
      address: defaultValues.property.label,
      propertyId: defaultValues.property.value,
      access: defaultValues.access?.value || null, // Include specific nested values
      summary:
        defaultValues.services?.map((service) => service.label).join(" and ") ||
        "",
      // Add time comparison
      start: formatStartDate(selectedDate, time),
    };

    setIsChanged(!isEqual(currentValues, eventData)); // Update the change state
  }, [defaultValues, selectedDate, endTime, eventData, contractorId, agentId]);

  // Generate 15-minute interval times for the entire 24-hour period
  // const generateTimes = () => {
  //   const times = [];
  //   let currentTime = new Date(selectedDate);
  //   currentTime.setHours(0, 0, 0, 0); // Set to midnight (00:00)

  //   while (currentTime.getDate() === selectedDate.getDate()) {
  //     times.push({
  //       value: format(currentTime, "HH:mm"),
  //       label: format(currentTime, "hh:mm a"),
  //     });
  //     currentTime.setMinutes(currentTime.getMinutes() + 15);
  //   }
  //   return times;
  // };
  const generateTimes = () => {
    const times = [];
    let currentTime = new Date(selectedDate);
    currentTime.setHours(8, 0, 0, 0); // Set to 8:00 AM

    const endTime = new Date(selectedDate);
    endTime.setHours(20, 0, 0, 0); // Set to 8:00 PM

    while (currentTime < endTime) {
      times.push({
        value: format(currentTime, "HH:mm"), // 24-hour format
        label: format(currentTime, "hh:mm a"), // 12-hour format
      });
      currentTime.setMinutes(currentTime.getMinutes() + 15); // Increment by 15 minutes
    }
    return times;
  };

  useEffect(() => {
    if (selectedTime?.value && selectedServices?.length) {
      const totalDuration = selectedServices.reduce(
        (sum, service) => sum + service.duration,
        0
      );

      const [hours, minutes] = selectedTime.value.split(":").map(Number);
      const startDateTime = new Date(selectedDate);
      startDateTime.setHours(hours, minutes, 0, 0);

      const calculatedEndTime = addMinutes(startDateTime, totalDuration);
      setEndTime(calculatedEndTime);
    } else {
      setEndTime(null);
    }
  }, [selectedServices, selectedTime, selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValue("date", date);
  };

  const formattedProperties = properties.map((property) => ({
    label: property.address,
    value: property.propertyId,
  }));

  const handleContractorSelection = (contractor) => {
    setContractorId(contractor._id);
  };

  const handleAgentSelection = (agent) => {
    setAgentId(agent._id);
  };

  const onSubmit = async (data) => {
    if (!isChanged) return; // Prevent submission if nothing has changed
    try {
      setLoading(true);

      // Format start date by combining selected date and time
      const startDate = formatStartDate(selectedDate, data.time);

      if (!startDate || !endTime) {
        throw new Error("Invalid date/time selection");
      }

      // Format services summary
      const servicesSummary = data.services
        .map((service) => service.label)
        .join(" and ");

      const roleMapping = {
        Photographer: ["photography", "photo", "drone"],
        Videographer: ["video"],
        "Floor planner": ["floor plan"],
      };

      const servicesOffered = [
        ...new Set(
          data.services.flatMap((service) =>
            Object.keys(roleMapping).filter((role) =>
              roleMapping[role].some((keyword) =>
                service.value.toLowerCase().includes(keyword)
              )
            )
          )
        ),
      ];

      const formattedData = {
        _id: eventData._id,
        contractorId: contractorId, // Using the state value
        agentId: agentId, // Using the state value
        propertyId: data.propertyId,
        summary: servicesSummary,
        address: data.address,
        start: startDate.toISOString(),
        end: endTime.toISOString(),
        access: data.access?.value || "",
        additionalNotes: data.additionalNotes || "",
        notify: data.notify || false, // Pass notify value
        servicesOffered,
      };

      const response = await axios.put("/admin/events", formattedData);
      if (response.data.success) {
        showToast("success", "Event updated successfully");
        setIsEditing(false);
        refreshEventEdit(response.data.data);
      }
    } catch (error) {
      console.error("Error submitting event:", error);
      showToast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddressChange = (selected, action, event) => {
    // Prevent event propagation
    if (event) {
      event.stopPropagation();
    }

    if (action === "clear") {
      setValue("propertyId", null);
      setValue("address", null);
    } else if (selected) {
      setValue("propertyId", selected.value);
      setValue("address", selected.label);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="w-full">
        {/* Date Picker */}
        <div className="w-full flex items-center justify-center">
          <Calendar
            tileDisabled={() => false}
            prev2Label={null}
            next2Label={null}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        <input type="hidden" {...register("date")} value={selectedDate} />

        <br />

        {/* Time Slot Selection */}
        <div>
          <label className="form-label">Select Time Slot</label>
          <Controller
            control={control}
            name="time"
            render={({ field }) => (
              <Select
                {...field}
                options={generateTimes(selectedDate)}
                className="w-full"
                placeholder="Select a time"
              />
            )}
          />
        </div>

        <br />

        {/* Address Selection */}
        <div>
          <label className="form-label">Select Address</label>
          <Controller
            control={control}
            name="property"
            render={({ field }) => (
              <Select
                {...field}
                options={formattedProperties}
                className="w-full"
                placeholder="Select address"
                isSearchable
                isClearable={true}
                onChange={(selected, action) => {
                  field.onChange(selected);
                  handleAddressChange(selected, action.action, action.event);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                components={{
                  // Prevent clicks from bubbling up to modal
                  ClearIndicator: (props) => {
                    const {
                      innerProps: { ref, ...restInnerProps },
                      ...restProps
                    } = props;
                    return (
                      <div
                        {...restInnerProps}
                        ref={ref}
                        onClick={(e) => {
                          e.stopPropagation();
                          restInnerProps.onClick(e);
                        }}
                      >
                        {props.children}
                      </div>
                    );
                  },
                }}
              />
            )}
          />
        </div>

        <input type="hidden" {...register("propertyId")} />
        <input type="hidden" {...register("address")} />

        <br />

        {/* Service Selection */}
        <div>
          <label className="form-label">Select Services</label>
          <Controller
            control={control}
            name="services"
            render={({ field }) => (
              <Select
                {...field}
                options={serviceOptions}
                isClearable={false}
                isMulti
                className="w-full"
                placeholder="Select services"
              />
            )}
          />
        </div>

        {endTime && (
          <div>
            <br />
            <p>
              <strong>End Time:</strong> {format(endTime, "hh:mm a")}
            </p>
          </div>
        )}

        <br />

        {/* Contractor Selection */}
        <div>
          <label className="form-label">Select Contractor</label>
          <SelectWithImage
            options={contractors}
            onSelect={handleContractorSelection}
            placeholder="Choose a contractor..."
            selectedValue={contractorId}
          />
        </div>

        <br />

        {/* Agent Selection */}
        <div>
          <label className="form-label">Select Agent</label>
          <SelectWithImage
            options={agents}
            onSelect={handleAgentSelection}
            placeholder="Choose an agent..."
            selectedValue={agentId}
          />
        </div>

        <br />

        {/* Access Selection */}
        <div>
          <label className="form-label">Select Access</label>
          <Controller
            control={control}
            name="access"
            render={({ field }) => (
              <Select
                {...field}
                options={accessOptions}
                className="w-full"
                placeholder="Select access"
              />
            )}
          />
        </div>

        <br />

        {/* Additional Notes */}
        <div>
          <label className="form-label">Additional Notes</label>
          <textarea
            {...register("additionalNotes")}
            className="form-textarea border border-mediumgray"
            rows={3}
            placeholder="Add notes here"
          ></textarea>
        </div>

        {/* Notify Checkbox */}
        <div className="flex items-center">
          <label className="form-label">
            Notify No-one
            <input type="checkbox" className="ml-2" {...register("notify")} />
          </label>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end mt-4">
          <Button
            type="submit"
            className="black-button"
            loading={loading}
            disabled={!isChanged || loading}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

const FilterDropdown = ({
  options,
  selectedOption,
  onOptionSelect,
  placeholder = "Select an option...",
  allOption = { _id: "all", name: "All" },
  imageKey = null, // Key for the image source (optional)
  labelKey = "name", // Key for the main display label
  secondaryLabelKey = null, // Key for an additional label (optional)
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const dropdownRef = useRef(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    onOptionSelect(option);
    setIsOpen(false);
    setSearchTerm(""); // Reset search term
  };

  const filteredOptions = options.filter((option) =>
    option[labelKey].toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div ref={dropdownRef} className="w-full max-w-sm relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between border border-mediumgray rounded px-4 py-1.5 text-sm focus:outline-none"
      >
        {selectedOption ? (
          <div className="flex items-center gap-3">
            {imageKey && selectedOption[imageKey] && (
              <>
                <img
                  src={selectedOption[imageKey]}
                  alt={selectedOption[labelKey]}
                  className="w-8 h-8 rounded-full object-cover"
                />
                <div
                  className="w-6 h-6 rounded"
                  style={{ backgroundColor: selectedOption["calendarColor"] }}
                ></div>
              </>
            )}
            <div className="flex flex-col items-start">
              <span className="font-medium">{selectedOption[labelKey]}</span>
              {secondaryLabelKey && (
                <span className="text-sm text-gray-500">
                  {selectedOption[secondaryLabelKey]}
                </span>
              )}
            </div>
          </div>
        ) : (
          <span className="text-darkergray">{placeholder}</span>
        )}
        <i className="fas fa-chevron-down text-xs"></i>
      </button>

      {isOpen && (
        <div className="absolute mt-1 w-full bg-white border border-mediumgray rounded z-10">
          <div className="p-2">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
              className="w-full px-3 py-2 border border-gray-300 rounded text-sm focus:outline-none"
            />
          </div>
          <ul className="py-1 max-h-60 overflow-auto m-0">
            <li>
              <button
                onClick={() => handleSelect(allOption)}
                className="w-full px-4 py-2 flex items-center gap-3 hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
              >
                <span className="font-medium">{allOption[labelKey]}</span>
              </button>
            </li>
            {filteredOptions.map((option) => (
              <li key={option._id}>
                <button
                  onClick={() => handleSelect(option)}
                  className="w-full px-4 py-2 flex items-center gap-3 hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
                >
                  {imageKey && option[imageKey] && (
                    <>
                      <img
                        src={option[imageKey]}
                        alt={option[labelKey]}
                        className="w-8 h-8 rounded-full object-cover"
                      />
                      <div
                        className="w-6 h-6 rounded"
                        style={{
                          backgroundColor: option["calendarColor"],
                        }}
                      ></div>
                    </>
                  )}
                  <div className="flex flex-col items-start">
                    <span className="font-medium">{option[labelKey]}</span>
                    {secondaryLabelKey && (
                      <span className="text-sm text-gray-500">
                        {option[secondaryLabelKey]}
                      </span>
                    )}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const Events = () => {
  const [contractors, setContractors] = useState([]);
  const [agents, setAgents] = useState([]);
  const [properties, setProperties] = useState([]);
  const [allEvents, setAllEvents] = useState([]); // Store all events
  const [filteredEvents, setFilteredEvents] = useState([]); // Store filtered events

  const [selectedContractor, setSelectedContractor] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showBeleefEvents, setShowBeleefEvents] = useState(true); // Checkbox state

  const refreshEventAdd = (newEvent) => {
    const formattedEvent = {
      ...newEvent,
      title: newEvent.name || newEvent.summary, // Use database name or Google summary
      start: new Date(newEvent.start), // Convert start to Date object
      end: new Date(newEvent.end), // Convert end to Date object
      description: newEvent.description || "No description available", // Default description
    };

    setAllEvents((prevEvents) => [...prevEvents, formattedEvent]); // Add to allEvents
    setFilteredEvents((prevEvents) => [...prevEvents, formattedEvent]); // Add to filteredEvents
  };

  const refreshEventEdit = (updatedEvent) => {
    setAllEvents((prevEvents) =>
      prevEvents.map((event) =>
        event._id === updatedEvent._id
          ? {
              ...event, // Keep existing event data
              ...updatedEvent, // Overwrite fields from the response
              title: updatedEvent.name || updatedEvent.summary || event.title, // Update title if available
              start: updatedEvent.start
                ? new Date(updatedEvent.start)
                : event.start, // Update start if available
              end: updatedEvent.end ? new Date(updatedEvent.end) : event.end, // Update end if available
              description: updatedEvent.description || event.description, // Update description if available
            }
          : event
      )
    );

    setFilteredEvents((prevEvents) =>
      prevEvents.map((event) =>
        event._id === updatedEvent._id
          ? {
              ...event, // Keep existing event data
              ...updatedEvent, // Overwrite fields from the response
              title: updatedEvent.name || updatedEvent.summary || event.title,
              start: updatedEvent.start
                ? new Date(updatedEvent.start)
                : event.start,
              end: updatedEvent.end ? new Date(updatedEvent.end) : event.end,
              description: updatedEvent.description || event.description,
            }
          : event
      )
    );
  };

  const refreshEventDelete = (deletedEventId) => {
    // Remove the event from allEvents
    setAllEvents((prevEvents) =>
      prevEvents.filter((event) => event._id !== deletedEventId)
    );

    // Remove the event from filteredEvents
    setFilteredEvents((prevEvents) =>
      prevEvents.filter((event) => event._id !== deletedEventId)
    );

    setSelectedEvent(null);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`/admin/events`);
        if (response.data.success) {
          const formatEvents = response.data.data.map((event) => {
            // Standardize start and end fields for database and Google events
            const isDatabaseEvent = event.summary !== "Non-Beleef event"; // Check source field
            // const isDatabaseEvent = event.type !== "google"; // Check source field
            return {
              ...event,
              title: event.name || event.summary, // Use database name or Google summary
              start: isDatabaseEvent
                ? new Date(event.start) // Database uses a simple start field
                : new Date(event.start.dateTime || event.start.date), // Google event may use dateTime or date
              end: isDatabaseEvent
                ? new Date(event.end) // Database uses a simple end field
                : new Date(event.end.dateTime || event.end.date), // Google event may use dateTime or date
              description: event.description || "No description available",
            };
          });

          setAllEvents(formatEvents); // Store all events
          setFilteredEvents(formatEvents); // Initially display all events
        }
      } catch (error) {
        console.error("Error fetching Google events", error);
      }
    };

    const fetchContractors = async () => {
      try {
        const response = await axios.get(
          `/admin/contractor?profileComplete=true`
        );
        if (response.data.success) {
          setContractors(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching contractors", error);
      }
    };

    const fetchAgents = async () => {
      try {
        const response = await axios.get(`/admin/user`);
        if (response.data.success) {
          setAgents(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching agents", error);
      }
    };

    const fetchProperties = async () => {
      try {
        const response = await axios.get(`/admin/events/uniqueProperties`);
        if (response.data.success) {
          setProperties(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching agents", error);
      }
    };

    fetchEvents();
    fetchContractors();
    fetchAgents();
    fetchProperties();
  }, []); // Dependency array includes `events` to refetch if parent data changes

  const [selectedEvent, setSelectedEvent] = useState(null); // State for selected event

  const eventStyleGetter = (event) => {
    const backgroundColor =
      event.color || event?.contractorId?.calendarColor || "#cccc"; // Use the event's color or a default gray color
    return {
      style: {
        backgroundColor,
        opacity: 1,
        color: "#fff",
        border: "none",
        display: "block",
      },
    };
  };

  // Event selection handler
  const handleSelectEvent = (event) => {
    setSelectedEvent(event); // Store selected event details
  };

  const [addEvent, setAddEvent] = useState(false);

  // Apply all filters dynamically
  const filterEvents = () => {
    let filtered = [...allEvents]; // Start with all events

    // Filter out "Non-Beleef event" if checkbox is unchecked
    if (!showBeleefEvents) {
      filtered = filtered.filter(
        (event) => event.summary !== "Non-Beleef event"
      );
    }

    // Apply contractor filter
    if (selectedContractor && selectedContractor._id !== "all") {
      filtered = filtered.filter(
        (event) =>
          event.contractorId?._id === selectedContractor?._id ||
          event.contractorId === selectedContractor?._id
      );
    }

    // Apply property filter
    if (selectedProperty && selectedProperty._id !== "all") {
      filtered = filtered.filter(
        (event) => event.address === selectedProperty.address
      );
    }

    setFilteredEvents(filtered); // Update the filtered events state
  };

  // Handle checkbox toggle for "Show Beleef Events"
  const handleCheckboxChange = () => {
    setShowBeleefEvents((prev) => !prev);
  };

  // Handle contractor selection
  const handleContractorSelect = (contractor) => {
    setSelectedContractor(contractor);
  };

  // Handle property selection
  const handlePropertySelect = (property) => {
    setSelectedProperty(property);
  };

  // UseEffect to apply filters when state changes
  useEffect(() => {
    filterEvents(); // Reapply filters when any of the filter states change
  }, [showBeleefEvents, selectedContractor, selectedProperty, allEvents]);

  const EventWithIcons = ({ event }) => (
    <div className="space-y-1">
      {event?.title && <div className="eventDisplay">{event.title}</div>}
      {event?.address && (
        <div className="eventDisplay flex items-center gap-1">
          <BiMap size={12} /> {event.address}
        </div>
      )}

      {event?.contractorId?.name && (
        <div className="eventDisplay flex items-center gap-1">
          <FaRegUserCircle size={12} /> {event?.contractorId?.name}
        </div>
      )}

      {event?.start && event?.end && (
        <div className="eventDisplay flex items-center gap-1">
          <AiOutlineClockCircle size={12} /> {formatTime(event.start)} -{" "}
          {formatTime(event.end)}
        </div>
      )}
    </div>
  );

  return (
    <div className="container">
      {/* Dropdown for contractor selection */}
      <div className="flex w-full justify-between gap-4">
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <FilterDropdown
            options={contractors}
            selectedOption={selectedContractor}
            onOptionSelect={(option) => handleContractorSelect(option)}
            placeholder="Select a contractor..."
            allOption={{ _id: "all", name: "Select all Contractors" }}
            imageKey="picture" // Assume contractors have pictures
            labelKey="name" // Contractor name
          />

          <FilterDropdown
            options={properties}
            selectedOption={selectedProperty}
            onOptionSelect={(option) => handlePropertySelect(option)}
            placeholder="Select a property..."
            allOption={{ _id: "all", address: "Select all Properties" }}
            imageKey={null} // Assume properties don't have images
            labelKey="address" // Property address
          />
        </div>
        <Button
          className="black-button h-fit w-[130px]"
          onClick={() => setAddEvent(true)}
        >
          Add Event
        </Button>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="showBeleefEvents"
          checked={showBeleefEvents}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="showBeleefEvents">Show All Events</label>
      </div>

      <div style={{ marginTop: "20px", height: "100vh" }}>
        <BigCalendar
          localizer={localizer}
          // events={filteredEvents.map((event) => ({
          //   ...event,
          //   title: [
          //     event?.title,
          //     event?.address,
          //     event?.contractorId?.name,
          //     `${formatTime(event?.start)} - ${formatTime(event?.end)}`,
          //   ]
          //     .filter((line) => line) // Remove undefined or empty lines
          //     .join("\n"), // Join non-empty lines with a newline character
          // }))}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "auto", margin: "20px 0" }}
          onSelectEvent={handleSelectEvent} // Handle event click
          eventPropGetter={eventStyleGetter} // Add this line
          components={{
            event: EventWithIcons, // Use the custom component for rendering events
          }}
        />
      </div>

      <Modal
        isOpen={selectedEvent}
        onClose={() => setSelectedEvent(null)}
        title="Event Details"
      >
        {selectedEvent && (
          <EventCard
            event={selectedEvent}
            agents={agents}
            contractors={contractors}
            properties={properties}
            shortView={selectedEvent.summary === "Non-Beleef event"}
            refreshEventEdit={refreshEventEdit}
            refreshEventDelete={refreshEventDelete}
          />
        )}
      </Modal>

      <Modal
        isOpen={addEvent}
        onClose={() => {
          setAddEvent(false);
        }}
        title="Add Event"
        className="max-w-lg min-h-[400px] max-h-[94vh] px-4"
      >
        <AddEvent
          agents={agents}
          contractors={contractors}
          properties={properties}
          setAddEvent={setAddEvent}
          refreshEvents={refreshEventAdd}
        />
      </Modal>
    </div>
  );
};

export default Events;
