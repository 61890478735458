import React, { useEffect, useState } from "react";
import axios from "utils/axios";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi2";

const SmsLogs = () => {
  const [smsLogs, setSmsLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch SMS logs count
    const fetchSmsLogs = async () => {
      try {
        const response = await axios.get("/admin/smsLog/count");
        if (response.data.success) {
          setSmsLogs(response.data.data);
        } else {
          setError("Failed to fetch data.");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchSmsLogs();
  }, []);

  if (loading) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto mt-8">
      <h4 className="text-center mb-4">SMS Logs Count</h4>

        {smsLogs.map((log, index) => (
          <Disclosure key={index} className="mb-4">
            {({ open }) => (
              <>
                <DisclosureButton className="flex justify-between items-center w-full py-2.5 px-4 text-left text-sm font-medium bg-lightgray rounded mb-4">
                  <h5>{log.officeName} ({log.officeCount})</h5>
                  <HiChevronDown
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } w-4 h-4 text-darkergray`}
                  />
                </DisclosureButton>
                <DisclosurePanel className="px-4 py-2 text-sm">
                  <ul className="space-y-2 m-0">
                    {log.users.map((user, userIndex) => (
                      <li
                        key={userIndex}
                        className="flex justify-between border-b py-2"
                      >
                        <span>{user.name}</span>
                        <span>{user.count}</span>
                      </li>
                    ))}
                  </ul>
                </DisclosurePanel>
              </>
            )}
          </Disclosure>
        ))}

    </div>
  );
};

export default SmsLogs;
