import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import {
  FaListUl,
  FaLocationDot,
  FaRegCircleUser,
  FaUserTie,
} from "react-icons/fa6";
import { MdOutlineCameraAlt, MdOutlineSms } from "react-icons/md";

import { AiOutlineLogout } from "react-icons/ai";
import { HiChevronDown } from "react-icons/hi2";
import { FaShoppingCart } from "react-icons/fa";
import logo from "assets/images/logo.png";
import { AuthContext } from "../../context/AuthContext";
// import profileImg from "assets/images/profile (1).png";
import Button from "./Button";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaRegUser } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";
import { TbPrompt } from "react-icons/tb";
import { useLogoutMutation, useMeQuery } from "apis/user";
import Avatar from "assets/images/profile (1).png";
import {
  MdEvent,
  MdMiscellaneousServices,
  MdOutlinePassword,
} from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { TbTemplate } from "react-icons/tb";

const Navbar = () => {
  const location = useLocation();
  const { data: user } = useMeQuery();
  const { mutate: logout } = useLogoutMutation();

  // Function to generate URLs with updated 'tab' parameter
  const generateTabUrl = (tabName) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", tabName);
    return `${location.pathname}?${searchParams.toString()}`;
  };

  const { name, email, picture } = user || {};

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isShortCutDropdown, setIsShortCutDropdown] = useState(false);
  const [isShortCutIcon, setIsShortCutIcon] = useState(false);

  const profileDropdownRef = useRef(null);
  const shortcutDropdownRef = useRef(null);

  const checkRouteForChat = () => {
    const chatPattern = /^\/property\/[^/]+$/;
    setIsShortCutIcon(chatPattern.test(location.pathname));
  };

  const shortCutDropdown = () => {
    setIsShortCutDropdown(!isShortCutDropdown);
    setIsProfileDropdown(false); // Close the profile dropdown when opening shortcut dropdown
  };

  const profileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
    setIsShortCutDropdown(false); // Close the shortcut dropdown when opening profile dropdown
  };

  const handleTogglerClick = () => {
    const newSidebarState = !isSidebarOpen;
    setIsSidebarOpen(newSidebarState);
  };

  const handleClickOutside = (event) => {
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target)
    ) {
      setIsProfileDropdown(false);
    }
    if (
      shortcutDropdownRef.current &&
      !shortcutDropdownRef.current.contains(event.target)
    ) {
      setIsShortCutDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    checkRouteForChat();
  }, [location]);
  const { isAuthenticated, role } = useContext(AuthContext);
  const meloAdmin = [
    {
      name: "Dashboard",
      icon: <LuLayoutDashboard className="h-4 w-4" />,
      route: "/",
    },
    {
      name: "Calendar",
      icon: <MdEvent className="h-4 w-4" />,
      route: "/events",
    },
    {
      name: "Services",
      icon: <MdMiscellaneousServices className="h-4 w-4" />,
      route: "/services",
    },
    {
      name: "Edits",
      icon: <MdOutlineCameraAlt className="h-4 w-4" />,
      route: "/edits",
    },
    {
      name: "Change Password",
      icon: <MdOutlinePassword className="h-4 w-4" />,
      route: "/change-password",
    },
  ];

  const beleefAdmin = [
    {
      name: "Dashboard",
      icon: <LuLayoutDashboard className="h-4 w-4" />,
      route: "/",
    },
    {
      name: "Property",
      icon: <FaHouse className="h-4 w-4" />,
      route: "/property",
    },
    {
      name: "Change Password",
      icon: <MdOutlinePassword className="h-4 w-4" />,
      route: "/change-password",
    },
  ];

  const superAdmin = [
    {
      name: "Dashboard",
      icon: <LuLayoutDashboard className="h-4 w-4" />,
      route: "/",
    },
    {
      name: "Users",
      icon: <FaRegUser className="h-4 w-4" />,
      route: "/users",
    },
    {
      name: "Property",
      icon: <FaHouse className="h-4 w-4" />,
      route: "/property",
    },
    {
      name: "Marketing Price",
      icon: <FaShoppingCart className="h-4 w-4" />,
      route: "/marketingPrice",
    },
    {
      name: "Sms Logs",
      icon: <MdOutlineSms className="h-4 w-4" />,
      route: "/sms-logs",
    },
    {
      name: "Listing",
      icon: <FaListUl className="h-4 w-4" />,
      route: "/pricepoint",
    },
    {
      name: "Edits",
      icon: <MdOutlineCameraAlt className="h-4 w-4" />,
      route: "/edits",
    },
    {
      name: "Prompt",
      icon: <TbPrompt className="h-4 w-4" />,
      route: "/prompts",
    },
    {
      name: "Templates",
      icon: <TbTemplate className="h-4 w-4" />,
      route: "/templates",
    },
    {
      name: "Triggers",
      icon: <RiCalendarScheduleLine className="h-4 w-4" />,
      route: "/triggers",
    },
    {
      name: "Calendar",
      icon: <MdEvent className="h-4 w-4" />,
      route: "/events",
    },
    {
      name: "Services",
      icon: <MdMiscellaneousServices className="h-4 w-4" />,
      route: "/services",
    },
    {
      name: "Contractors",
      icon: <FaUserTie className="h-4 w-4" />,
      route: "/contractors",
    },
    {
      name: "Add Admin",
      icon: <IoMdPersonAdd className="h-4 w-4" />,
      route: "/admin",
    },
    {
      name: "Suburb",
      icon: <FaLocationDot className="h-4 w-4" />,
      route: "/suburb",
    },
    {
      name: "Change Password",
      icon: <MdOutlinePassword className="h-4 w-4" />,
      route: "/change-password",
    },
  ];

  return (
    <nav className="bg-white sticky top-0" style={{ zIndex: 1001 }}>
      <div className="container flex items-center justify-between py-6">
        <Button onClick={handleTogglerClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30"
            height="30"
          >
            <path
              stroke="rgba(33, 37, 41, 0.75)"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
              d="M4 7h22M4 15h22M4 23h22"
            />
          </svg>
        </Button>

        {/* Logo */}
        <div className="navbar-image flex items-center gap-4">
          <Link to="/">
            <img src={logo} alt="Beleef" className="w-[150px] h-auto" />
          </Link>
          {isShortCutIcon && (
            <HiChevronDown
              onClick={shortCutDropdown}
              className="text-sm cursor-pointer"
            />
          )}
          {isShortCutDropdown && (
            <div className="shortcut-dropdown-menu" ref={shortcutDropdownRef}>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("open-home")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Open Home
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("my-sign")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                My Sign
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("compliance")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Compliance
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("comms-log")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Comms Log
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("data-reports")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Data Reports
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("negotiation")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Negotiation
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("rental-appraisal")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Rental Appraisal
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("notifications")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Notifications
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("area-insights")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Area Insights
              </Link>
              <Link
                className="shortcut-dropdown-item"
                to={generateTabUrl("toolkit")}
                onClick={() => setIsShortCutDropdown(false)}
              >
                Toolkit
              </Link>
            </div>
          )}
        </div>

        {/* User Authentication */}
        {!user || !isAuthenticated ? (
          <Link to="/login" className="text-sm">
            Sign In
          </Link>
        ) : (
          <div className="relative">
            <img
              // src={picture || profileImg}
              src={Avatar}
              className="w-8 h-8 rounded-full cursor-pointer profile"
              alt="Profile"
              onClick={profileDropdown}
            />
            {isProfileDropdown && (
              <div className="profile-dropdown-menu" ref={profileDropdownRef}>
                <div className="flex items-center mb-4 px-2">
                  <img
                    src={Avatar}
                    className="w-10 h-10 rounded-full object-fill"
                    alt="Profile"
                  />
                  <div className="ml-3">
                    <p className="truncate w-44 text-sm font-semibold mb-0">
                      {name}
                    </p>
                    <p className="truncate w-44 text-sm text-darkergray mb-0">
                      {email}
                    </p>
                  </div>
                </div>
                <hr className="my-1" />
                <Link className="profile-dropdown-item gap-2" to="/profile">
                  <FaRegCircleUser />
                  <span>Profile</span>
                </Link>
                <Link className="profile-dropdown-item gap-2" onClick={logout}>
                  <AiOutlineLogout />
                  Logout
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 bg-white shadow-lg w-full max-w-[320px] transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40 overflow-y-auto`}
      >
        <div
          className="mt-12 flex justify-end pr-4 cursor-pointer"
          onClick={() => setIsSidebarOpen(false)}
        >
          <RxCross1 />
        </div>
        <div className="flex flex-col gap-6 p-4">
          {role === "meloAdmin"
            ? meloAdmin.map((item, index) => (
                <Link
                  key={index}
                  className=" pl-4 flex gap-2"
                  to={item.route}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  {item.icon}
                  {item.name}
                </Link>
              ))
            : role === "beleefAdmin"
            ? beleefAdmin.map((item, index) => (
                <Link
                  key={index}
                  className=" pl-4 flex gap-2"
                  to={item.route}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  {item.icon}
                  {item.name}
                </Link>
              ))
            : superAdmin.map((item, index) => (
                <Link
                  key={index}
                  className="pl-4 flex gap-2"
                  to={item.route}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  {item.icon}
                  {item.name}
                </Link>
              ))}
        </div>
      </div>

      {/* Sidebar Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </nav>
  );
};

export default Navbar;
