import React, { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import PageLoader from "../components/ui/PageLoader";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import { FaEdit} from "react-icons/fa";
import Modal from "../components/ui/Modal";
import { MdDelete } from "react-icons/md";
import { FaEye, FaPlus } from "react-icons/fa6";
import { useGetSuburbQuery, useUpdateSuburbMutation } from "apis/suburb";
import SuburbView from "components/suburb/SuburbView";
import SuburbEdit from "components/suburb/SuburbEdit";

const Suburb = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);
  const [pageGroup, setPageGroup] = useState(0); // State for pagination groups
  const navigate = useNavigate();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const{
    data,
    isPending:isSuburbsPending,
    isError:isSuburbsError,
    error:SuburbsError,
  }=useGetSuburbQuery();
  const {
    mutate:updateSuburb,
    isLoading:isSuburbLoading,
  }=
  useUpdateSuburbMutation();
  function formatPrice(value) {
    if (value >= 1e9) {
        const result = (value / 1e9).toFixed(1);
        return result.endsWith('.0') ? result.slice(0, -2) + 'B' : result + 'B'; // Billion
    }
    if (value >= 1e6) {
        const result = (value / 1e6).toFixed(1);
        return result.endsWith('.0') ? result.slice(0, -2) + 'M' : result + 'M'; // Million
    }
    if (value >= 1e3) {
        const result = (value / 1e3).toFixed(1);
        return result.endsWith('.0') ? result.slice(0, -2) + 'K' : result + 'K'; // Thousand
    }
    return value.toLocaleString(); // Less than 1000, use regular formatting
}

  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "#",
        cell: (info) => info.row.index + 1,
        enableColumnFilter: false, // Disables search for this column
        
      },
      {
        accessorKey: "suburb",
        header: "Name",
        
      },
      {
        accessorKey: "postcode",
        header: "Post Code",
      },
      {
        accessorKey: "reaPrice",
        header: "REA Price",
      },
      {
  accessorKey: "domainPrice",
  header: "Domain Price",
  cell: ({ row }) => (
    <div className="text-sm whitespace-nowrap">
      {row.original.domainPrice.map((price, index) => (
        <div key={index} className="flex justify-between mb-1">
          <span className="font-semibold">
            ${formatPrice(
                price.minPrice
            )} - ${
                formatPrice(price.maxPrice)
            }:
          </span>
          <span className="text-gray-600">${
            price.fee
          }</span>
        </div>
      ))}
    </div>
  ),
},

      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex items-center space-x-2">
          <button
            onClick={() => {
              setSelectedId(row.original._id);
              setIsViewOpen(true);
            }}
            className="view-icon"
          >
            <FaEye
              fontSize={20}
            />
          </button>
          <button
            onClick={() => {
              setSelectedId(row.original._id);
              setIsEditOpen(true);
            }}
            className="view-icon"
          >
            <FaEdit 
              fontSize={20}
            />
          </button>
          {/* <button
            onClick={() => {
              
              setSelectedId(row.original._id);
            }}
            className="view-icon"
          >
            <MdDelete
              fontSize={20}

            />
          </button> */}
          
          </div>
        ),
      },
    ],
    [navigate]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
  });

  if (!data.length) {
    return <PageLoader />;
  }

  const totalPages = table.getPageCount();
  const pagesPerGroup = 10;
  const currentGroupStart = pageGroup * pagesPerGroup;
  const currentGroupEnd = Math.min(
    currentGroupStart + pagesPerGroup,
    totalPages
  );

  const handleNextGroup = () => {
    if (currentGroupEnd < totalPages) {
      setPageGroup(pageGroup + 1);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
    }
  };

  return (
    <>
        <Modal
      isOpen={isEditOpen}
      onClose={
        () => setIsEditOpen(false)
      }
      title="Edit Suburb"
      className="max-w-2xl"
    >
    <SuburbEdit
data={data.find((item) => item._id === selectedId)}
onSave={(data) => {
    updateSuburb(data);
    setIsEditOpen(false);
  }}
/>
    </Modal>
    <Modal
        isOpen={isViewOpen}
        onClose={
          () => setIsViewOpen(false)
        }
        title="Suburb Details"
        className="max-w-2xl"
        >
        <SuburbView
            data={data.find((item) => item._id === selectedId)}
        />
    </Modal>
    
    <div className="container mx-auto">
      <div className="pagination-info
      ">
        <p>
        Showing {pagination.pageIndex * pagination.pageSize + 1}-
        {Math.min(
          (pagination.pageIndex + 1) * pagination.pageSize,
          data.length
        )}{" "}
        of {data.length} items.
        </p>
        
      </div>
      <div className="table-responsive">
        <table className="styled-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className="text-sm cursor-pointer flex center items-center"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div className="flex flex-col ml-2">
                        <i
                          className={`fa-solid fa-caret-up ${
                            header.column.getIsSorted() === "asc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                        <i
                          className={`fa-solid fa-caret-down ${
                            header.column.getIsSorted() === "desc"
                              ? "text-primary"
                              : ""
                          }`}
                          style={{ lineHeight: "0.5" }}
                        ></i>
                      </div>
                    </div>
                    {header.column.getCanFilter() &&
                    (header.column.id !== "index" ) && (
                        <input
                          type="text"
                          value={header.column.getFilterValue() || ""}
                          onChange={(e) =>
                            header.column.setFilterValue(e.target.value)
                          }
                          placeholder={`Search ${header.column.id}`}
                          className="column-filter"
                        />
                      
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <button onClick={handlePrevGroup} disabled={pageGroup === 0}>
          &laquo;
        </button>
        {Array.from({ length: currentGroupEnd - currentGroupStart }, (_, i) => (
          <button
            key={i}
            onClick={() => table.setPageIndex(currentGroupStart + i)}
            className={
              currentGroupStart + i === pagination.pageIndex ? "active" : ""
            }
          >
            {currentGroupStart + i + 1}
          </button>
        ))}
        <button
          onClick={handleNextGroup}
          disabled={currentGroupEnd >= totalPages}
        >
          &raquo;
        </button>
      </div>
    </div>
    </>
  );
};

export default Suburb;
