import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useMeQuery } from "apis/user";
import Navbar from "components/ui/NavBar";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  const { data, isError, isPending } = useMeQuery();

  if (isPending ) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin"></i>
      </div>
    );
  }

  // If user is authenticated and token is valid, show the protected content
  if (isAuthenticated && data) {
    return <>
        <Navbar />
        {children}
    </>;
  }

  // Otherwise, redirect to the login page
  return <Navigate to="/login" />;
};

export default ProtectedRoute;
